<template>
  <section class="axis-control">
    <svg-icon
      icon-class="adjuster-horizontal"
      class="axis-control__filter-icon"
    />
    <template v-for="(controlOptionList, index) in controlList">
      <single-axis-control-badge
        :key="index"
        :initial-option-list="controlOptionList"
        :is-edit-mode="isEditMode"
        class="axis-control__list"
        @updateSelectedOption="updateControlOptionList($event, index)"
        @removeControl="removeControl(index)"
      />
    </template>
  </section>
</template>

<script>
import { defineComponent, ref, onMounted, watch } from '@vue/composition-api'
import SingleAxisControlBadge from './SingleAxisControlBadge'

export default defineComponent({
  name: 'AxisControlPanel',
  components: {
    SingleAxisControlBadge
  },
  props: {
    initialControlList: {
      type: Array,
      required: true
    },
    isEditMode: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const controlList = ref([])

    const formatList = (list, activeId) => {
      return list.map((option) => ({
        columnId: option.column.columnId,
        columnName: option.column.name,
        isSelected: option.column.columnId === activeId
      }))
    }

    const updateControlList = (rawData) => {
      // 更新內部 Data
      controlList.value = rawData.map((controlGroup) => {
        return formatList(controlGroup.options, controlGroup.activeColumnId)
      })
    }

    watch(
      () => props.initialControlList,
      (list) => {
        updateControlList(list)
      }, {
        deep: true
      }
    )

    // ----- emit -----
    const updateControlOptionList = (columnId, controlIndex) => {
      // 產生新的 Y軸控制項 結構
      const emitData = props.initialControlList.map((group, i) => {
        return {
          ...group,
          ...(i === controlIndex && { activeColumnId: columnId })
        }
      })

      updateControlList(emitData)

      // emit 更新資訊
      emit('updateControl', emitData)
    }

    const removeControl = (controlIndex) => {
      // 移除 control, 產生新的 Y軸控制項 結構
      const emitData = props.initialControlList.filter((filter, index) => index !== controlIndex)

      updateControlList(emitData)

      emit('updateControl', emitData)
    }

    // ----- hooks -----
    onMounted(() => {
      updateControlList(props.initialControlList)
    })

    return {
      controlList,
      updateControlList,
      removeControl,
      updateControlOptionList
    }
  }
})
</script>

<style lang="scss" scoped>
.axis-control {
  align-items: center;
  display: flex;
  position: relative;

  &__filter-icon {
    margin-bottom: 16px;
    margin-right: 11px;
  }

  ::v-deep .control {
    margin-bottom: 16px;
  }
}
</style>
