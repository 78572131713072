<template>
  <div class="list__items">
    <div class="list__items--container">
      <slot name="prefixOption" />
      <div class="list__items--params">
        {{ columnInfo.modelColumnName ? `${columnInfo.modelColumnName} (${columnInfo.statsType})` : columnInfo.modelDataFrameName }}
      </div>
    </div>
    <div class="list__items--container">
      <svg-icon
        icon-class="go-right"
        class="icon"
      />
      <div
        v-if="!isMultiDatasetModel"
        class="input-field__input-box"
      >
        <default-select
          v-validate="'required'"
          :option-list="optionList"
          :placeholder="$t('editing.chooseDataColumn')"
          :is-disabled="isLoading"
          :is-loading="isLoading"
          :name="`modelInputId-${columnIndex}`"
          filterable
          v-model="dataFrameId"
          class="input-field__select"
          @change="$emit('change', $event)"
        />
        <div
          v-show="errors.has(`modelInputId-${columnIndex}`)"
          class="error-text"
        >
          {{ errors.first(`modelInputId-${columnIndex}`) }}
        </div>
      </div>
      <div
        v-else
        class="input-field"
      >
        <div class="input-field__input-box">
          <default-select
            v-validate="'required'"
            :option-list="optionList"
            :placeholder="$t('miniApp.chooseDataSource')"
            :name="`dataSourceId-${columnIndex}`"
            v-model="dataSourceId"
            filterable
            class="input-field__select"
          />
          <div
            v-show="errors.has(`dataSourceId-${columnIndex}`)"
            class="error-text"
          >
            {{ errors.first(`dataSourceId-${columnIndex}`) }}
          </div>
        </div>
        <div class="input-field__input-box">
          <default-select
            v-validate="'required'"
            :option-list="dataFrameOptionList"
            :placeholder="$t('miniApp.chooseDataFrame')"
            :is-disabled="!(dataSourceId || isLoadingDataFrame) || isLoadingDataFrame"
            :is-loading="isLoadingDataFrame"
            :name="`dataFrameId-${columnIndex}`"
            v-model="dataFrameId"
            filterable
            class="input-field__select"
            @change="$emit('change', { type: 'dataFrame', id: $event })"
          />
          <div
            v-show="errors.has(`dataFrameId-${columnIndex}`)"
            class="error-text"
          >
            {{ errors.first(`dataFrameId-${columnIndex}`) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import DefaultSelect from '@/components/select/DefaultSelect'
import { getDataFrameById } from '@/API/DataSource'

export default defineComponent({
  inject: ['$validator'],
  name: 'ColumnSettingCard',
  components: {
    DefaultSelect
  },
  props: {
    optionList: {
      type: Array,
      required: true
    },
    columnIndex: {
      type: Number,
      default: null
    },
    columnInfo: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isMultiDatasetModel: {
      type: Boolean,
      default: false
    },
    hasModelId: {
      type: Boolean,
      default: false
    },
    sourceId: {
      type: Number,
      default: null
    },
    inputId: {
      type: Number,
      default: null
    }
  },
  setup (props, { emit }) {
    const dataFrameOptionList = ref([])
    const isLoadingDataFrame = ref(false)

    const dataSourceId = computed({
      get () {
        return props.sourceId
      },
      set (value) {
        emit('update:sourceId', value)
        fetchDataFrameList(value)
      }
    })

    const dataFrameId = computed({
      get () {
        return props.inputId
      },
      set (value) {
        emit('update:inputId', value)
      }
    })

    const fetchDataFrameList = (id, isMounted = false) => {
      isLoadingDataFrame.value = true
      getDataFrameById(id ?? dataSourceId.value)
        .then(response => {
          dataFrameOptionList.value = response
            .filter((frame) => !frame.generatedByModelFlow)
            .map((frame) => {
              return {
                name: frame.primaryAlias,
                value: frame.id
              }
            })
        }).finally(() => {
          isLoadingDataFrame.value = false
          // 主要是避免初次執行時觸發
          !isMounted && emit('change', { type: 'dataSource', id: id ?? dataSourceId.value })
        })
    }

    onMounted(() => {
      if (dataSourceId.value) {
        fetchDataFrameList(null, true)
        emit('change', { type: 'dataFrame', id: dataFrameId.value })
      }
    })
    return {
      dataSourceId,
      dataFrameId,
      isLoadingDataFrame,
      dataFrameOptionList,
      fetchDataFrameList
    }
  }
})
</script>
