<template>
  <div class="dialog">
    <nav class="dialog__nav">
      <div class="nav--left">
        <div
          class="icon-arrow"
          @click="$emit('close')"
        >
          <svg-icon icon-class="arrow-left" />
        </div>
        <span>{{ init ? $t('miniApp.editComponent') : $t('miniApp.createComponent') }}</span>
        <div
          v-if="topControlsAndFilter.length > 0"
          class="filter-list"
        >
          <single-filter-status-badge
            v-for="(filter, index) in topControlsAndFilter.slice(0, 4)"
            :key="`single-filter-status-badge-${index}`"
            :filter="filter"
          />
          <overflow-filter-status-badge :filters="topControlsAndFilter.slice(4)" />
        </div>
      </div>
      <div class="nav--right">
        <div
          v-if="!isLoading && isAddable === false"
          class="message"
        >
          <svg-icon
            class="icon"
            icon-class="information-circle"
          />
          {{ $t('miniApp.componentNotAddable') }}
        </div>
        <button
          v-if="init"
          class="btn btn-default"
          @click="saveComponent"
        >
          {{ $t('button.save') }}
        </button>
        <button
          v-else
          :disabled="!isAddable || isLoading || isFailed"
          class="btn btn-default"
          @click="createComponent"
        >
          {{ $t('button.create') }}
        </button>
      </div>
    </nav>
    <div class="dialog__content">
      <div class="dialog__content--left">
        <template v-if="isCreatedViaAsking">
          <div class="search-bar">
            <data-frame-menu
              :redirect-on-change="false"
              :is-show-preview-entry="true"
              :is-show-advance-setting-entry="true"
            />
            <ask-block
              :redirect-on-ask="false"
              :is-show-ask-helper-entry="false"
              :default-question="currentComponent.questionConfig.question"
            />
          </div>
          <div
            class="dashboard-container"
            :class="{ 'show-settings': isShowSettingBox }"
          >
            <transition name="fast-fade-in">
              <advance-data-frame-setting v-if="isShowSettingBox" />
            </transition>
            <dashboard-component
              :current-component="currentComponent"
              :is-addable.sync="isAddable"
              :is-loading.sync="isLoading"
              @setDiagram="currentComponent.diagram = $event"
              @magicTypeChanged="handleMagicTypeChanged"
              @toggleLabel="handleToggleLabel"
              @updateBinSize="handleUpdateBinSize"
            />
          </div>
          <transition name="fast-fade-in">
            <section
              v-if="isShowPreviewDataSource"
              class="preview-datasource"
              :class="{ 'show-settings': isShowSettingBox }"
            >
              <preview-data-source
                :is-previewing="true"
                mode="popup"
              />
              <a
                href="javascript:void(0)"
                class="preview-datasource__close-btn"
                @click="closePreviewDataSource"
              ><svg-icon icon-class="close" /></a>
            </section>
          </transition>
        </template>
        <template v-else-if="currentComponent.type === 'formula' && currentComponent.formulaSetting">
          <formula-setting
            :data-source="currentComponent.source"
            :formula-setting="currentComponent.formulaSetting"
            :date-time-column.sync="dateTimeColumn"
            :unit.sync="currentComponent.displayConfig.unit"
          />
        </template>
        <template v-else-if="(currentComponent.type === 'simulator' || currentComponent.type === 'parameters-optimized-simulator') && currentComponent.modelSetting">
          <simulator-setting
            :data-source="currentComponent.source"
            :model-setting="currentComponent.modelSetting"
            :date-time-column.sync="dateTimeColumn"
            :current-component-type="currentComponent.type"
            :is-loading.sync="isLoading"
            :is-failed.sync="isFailed"
          />
        </template>
      </div>
      <div
        class="dialog__content--right"
      >
        <div class="setting__header">
          <svg-icon icon-class="filter-setting" />
          {{ $t('miniApp.componentSetting') }}
        </div>
        <!-- Title Setting -->
        <div class="setting__content">
          <div class="setting__block">
            <div class="setting__label-block">
              {{ isCreatedViaAsking ? $t('miniApp.syncComponentName') : $t('miniApp.componentName') }}
              <el-switch
                v-if="isCreatedViaAsking"
                v-model="currentComponent.displayConfig.title.isSyncQuestion"
                :width="Number('32')"
                active-color="#2AD2E2"
                inactive-color="#324B4E"
                @change="handleTitleSyncChange"
              />
            </div>
            <div class="setting__blok-select-field">
              <input-verify
                v-validate="'required'"
                v-model="displayedName"
                name="createComponentDisplayName"
              />
            </div>
          </div>
        </div>
        <!--Related dashboard of current component-->
        <div
          v-if="isShowRelatedOption"
          class="setting__content"
        >
          <div class="setting__block">
            <div class="setting__label-block">
              {{ $t('miniApp.selectDashboard') }}
              <el-switch
                v-model="currentComponent.settingConfig.related.dashboard.active"
                :width="Number('32')"
                active-color="#2AD2E2"
                inactive-color="#324B4E"
                @change="updateHasRelatedDashboard"
              />
            </div>
            <div
              v-if="currentComponent.settingConfig.related.dashboard.active"
              class="setting__block-select-field"
            >
              <default-select
                v-validate="'required'"
                :value="currentComponent.settingConfig.related.dashboard.dashboardId"
                :option-list="dashboardOptions"
                :placeholder="$t('miniApp.selectDashboard')"
                class="setting__block-select"
                name="createRelatedDashboard"
                @change="updateRelatedDashboard"
              />
              <div
                v-show="errors.has('createRelatedDashboard')"
                class="error-text"
              >
                {{ errors.first('createRelatedDashboard') }}
              </div>
            </div>
          </div>
        </div>
        <!-- Related dashboard of component columns or rows -->
        <div
          v-if="isShowTableRelationSetting"
          class="setting__content"
        >
          <div class="setting__block">
            <div class="setting__label-block">
              <span class="setting__label-block-label">
                {{ $t('miniApp.dataColumnRelationSetting') }}
              </span>
              <el-switch
                v-model="currentComponent.settingConfig.related.table.active"
                :width="Number('32')"
                active-color="#2AD2E2"
                inactive-color="#324B4E"
                @change="updateHasTableRelatedDashboard"
              />
              <span class="setting__label-block-description">
                {{ $t('miniApp.dataColumnRelationSettingReminding') }}
              </span>
            </div>
            <template v-if="currentComponent.settingConfig.related.table.active">
              <div class="setting__block-select-field">
                <label class="setting__block-select-label">{{ $t('miniApp.triggerColumn') }}</label>
                <div class="setting__block-radio-groups">
                  <div
                    v-for="(option, index) in triggerTargetOptions"
                    :key="index"
                    class="input-radio-group"
                  >
                    <input
                      :id="option.value"
                      :value="option.value"
                      :checked="option.value === currentComponent.settingConfig.related.table.triggerTarget"
                      name="triggerOption"
                      class="input-radio"
                      type="radio"
                      @change="updateTriggerTarget(option.value)"
                    >
                    <label
                      :for="option.value"
                      class="input-radio-label"
                    >
                      {{ `${ option.name }` }}
                    </label>
                  </div>
                </div>
              </div>
              <div
                v-if="currentComponent.settingConfig.related.table.triggerTarget === 'column'"
                class="setting__block-select-field"
              >
                <label class="setting__block-select-label">{{ $t('miniApp.triggerColumn') }}</label>
                <default-select
                  v-validate="'required'"
                  v-model="selectedTriggerColumn"
                  :option-list="categoryColumnOptions"
                  :placeholder="$t('miniApp.chooseColumn')"
                  class="setting__block-select"
                  name="createTriggerColumn"
                  @change="updateTriggerColumnInfo"
                />
                <div
                  v-show="errors.has('createTriggerColumn')"
                  class="error-text"
                >
                  {{ errors.first('createTriggerColumn') }}
                </div>
              </div>
              <div class="setting__block-select-field">
                <label class="setting__block-select-label">{{ $t('miniApp.relatedDashboard') }}</label>
                <default-select
                  v-validate="'required'"
                  :value="tableRelatedDashboard"
                  :option-list="dashboardOptions"
                  :placeholder="$t('miniApp.chooseDashboard')"
                  class="setting__block-select"
                  name="createColumnRelatedDashboard"
                  @change="updateTableRelatedDashboard"
                />
                <div
                  v-show="errors.has('createColumnRelatedDashboard')"
                  class="error-text"
                >
                  {{ errors.first('createColumnRelatedDashboard') }}
                </div>
              </div>
            </template>
          </div>
        </div>
        <!--Update frequency-->
        <div
          v-if="isShowUpdatedOption"
          class="setting__content"
        >
          <div class="setting__block">
            <div class="setting__label-block">
              {{ $t('miniApp.updateFrequency') }}
              <el-switch
                v-model="isAutoRefresh"
                :width="Number('32')"
                active-color="#2AD2E2"
                inactive-color="#324B4E"
                @change="updateRefreshFrequency"
              />
            </div>
            <div
              v-if="isAutoRefresh"
              class="setting__block-select-field"
            >
              <default-select
                v-validate="'required'"
                v-model="currentComponent.settingConfig.refresh.refreshFrequency"
                :option-list="updateFrequency"
                :placeholder="$t('miniApp.chooseUpdateFrequency')"
                class="setting__block-select"
                name="createUpdateFrequency"
              />
              <div
                v-show="errors.has('createUpdateFrequency')"
                class="error-text"
              >
                {{ errors.first('createUpdateFrequency') }}
              </div>
            </div>
          </div>
        </div>
        <!--Index type component font size setting-->
        <div
          v-if="isShowFontSizeOption"
          class="setting__content"
        >
          <div class="setting__block">
            <div class="setting__label-block">
              {{ $t('miniApp.fontSizeSetting') }}
            </div>
            <default-select
              v-model="currentComponent.displayConfig.fontSize"
              :option-list="indexSizeOptionList"
              :placeholder="$t('miniApp.chooseColumnSize')"
              class="setting__block-select"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import momentTZ from 'moment-timezone'
import { Message } from 'element-ui'
import { defineComponent } from '@vue/composition-api'
import { mapState } from 'vuex'
import { algoConfig } from '@/utils/general'
import { useAskingModuleContext } from '@/modules/shared/asking'
import DefaultSelect from '@/components/select/DefaultSelect'
import AskBlock from '@/components/chatBot/AskBlock'
import DataFrameMenu from '@/components/select/DataFrameMenu'
import InputVerify from '@/components/InputVerify'
import AdvanceDataFrameSetting from '@/components/AdvanceDataFrameSetting'
import FormulaSetting from '../components/componentSetting/FormulaSetting'
import SimulatorSetting from '../components/componentSetting/SimulatorSetting'
import DashboardComponent from './DashboardComponent'
import SingleFilterStatusBadge from './SingleFilterStatusBadge'
import OverflowFilterStatusBadge from './OverflowFilterStatusBadge'
import ResultDisplay from '@/pages/result/ResultDisplay'
import globalEmitter from '@/utils/globalEmitter'

export default defineComponent({
  name: 'CreateComponentDialog',
  components: {
    // public
    DefaultSelect,
    AskBlock,
    DataFrameMenu,
    InputVerify,
    AdvanceDataFrameSetting,
    // miniApp
    FormulaSetting,
    SimulatorSetting,
    DashboardComponent,
    SingleFilterStatusBadge,
    OverflowFilterStatusBadge,
    // other
    ResultDisplay
  },
  props: {
    initialCurrentComponent: {
      type: Object,
      default: () => ({})
    },
    dashboardList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    controls: {
      type: Array,
      default: () => []
    },
    gap: {
      type: Number,
      default: 0
    },
    timeZone: {
      type: String,
      default: momentTZ.tz.guess()
    },
    init: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    appQuestion (val) {
      if (!this.displayedName && !this.isTitleSyncing) {
        this.isTitleSyncing = true
        this.displayedName = val
      } else if (this.isTitleSyncing) {
        this.displayedName = val
      }
    },
    displayedName (val) {
      if (val !== this.appQuestion) {
        this.isTitleSyncing = false
      }
    },
    isTitleSyncing (val) {
      if (val) {
        this.displayedName = this.appQuestion
      }
    }
  },
  setup () {
    const {
      parserLanguage,
      setIsShowSettingBox,
      isShowSettingBox,
      filterList,
      filterRestrictionList,
      selectedColumnList,
      appQuestion,
      setAppQuestion,
      setIsInit,
      clearFilterList,
      clearColumnList
    } = useAskingModuleContext()

    return {
      parserLanguage,
      setIsShowSettingBox,
      isShowSettingBox,
      filterList,
      filterRestrictionList,
      selectedColumnList,
      appQuestion,
      setAppQuestion,
      setIsInit,
      clearFilterList,
      clearColumnList
    }
  },
  provide () {
    return {
      computeRestrictions: (mainDateColumn) => this.computeRestrictions(mainDateColumn),
      computeSelectedColumns: () => this.computeSelectedColumns(),
      isMiniAppCreateDialog: true
    }
  },
  inject: ['$validator'],
  data () {
    const isDirectAddableComponentTypes = ['formula', 'simulator', 'parameters-optimized-simulator']
    const currentComponent = this.initialCurrentComponent ? JSON.parse(JSON.stringify(this.initialCurrentComponent)) : {}
    const columnInfo = currentComponent?.settingConfig?.related?.table?.column

    return {
      isAddable: isDirectAddableComponentTypes.includes(currentComponent.type),
      isLoading: false,
      isFailed: false,
      currentComponent,
      selectedTriggerColumn: columnInfo?.columnId,
      relatedDashboardTemplate: {
        // TODO: 抽出 schema
        active: false,
        dashboardId: null,
        name: null
      },
      algoConfig
    }
  },
  computed: {
    ...mapState('result', ['currentResultId', 'currentResultInfo']),
    ...mapState('previewDataSource', ['isShowPreviewDataSource']),
    max () {
      return this.$store.getters['validation/fieldCommonMaxLength']
    },
    isShowTableRelationSetting () {
      if (!this.currentResultInfo?.segmentation || !this.currentComponent) return false
      const isTableDiagram = this.currentComponent.diagram === 'table'
      const isListOutDenotation = this.currentResultInfo.segmentation.denotation === 'LIST_OUT'
      const hasDataRowTypeSentence = this.currentResultInfo.segmentation.sentence.some(({ type }) => type === 'DATA_ROW')
      return isTableDiagram && !(isListOutDenotation && hasDataRowTypeSentence)
    },
    isShowRelatedOption () {
      return !['monitor-warning-list', 'abnormal-statistics', 'simulator', 'parameters-optimized-simulator'].includes(this.currentComponent.type)
    },
    isShowUpdatedOption () {
      return !['monitor-warning-list', 'abnormal-statistics', 'simulator', 'parameters-optimized-simulator'].includes(this.currentComponent.type)
    },
    isShowFontSizeOption () {
      return ['index', 'formula', 'abnormal-statistics'].includes(this.currentComponent.type)
    },
    rowSpanOption () {
      // 最小值為 2, 最大值為 12
      return [...Array(11).keys()].map(value => ({
        value: value + 2,
        name: this.$t('miniApp.rowSpanAmount', { number: value + 2 })
      }))
    },
    categoryColumnOptions () {
      const origin = this.currentResultInfo || this.initialCurrentComponent?.questionConfig
      let options = origin.segmentation.transcript.subjectList.reduce((acc, cur) => {
        const isExist = acc.findIndex(item => item.dataColumnId === cur.categoryDataColumnList[0].dataColumnId) > -1
        return isExist ? acc : acc.concat(
          ...cur.categoryDataColumnList.map(item => ({
            ...item,
            value: item.dataColumnId,
            name: item.dataColumnAlias
          })))
      }, [])
      options.unshift(this.defaultOptionFactory(this.$t('miniApp.chooseColumn')))
      return options
    },
    dashboardOptions () {
      let options = this.dashboardList
        .filter(board => board.uuid !== this.dashboardId) // NOTICE: 沒有 dashboardId
        .map(board => ({
          value: board.uuid,
          name: board.name
        }))
      options.unshift(this.defaultOptionFactory(this.$t('miniApp.chooseDashboard')))
      return options
    },
    tableRelatedDashboard () {
      const relationTable = this.currentComponent?.settingConfig?.related?.table
      if (!relationTable?.active) return

      return relationTable?.dashboardId
    },
    updateFrequency () {
      return [
        {
          value: '* * * * *',
          name: this.$t('warRoom.everyMinute', { number: 1 })
        },
        {
          value: '*/5 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 5 })
        },
        {
          value: '*/15 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 15 })
        },
        {
          value: '*/30 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 30 })
        },
        {
          value: '*/45 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 45 })
        },
        {
          value: '0 * * * *',
          name: this.$t('warRoom.everyHour')
        },
        {
          value: '0 0 * * *',
          name: this.$t('warRoom.everyDay')
        },
        {
          value: '0 0 * * 0',
          name: this.$t('warRoom.everyWeek')
        },
        {
          value: '0 0 1 * *',
          name: this.$t('warRoom.everyMonth')
        }
      ]
    },
    triggerTargetOptions () {
      return [
        {
          name: this.$t('miniApp.singleColumnData'),
          value: 'column'
        },
        {
          name: this.$t('miniApp.singleRowData'),
          value: 'row'
        }
      ]
    },
    indexSizeOptionList () {
      return [
        {
          value: 'large',
          name: this.$t('miniApp.large')
        },
        {
          value: 'middle',
          name: this.$t('miniApp.middle')
        },
        {
          value: 'small',
          name: this.$t('miniApp.small')
        },
        {
          value: 'mini',
          name: this.$t('miniApp.mini')
        }
      ]
    },
    topControls () {
      return this.controls.flat(1).map((control) => {
        control.tagType = 'control'
        return control
      })
    },
    topFilters () {
      return this.filters.flat(1).map((filter) => {
        filter.tagType = 'filter'
        return filter
      })
    },
    topControlsAndFilter () {
      // 全部外層傳進來的 filters / controls 加入 tagtype 後攤平
      return [...this.topControls, ...this.topFilters]
    },
    isCreatedViaAsking () {
      return this.initialCurrentComponent && ['chart', 'text', 'index'].includes(this.initialCurrentComponent.type)
    },
    isAutoRefresh: {
      get () {
        return this.currentComponent?.settingConfig?.refresh?.isAuto
      },
      set (val) {
        if ('isAuto' in this.currentComponent?.settingConfig?.refresh) {
          this.currentComponent.settingConfig.refresh.isAuto = val
        }
      }
    },
    isTitleSyncing: {
      get () {
        return this.currentComponent.displayConfig.title.isSyncQuestion
      },
      set (val) {
        this.currentComponent.displayConfig.title.isSyncQuestion = val
      }
    },
    displayedName: {
      get () {
        return this.currentComponent.displayConfig.title.name
      },
      set (val) {
        this.currentComponent.displayConfig.title.name = val
      }
    },
    dateTimeColumn: {
      get () {
        return this.currentComponent?.settingConfig?.dateTimeColumn
      },
      set (val) {
        if (this.currentComponent?.settingConfig?.dateTimeColumn) {
          this.currentComponent.settingConfig.dateTimeColumn = val
        }
      }
    }
  },
  mounted () {
    if (this.isCreatedViaAsking) {
      this.setAppQuestion(this.currentComponent.questionConfig.question)
    }

    globalEmitter.on('updateStackStatus', (status) => {
      this.currentComponent.displayConfig.isStack = status
    })
  },
  destroyed () {
    this.$store.commit('result/updateCurrentResultInfo', null)
    this.$store.commit('result/updateCurrentResultId', null)
    this.setIsInit(false)
    this.clearFilterList()
    this.clearColumnList()
    this.setAppQuestion(null)
    this.setIsShowSettingBox(false)
    if (this.isShowPreviewDataSource) this.closePreviewDataSource()
  },
  methods: {
    // ----- emit -----
    createComponent () {
      this.$validator.validateAll().then(valid => {
        if (!valid) return

        if (this.currentComponent?.modelSetting?.type === 'dataFrame' &&
        this.currentComponent.modelSetting.inputList.every((input) => input.paramType === 'FIXED')) {
          Message({
            message: this.$t('miniApp.notificationMessage.atLeastOneSimulatorParamType'),
            type: 'warning',
            duration: 3 * 1000,
            showClose: true
          })
          return
        }

        const returnObj = JSON.parse(JSON.stringify(this.currentComponent))

        if (this.isParameterComparisonTypeComponent(this.currentComponent.displayConfig.title.name)) {
          // Demo 使用：為了展示參數最佳化比較，把元件名稱帶有特定字串的元件改 type
          // TODO: 是否改為 question
          returnObj.type = 'paramCompare'
        }

        returnObj.advanced = {
          filterList: this.filterList,
          selectedColumnList: this.selectedColumnList
        }

        if (this.isCreatedViaAsking) {
          returnObj.source.dataSourceId = this.currentResultInfo.dataSourceId
          returnObj.source.dataFrameId = this.currentResultInfo.dataFrameId
          returnObj.settingConfig.dateTimeColumn = {
            columnId: this.currentResultInfo.dateTimeColumn?.columnId,
            primaryAlias: this.currentResultInfo.dateTimeColumn?.primaryAlias
          }

          returnObj.questionConfig.parserLanguage = this.parserLanguage
          returnObj.questionConfig.resultId = this.currentResultId
          returnObj.questionConfig.question = this.currentResultInfo.question
          returnObj.questionConfig.questionId = this.currentResultInfo.questionId
          returnObj.questionConfig.keyResultId = this.currentResultInfo.keyResultId
          returnObj.questionConfig.segmentation = this.currentResultInfo.segmentation
          returnObj.questionConfig.dataColumns = this.currentResultInfo.dataColumns
        }

        // 將來 增/刪 filter 時，重打 askResult 所需的 request body
        // TODO: returnObj 補入 this.currentResultInfo

        this.$emit('create', returnObj)
      })
    },
    saveComponent () {
      this.$validator.validateAll().then(valid => {
        if (!valid) return

        if (this.currentComponent?.modelSetting?.type === 'dataFrame' &&
        this.currentComponent.modelSetting.inputList.every((input) => input.paramType === 'FIXED')) {
          Message({
            message: this.$t('miniApp.notificationMessage.atLeastOneSimulatorParamType'),
            type: 'warning',
            duration: 3 * 1000,
            showClose: true
          })
          return
        }

        const returnObj = JSON.parse(JSON.stringify(this.currentComponent))

        returnObj.advanced = {
          filterList: this.filterList,
          selectedColumnList: this.selectedColumnList
        }

        if (this.isCreatedViaAsking) {
          returnObj.source.dataSourceId = this.currentResultInfo.dataSourceId
          returnObj.source.dataFrameId = this.currentResultInfo.dataFrameId

          returnObj.settingConfig.dateTimeColumn = {
            columnId: this.currentResultInfo.dateTimeColumn?.columnId,
            primaryAlias: this.currentResultInfo.dateTimeColumn?.primaryAlias
          }

          returnObj.questionConfig.question = this.currentResultInfo.question
          returnObj.questionConfig.questionId = this.currentResultInfo.questionId
          returnObj.questionConfig.keyResultId = this.currentResultInfo.keyResultId
          returnObj.questionConfig.segmentation = this.currentResultInfo.segmentation
          returnObj.questionConfig.dataColumns = this.currentResultInfo.dataColumns
        }

        this.$emit('updateSetting', returnObj)
      })
    },
    // ----- title -----
    handleTitleSyncChange (isActive) {
      if (isActive) return

      // 關閉時，恢復原本預設，避免存取時送錯的格式給後端
      this.$nextTick(() => {
        this.currentComponent.displayConfig.title.name = this.initialCurrentComponent.displayConfig.title.name
      })
    },
    // ----- related dashboard -----
    updateRelatedDashboard (selectedDashboardId) {
      if (!selectedDashboardId) {
        this.currentComponent.settingConfig.related.dashboard.dashboardId = null
        this.currentComponent.settingConfig.related.dashboard.name = null
        return
      }
      const { uuid, name } = this.dashboardList.find(dashboard => dashboard.uuid === selectedDashboardId)
      this.currentComponent.settingConfig.related.dashboard.dashboardId = uuid
      this.currentComponent.settingConfig.related.dashboard.name = name
    },
    updateHasRelatedDashboard (isTurnedOn) {
      if (isTurnedOn) {
        if (this.currentComponent.settingConfig.related.dashboard) return
        this.currentComponent.settingConfig.related.dashboard = this.relatedDashboardTemplate
        return
      }
      // 關閉時，恢復原本預設，避免存取時送錯的格式給後端
      this.$nextTick(() => {
        this.currentComponent.settingConfig.related.dashboard = {
          ...this.initialCurrentComponent.settingConfig.related.dashboard,
          ...{ active: false }
        }
      })
    },
    // ----- related table -----
    updateHasTableRelatedDashboard (isTurnedOn) {
      if (!isTurnedOn) {
        // this.$validator.detach('columnRelatedDashboard')
        this.currentComponent.settingConfig.related.table = {
          active: false,
          triggerTarget: 'column',
          dashboardId: null,
          column: {
            columnId: null,
            columnAlias: null
          }
        }
      }
    },
    updateTriggerColumnInfo () {
      const column = this.categoryColumnOptions.find(item => item.dataColumnId === this.selectedTriggerColumn)
      this.currentComponent.settingConfig.related.table.column = {
        columnId: column.dataColumnId,
        columnAlias: column.dataColumnAlias
      }
    },
    async updateTriggerTarget (triggerTarget) {
      this.currentComponent.settingConfig.related.table.triggerTarget = triggerTarget
      if (triggerTarget === 'row') {
        // 避免資料被清空時觸發驗證導致 error
        this.$validator.detach('triggerColumn')
        await this.$nextTick()
      }

      this.currentComponent.settingConfig.related.table.dashboardId = null
      this.selectedTriggerColumn = null
      this.currentComponent.settingConfig.related.table.column = null
    },
    updateTableRelatedDashboard (selectedDashboardId) {
      this.currentComponent.settingConfig.related.table.dashboardId = selectedDashboardId
    },
    // ----- refres frequency -----
    updateRefreshFrequency (isTurnedOn) {
      if (isTurnedOn) return
      const { refreshFrequency } = JSON.parse(JSON.stringify(this.initialCurrentComponent.settingConfig.refresh))

      // 關閉時，恢復原本預設，避免存取時送錯的格式給後端
      this.$nextTick(() => {
        this.currentComponent.settingConfig.refresh.refreshFrequency = refreshFrequency
      })
    },
    // ----- common -----
    defaultOptionFactory (placholder) {
      return {
        value: null,
        name: placholder
      }
    },
    closePreviewDataSource () {
      this.$store.commit('previewDataSource/togglePreviewDataSource', false)
    },
    isParameterComparisonTypeComponent (componentName) {
      if (componentName === null) return false
      const regex = new RegExp(this.$t('miniApp.optimalParameterComparison'), 'g')
      return componentName.match(regex)
    },
    formatRelativeDatetime (dataValue) {
      const properties = {
        start: null,
        end: null
      }

      const currentTimeZone = this.timeZone ?? momentTZ.tz.guess()

      // update datetime range
      switch (dataValue) {
        case 'today':
          properties.start = momentTZ().tz(currentTimeZone).startOf('day').format('YYYY-MM-DD HH:mm')
          properties.end = momentTZ().tz(currentTimeZone).endOf('day').format('YYYY-MM-DD HH:mm')
          break
        // 注意！每週是從週一到週日，要使用 isoWeek
        case 'week':
          properties.start = momentTZ().tz(currentTimeZone).startOf('isoWeek').format('YYYY-MM-DD HH:mm')
          properties.end = momentTZ().tz(currentTimeZone).endOf('isoWeek').format('YYYY-MM-DD HH:mm')
          break
        case 'month':
        case 'quarter':
        case 'year':
          properties.start = momentTZ().tz(currentTimeZone).startOf(dataValue).format('YYYY-MM-DD HH:mm')
          properties.end = momentTZ().tz(currentTimeZone).endOf(dataValue).format('YYYY-MM-DD HH:mm')
          break
        default:
          if (RegExp('^.*hour.*$').test(dataValue)) {
            const hour = Number(dataValue.split('hour')[0])
            properties.start = momentTZ().tz(currentTimeZone).subtract(hour, 'hours').format('YYYY-MM-DD HH:mm')
            properties.end = momentTZ().tz(currentTimeZone).format('YYYY-MM-DD HH:mm')
          } else {
            properties.start = null
            properties.end = null
          }
      }

      return properties
    },
    transformToRestrictionBy (filter, mainDateColumn) {
      const filterType = filter.column.type
      let type = ''
      let data_type = ''
      switch (filterType) {
        case 'STRING':
        case 'BOOLEAN':
        case 'CATEGORY':
          data_type = 'string'
          type = 'enum'
          break
        case 'FLOAT':
        case 'NUMERIC':
          data_type = 'int'
          type = 'range'
          break
        case 'DATETIME':
        case 'RELATIVEDATETIME':
        case 'CUSTOMDATETIME':
          data_type = 'datetime'
          type = 'range'
          break
      }

      // 相對時間 filter 需取當前元件所屬 dataframe 的預設時間欄位和當前時間來套用
      if (filterType === 'RELATIVEDATETIME') {
        return [
          {
            type,
            properties: {
              data_type,
              dc_id: mainDateColumn.columnId,
              display_name: mainDateColumn.primaryAlias,
              ...this.formatRelativeDatetime(filter.optionValues[0])
            }
          }
        ]
      } else if (filterType === 'CUSTOMDATETIME') {
        return [
          {
            type,
            properties: {
              data_type,
              dc_id: mainDateColumn.columnId,
              display_name: mainDateColumn.primaryAlias,
              ...this.formatCustomDatetime(filter.optionValues[0])
            }
          }
        ]
      }

      return [
        {
          type,
          properties: {
            data_type,
            dc_id: filter.column.columnId,
            display_name: filter.column.name,
            ...((filterType === 'STRING' ||
              filterType === 'BOOLEAN' ||
              filterType === 'CATEGORY') && {
              datavalues: filter.optionValues,
              display_datavalues: filter.optionValues
            }),
            ...((filterType === 'NUMERIC' ||
              filterType === 'FLOAT' ||
              filterType === 'DATETIME') && {
              start: filter.valueRange.start,
              end: filter.valueRange.end
            })
          }
        }
      ]
    },
    formatCustomDatetime ({ timeUnit, timeValue }) {
      const currentTimeZone = this.timeZone ?? momentTZ.tz.guess()
      return {
        start: momentTZ().tz(currentTimeZone).subtract(timeUnit, timeValue).format('YYYY-MM-DD HH:mm'),
        end: momentTZ().tz(currentTimeZone).format('YYYY-MM-DD HH:mm')
      }
    },

    computeRestrictions (mainDateColumn = null) {
      const topRestrictions = this.topControlsAndFilter
        .filter((filter) => this.checkShouldApplyMiniAppFilter(filter, mainDateColumn))
        .map((filter) => this.transformToRestrictionBy(filter, mainDateColumn))

      // 進階設定提供的 restrictions
      const restrictionsFromAdvancedSettings = this.filterRestrictionList

      const result = [
        ...topRestrictions,
        ...restrictionsFromAdvancedSettings
      ]
      return result
    },
    computeSelectedColumns () {
      const selectedColumnsFromAdvancedSettings = this.selectedColumnList ?? []
      const result = [...selectedColumnsFromAdvancedSettings]

      return result.length === 0
        ? null
        : result
    },
    setAlgoConfig (intent) {
      this.currentComponent.questionConfig.algoConfig = this.algoConfig[intent.toLowerCase()]
    },
    handleMagicTypeChanged (e) {
      this.currentComponent.displayConfig.magicType = e
    },
    handleToggleLabel (e) {
      this.currentComponent.displayConfig.showLabelData = e
    },
    handleUpdateBinSize (e) {
      this.currentComponent.displayConfig.binSize = e
    }
  }
})
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;

  &__nav {
    align-items: center;
    background: rgba(0, 0, 0, 0.55);
    border-bottom: 1px solid #232c2e;
    display: flex;
    flex: 0 0 56px;
    justify-content: space-between;
    padding: 0 24px;

    .nav--left {
      align-items: center;
      display: flex;

      .icon-arrow {
        color: $theme-color-primary;
        cursor: pointer;
        margin-right: 20px;
      }

      .filter-list {
        align-items: center;
        display: flex;
        margin-left: 42px;

        &__label {
          align-items: center;
          color: #aaa;
          display: flex;
          font-size: 13px;

          .label-text {
            margin-left: 10px;
          }
        }

        .filter-status-badge {
          margin-left: 10px;
        }
      }
    }

    .nav--right {
      display: flex;
      margin: 0 0 0 auto;

      .message {
        line-height: 36px;
        margin-right: 6px;
      }
    }
  }

  &__content {
    display: flex;
    flex: 1;
    height: 0;
    overflow: auto;
    overflow: overlay; // 讓scrollbar不佔位。for有支援此屬性的瀏覽器

    &--left {
      border-right: 1px solid #232c2e;
      flex: 1;
      min-width: 0;
      position: relative;

      .search-bar {
        border-bottom: 1px solid #232c2e;
        display: flex;
        height: 60px;
        padding: 8px 24px;
        position: relative;
        z-index: 4;

        .data-frame-select-block {
          margin-right: 16px;
          width: 300px;
        }
      }

      .dashboard-container {
        height: calc(100vh - 116px);
        overflow-y: auto;
        transition: all 0.3s;

        &.show-settings {
          padding-left: 280px;
        }

        ::v-deep .setting__wrapper {
          height: calc(100vh - 116px);
          top: 60px;
          width: 280px;
        }
      }
    }

    &--right {
      flex: 0 0 280px;

      .setting {
        &__header {
          align-items: center;
          border-bottom: 1px solid #232c2e;
          display: flex;
          height: 60px;
          padding: 16px 24px;

          .svg-icon {
            color: $theme-color-primary;
            margin-right: 12px;
            width: 16px;
          }
        }

        &__content {
          border-bottom: 1px solid #232c2e;
          padding: 16px 24px;
        }

        &__label-block {
          color: #fff;
          display: flex;
          flex-wrap: wrap;
          font-size: 14px;
          font-weight: 600;
          justify-content: space-between;

          &-description {
            color: #aaa;
            font-size: 12px;
            margin-top: 12px;
          }
        }

        &__block {
          ::v-deep .input-verify {
            .input-verify-text {
              margin-bottom: 10px;
            }

            .input-error.error-text {
              bottom: -10px;
            }
          }
        }

        &__block-select-field {
          margin-top: 8px;
        }

        &__block-select-label {
          color: #aaa;
          display: block;
          font-size: 14px;
          font-weight: 600;
          padding-top: 8px;
        }

        &__block-select {
          width: 100%;

          ::v-deep .el-input__inner {
            border-bottom: 1px solid #fff;
            padding-left: 0;
          }
        }

        &__block-radio-groups {
          display: flex;
          font-size: 14px;

          .input-radio-group {
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    .preview-datasource {
      background: rgba(0, 0, 0, 0.89);
      height: calc(100vh - 56px - 60px);
      left: 0;
      overflow: auto;
      padding: 40px;
      position: absolute;
      right: 0;
      text-align: left;
      top: 60px;
      transition: all 0.3s;

      &.show-settings {
        left: 280px;
      }

      &__close-btn {
        color: #fff;
        font-size: 14px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
}
</style>
