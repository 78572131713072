<template>
  <div class="wrapper wrapper--without-nav-header mini-app">
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
      size="50"
    />
    <main
      v-else
      class="mini-app__page"
    >
      <nav class="mini-app__nav">
        <div class="nav--left">
          <div
            class="side-nav--pin"
            @mouseenter="handleSideNavPinnerHover(true)"
            @mouseleave="handleSideNavPinnerHover(false)"
            @click.stop="handleSideNavPinClick(true)"
            v-if="!isSideNavPin"
          >
            <el-tooltip
              :enterable="false"
              :content="$t('miniApp.pinnedSideNav')"
            >
              <svg-icon :icon-class="sideNavPinIcon" />
            </el-tooltip>
          </div>
          <div
            v-if="isEditMode"
            class="icon-arrow"
            @click="$router.push({ name: 'MiniAppList' })"
          >
            <svg-icon icon-class="arrow-left" />
          </div>
          <div
            v-else
            class="app-logo"
          >
            <img
              v-if="logoType === 'kiwi-smart'"
              class="app-logo__img"
              src="@/assets/images/kiwi-smart.png"
            >
            <img
              v-else
              class="app-logo__img"
              src="@/assets/images/logo-light.svg"
            >
          </div>
          <div
            :class="{ 'is-live': !isEditMode }"
            class="app-name"
          >
            <template v-if="isEditingAppName">
              <input-verify
                v-validate="`required|max:${max}`"
                key="appNameInput"
                v-model="newAppEditModeName"
                class="new-name-input"
                name="appNameInput"
              />
              <button
                class="btn-m btn-default"
                @click="updateAppName"
              >
                <svg-icon
                  v-if="isProcessing"
                  icon-class="spinner"
                />
                {{ $t('button.save') }}
              </button>
              <button
                class="btn-m btn-outline cancel-btn"
                @click="isEditingAppName = false"
              >
                {{ $t('button.cancel') }}
              </button>
            </template>
            <template v-else>
              {{ appData.name }}
              <div
                v-if="isEditMode"
                class="edit-app-name"
                @click="isEditingAppName = true"
              >
                <svg-icon
                  icon-class="edit"
                  class="icon-edit"
                />
              </div>
            </template>
          </div>
        </div>
        <div
          v-if="isEditMode"
          class="nav--right"
        >
          <div class="button-container">
            <span
              v-if="viewModeUpdateDate"
              class="button-container__time"
            >
              {{ $t('miniApp.updateTime') + '：' + formatTimeStamp(viewModeUpdateDate) }}
            </span>
            <template v-if="isEditMode">
              <task-notifier />
              <span
                :class="{ 'button-container__status--active': isPublished }"
                class="button-container__status"
              >
                {{ isPublished ? $t('miniApp.hasPublished') : $t('miniApp.notPublished') }}
              </span>
              <button
                v-if="!isPublished"
                :disabled="isProcessing"
                type="button"
                class="btn-m btn-default button-container__button"
                @click="publishMiniApp('publish')"
              >
                {{ $t('miniApp.publish') }}
              </button>
              <template v-if="isPublished">
                <button
                  :disabled="isProcessing"
                  type="button"
                  class="btn-m btn-default button-container__button"
                  @click="publishMiniApp('update')"
                >
                  {{ $t('button.update') }}
                </button>
                <button
                  :disabled="isProcessing"
                  type="button"
                  class="btn-m btn-secondary button-container__button"
                  @click="unpublishMiniApp"
                >
                  {{ $t('miniApp.unpublish') }}
                </button>
              </template>
              <button
                type="button"
                class="btn-m btn-secondary button-container__button"
                @click="directToCertainModeMiniApp('preview')"
              >
                {{ $t('miniApp.preview') }}
              </button>
              <custom-dropdown-select
                :data-list="otherFeatureList"
                trigger="hover"
                @select="(_id) => otherFeatureList.find(({ id }) => id === _id).action()"
              >
                <template #display>
                  <button
                    type="button"
                    class="btn-m btn-secondary button-container__button"
                  >
                    <svg-icon
                      icon-class="more"
                      class="icon"
                    />
                  </button>
                </template>
              </custom-dropdown-select>
            </template>
          </div>
        </div>
        <div
          v-else
          class="nav--right"
        >
          <task-notifier />
        </div>
      </nav>
      <div class="mini-app__content">
        <template v-if="(isViewMode && !isPublished)">
          <div class="empty-block">
            {{ $t('miniApp.noData') }}
          </div>
        </template>
        <template v-else>
          <div
            class="mini-app__side-nav"
            @mouseenter="handleSideNavPinnerHover(true)"
            @mouseleave="handleSideNavPinnerHover(false)"
            :class="{
              'mini-app__side-nav--pinned': isSideNavPin,
              'mini-app__side-nav--show': isSideNavShow && !isSideNavPin
            }"
          >
            <mini-app-side-nav
              :is-edit-mode="isEditMode"
              :dashboard-list="dashboardList"
              :current-dashboard-id="currentDashboardId"
              :is-warning-module-activate="isWarningModuleActivate"
              :is-show-warning-module="isShowWarningModule"
              :dashboard-limit="miniAppList.dashboardLimit"
              @openWarningModule="openWarningModule"
              @activeCertainDashboard="activeCertainDashboard($event)"
              @showCreateDashboardDialog="isShowCreateDashboardDialog = true"
              @updateDashboardOrder="updateDashboardOrder($t('miniApp.dashboard'))"
            />
            <div
              class="mini-app__side-nav-toggle"
              @click.stop="handleSideNavPinClick(false)"
              v-if="isSideNavPin"
            >
              <el-tooltip
                :enterable="false"
                placement="right"
                content="隱藏側邊區塊"
              >
                <svg-icon icon-class="arrow-right" />
              </el-tooltip>
            </div>
          </div>
          <!-- 監控示警模組 -->
          <main
            v-if="isShowWarningModule && !currentDashboardId"
            class="mini-app__main warning"
          >
            <warning-module
              :setting="warningModuleSetting"
              :dashboard-list="dashboardList"
              :app-id="miniApp.id"
              @update="updateWarningModuleSetting"
              @goToCertainDashboard="warningLogTriggered($event)"
            />
          </main>
          <!-- 分析看板模組 -->
          <div
            v-else-if="dashboardList.length === 0"
            class="empty-block"
          >
            {{ $t('miniApp.noneDashboard') }}
            <button
              v-if="isEditMode"
              class="btn-m btn-default btn-has-icon create-btn"
              @click="isShowCreateDashboardDialog = true"
            >
              <BeginnerGuide
                :content="$t('beginnerGuide.createDashboard')"
                v-if="isGuideMode"
              />
              <svg-icon icon-class="plus" />
              {{ $t('miniApp.createDashboard') }}
            </button>
          </div>
          <main
            v-else
            class="mini-app__main dashboard"
          >
            <div class="dashboard__header">
              <div class="header-left">
                <template v-if="isEditingDashboardName">
                  <input-verify
                    v-validate="`required|max:${max}`"
                    key="dashboardNameInput"
                    v-model="newDashboardName"
                    class="new-name-input"
                    name="dashboardNameInput"
                  />
                  <button
                    class="btn-m btn-default"
                    @click="updateDashboardName"
                  >
                    {{ $t('button.save') }}
                  </button>
                  <button
                    class="btn-m btn-outline cancel-btn"
                    @click="isEditingDashboardName = false"
                  >
                    {{ $t('button.cancel') }}
                  </button>
                </template>
                <template v-else>
                  <span class="name">{{ currentDashboard.name }}</span>
                  <div
                    v-if="isEditMode"
                    @click="isEditingDashboardName = true"
                  >
                    <svg-icon
                      icon-class="edit"
                      class="icon-edit"
                    />
                  </div>
                </template>
              </div>
              <div class="header-right">
                <custom-dropdown-select
                  v-if="isEditMode"
                  :data-list="componentTypeOptions"
                  :has-bullet-point="false"
                  trigger="hover"
                  class="component-type-dropdown"
                  @select="createComponentType"
                >
                  <template #display>
                    <button class="btn-m btn-outline btn-has-icon create-component-btn">
                      <BeginnerGuide
                        :content="$t('beginnerGuide.createComponentButton')"
                        v-if="isGuideMode"
                      />
                      <svg-icon icon-class="plus" />
                      <span class="button-label">{{ $t('miniApp.component') }}</span>
                      <svg-icon
                        icon-class="triangle"
                        class="icon-triangle"
                      />
                    </button>
                  </template>
                </custom-dropdown-select>
                <custom-dropdown-select
                  v-if="isEditMode"
                  :data-list="controlTypeOptions"
                  :has-bullet-point="false"
                  trigger="hover"
                  @select="createFilterAndControl"
                >
                  <template #display>
                    <button
                      class="btn-m btn-outline btn-has-icon create-filter-btn"
                      @click.prevent
                    >
                      <svg-icon icon-class="plus" />
                      <span class="button-label">{{ $t('miniApp.panelControl') }}</span>
                      <svg-icon
                        icon-class="triangle"
                        class="icon-triangle"
                      />
                    </button>
                  </template>
                </custom-dropdown-select>
                <!--Filter-->
                <custom-dropdown-select
                  v-if="isEditMode"
                  :data-list="filterTypeOptions"
                  :has-bullet-point="false"
                  trigger="hover"
                  @select="createFilterAndControl"
                >
                  <template #display>
                    <button
                      class="btn-m btn-outline btn-has-icon create-filter-btn"
                      @click.prevent
                    >
                      <BeginnerGuide
                        :content="$t('beginnerGuide.createFilterButton')"
                        v-if="isGuideMode"
                      />
                      <svg-icon icon-class="plus" />{{ $t('miniApp.filterCondition') }}
                    </button>
                  </template>
                </custom-dropdown-select>
                <div
                  v-if="isEditMode"
                  class="dashboard-setting-box"
                >
                  <svg-icon
                    icon-class="more"
                    class="more-icon"
                  />
                  <dropdown-select
                    :bar-data="dashboardSettingOptions"
                    @switchDialogName="switchDialogName($event)"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="currentControlList.length > 0 || yAxisControlList.length > 0"
              :class="{ 'editing': isEditMode }"
              class="mini-app__dashboard-control mini-app__dashboard-control--top"
            >
              <!--Control panel-->
              <filter-control-panel
                v-if="currentControlList.length > 0"
                type="controller"
                :key="'control' + currentDashboardId"
                :is-edit-mode="isEditMode"
                :initial-filter-list="currentControlList"
                class="mini-app__dashboard-filter"
                @updateFilter="updateFilter($event, 'controlList')"
                @updateInit="isCurrentDashboardInit = $event"
              />
              <!--Y Axis Control panel-->
              <axis-control-panel
                v-if="yAxisControlList.length > 0"
                :key="'yAxisControl' + currentDashboardId"
                :is-edit-mode="isEditMode"
                :initial-control-list="yAxisControlList"
                class="mini-app__dashboard-filter"
                @updateControl="updateYAxisControl"
              />
            </div>
            <div
              v-if="currentFilterList.length > 0"
              :class="{ 'editing': isEditMode }"
              class="mini-app__dashboard-control mini-app__dashboard-control--bottom"
            >
              <BeginnerGuide
                :content="$t('beginnerGuide.filterBar')"
                v-if="isGuideMode"
              />
              <!--Filter Panel-->
              <filter-control-panel
                type="filter"
                :key="'filter' + currentDashboardId"
                :is-edit-mode="isEditMode"
                :initial-filter-list="currentFilterList"
                class="mini-app__dashboard-filter"
                @updateFilter="updateFilter($event, 'filterList')"
              />
            </div>
            <div class="mini-app__dashboard-components">
              <template v-if="currentDashboard && currentDashboard.components.length > 0 && (componentsLayout !== null && componentsLayout.length > 0)">
                <grid-layout
                  :layout="componentsLayout"
                  :col-num="12"
                  :row-height="50"
                  :is-draggable="true"
                  :is-resizable="true"
                  :is-mirrored="false"
                  :vertical-compact="true"
                  :margin="[10, 10]"
                  :use-css-transforms="true"
                  @layout-updated="updateComponentLayout"
                >
                  <grid-item
                    v-for="(item, i) in componentsLayout"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :key="item.i"
                    :static="!isEditMode"
                    :min-h="3"
                    :min-w="2"
                  >
                    <dashboard-task
                      :key="`${currentDashboard.components[i].uuid} - ${currentDashboard.components[i].updateTime}`"
                      :task-index="i"
                      :filters="currentFilterList"
                      :y-axis-controls="yAxisControlList"
                      :controls="currentControlList"
                      :component-data="currentDashboard.components[i]"
                      :is-edit-mode="isEditMode"
                      :warning-module-setting="warningModuleSetting"
                      :is-current-dashboard-init="isCurrentDashboardInit"
                      :time-zone="appTimeZone"
                      :app-id="miniApp.id"
                      @redirect="activeCertainDashboard($event)"
                      @deleteComponentRelation="deleteComponentRelation"
                      @columnTriggered="columnTriggered"
                      @rowTriggered="rowTriggered"
                      @chartTriggered="chartTriggered"
                      @warningLogTriggered="warningLogTriggered($event)"
                      @goToCertainDashboard="activeCertainDashboard($event)"
                      @switchDialogName="handleDashboardSwitchName($event, currentDashboard.components[i])"
                      @updateComponent="updateComponentSegmentation"
                      @setAskQuestionFlag="checkAskQuestionFlags"
                    >
                      <template
                        v-if="currentDashboard.components[i].type === 'monitor-warning-list'"
                        slot="icon"
                      >
                        <svg-icon
                          icon-class="warning"
                          class="warning-icon"
                        />
                      </template>
                    </dashboard-task>
                  </grid-item>
                </grid-layout>
              </template>
              <template v-else>
                <div class="empty-block">
                  {{ $t('miniApp.noneComponent') }}
                  <button
                    v-if="isEditMode"
                    class="btn-m btn-default btn-has-icon create-btn"
                    @click="createComponentType('General')"
                  >
                    <BeginnerGuide
                      :content="$t('beginnerGuide.createComponent')"
                      v-if="isGuideMode"
                    />
                    <svg-icon icon-class="plus" />
                    {{ $t('miniApp.createComponent') }}
                  </button>
                </div>
              </template>
            </div>
          </main>
        </template>
      </div>
    </main>
    <div
      v-show="isProcessing"
      class="dialog"
    >
      <spinner
        :title="$t('button.processing')"
        size="50"
      />
    </div>
    <create-dashboard-dialog
      v-if="isShowCreateDashboardDialog"
      :is-processing="isProcessingCreateDashboard"
      @close="isShowCreateDashboardDialog = false"
      @create="createDashboard"
    />
    <delete-dashboard-dialog
      v-if="isShowDeleteDashboardDialog"
      :dashboard-name="currentDashboard.name"
      @close="isShowDeleteDashboardDialog = false"
      @confirm="deleteDashboard"
    />
    <create-filter-dialog
      v-if="isShowCreateFilterDialog"
      :is-processing="isProcessing"
      :title="filterCreationDialogTitle"
      :is-hierarchical-filter="isHierarchicalFilter"
      :filter-type="createFilterType"
      @closeDialog="closeFilterCreationDialog"
      @filterCreated="saveCreatedFilter"
    />
    <create-component-dialog
      v-if="isShowCreateComponentDialog"
      :initial-current-component="currentComponent || initComponent"
      :dashboard-list="dashboardList"
      :filters="currentFilterList"
      :controls="currentControlList"
      :time-zone="appTimeZone"
      :init="isCreateComponentInit"
      @close="closeCreateComponentDialog"
      @create="createComponent"
      @updateSetting="updateComponentSetting"
    />
    <delete-component-dialog
      v-if="isShowDeleteComponentDialog"
      @close="isShowDeleteComponentDialog = false"
      @confirm="deleteComponent"
    />
    <update-dashboard-name-dialog
      v-if="isShowUpdateDashboardNameDialog"
      :original-dashboard-name="currentDashboard.name"
      @close="isShowUpdateDashboardNameDialog = false"
      @confirm="updateDashboardNameByDialog"
    />
    <writing-dialog
      v-if="isShowShare"
      :title="$t('miniApp.getPublishedUrl')"
      :button="$t('button.copy')"
      :show-both="false"
      @closeDialog="closeShareDialog"
      @confirmBtn="copyLink"
    >
      <input
        ref="shareInput"
        v-model="shareLink"
        readonly
        type="text"
        class="input mini-app__dialog-input"
      >
    </writing-dialog>
    <decide-dialog
      v-if="isShowDelete"
      :is-processing="isProcessing"
      :title="`${confirmDeleteText} ${appData.name}？`"
      :type="'delete'"
      @closeDialog="closeDeleteDialog"
      @confirmBtn="confirmDelete"
    />
    <component-to-alert-condition-dialog
      v-if="isShowCreateWarningCriteriaDialog"
      :component-data="componentToWarningCriteriaData"
      @update="addComponentAlertToWarningModuleSetting"
      @close="closeCreateWarningCriteriaDialog"
      @converted="closeCreateWarningCriteriaDialog"
      @confirm="deleteComponent"
    />
    <data-settings-dialog
      v-if="dataSettingsDialogMode !== null"
      :group-id="+$route.params.group_id"
      :app-id="miniApp.id"
      :mode="dataSettingsDialogMode"
      @done="handleDataSettingDialogDone"
      @exit="dataSettingsDialogMode = null"
    />
  </div>
</template>

<script>
// packages
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { Message } from 'element-ui'
import { v4 as uuidv4 } from 'uuid'
import { mapState, mapActions, mapGetters } from 'vuex'
import VueGridLayout from 'vue-grid-layout'
import { defineComponent } from '@vue/composition-api'

// API
import {
  getMiniAppInfo,
  updateAppSetting,
  deleteMiniApp
} from '@/API/MiniApp'
import { updateAlertTimeZone } from '@/API/Alert'

// utils
import { versionList, updateAppSettingVersion as miniAppSchemaMigration } from '@/utils/miniAppSchemaMigration'
import { generateLayoutMap, layoutLeftSpace } from '@/utils/findDraggableGridSpace.js'
import { useEventLog } from '@/utils/composable/eventLog'
import globalEmitter from '@/utils/globalEmitter'

// modules
import { useAskingModuleContext } from '@/modules/shared/asking'
import { useMiniAppModuleStore } from '@/modules/miniApp'
import DataSettingsDialog from '@/modules/miniApp/components/DataSettingsDialog.vue'
import { migrationConfirm } from '@/modules/miniApp/composable/migrationConfirm'

// components
import TaskNotifier from '@/components/TaskNotifier.vue'
import CustomDropdownSelect from '@/components/select/CustomDropdownSelect'
import DefaultSelect from '@/components/select/DefaultSelect'
import DecideDialog from '@/components/dialog/DecideDialog'
import WritingDialog from '@/components/dialog/WritingDialog'
import SySelect from '@/components/select/SySelect'
import InputVerify from '@/components/InputVerify'
import DropdownSelect from '@/components/select/DropdownSelect'
import { MiniAppStatus, downloadExportedApp } from '@/modules/miniApp/utils'
import MiniAppSideNav from './components/MiniAppSideNav'
import DashboardTask from './components/dashboard-components/DashboardTask'
import WarningModule from './components/warning-module/WarningModule'
import CreateDashboardDialog from './dialog/CreateDashboardDialog.vue'
import CreateComponentDialog from './dialog/CreateComponentDialog.vue'
import CreateFilterDialog from './dialog/CreateFilterDialog.vue'
import DeleteDashboardDialog from './dialog/DeleteDashboardDialog.vue'
import DeleteComponentDialog from './dialog/DeleteComponentDialog.vue'
import UpdateDashboardNameDialog from './dialog/UpdateDashboardNameDialog.vue'
import ComponentToAlertConditionDialog from './dialog/ComponentToAlertConditionDialog'
import FilterControlPanel from './filter/FilterControlPanel'
import AxisControlPanel from './filter/AxisControlPanel'
import BeginnerGuide from '@/components/BeginnerGuide'

export default defineComponent({
  inject: ['$validator'],
  name: 'MiniApp',
  components: {
    // ----- public -----
    TaskNotifier,
    InputVerify,
    CustomDropdownSelect,
    WritingDialog,
    SySelect,
    DecideDialog,
    DefaultSelect,
    BeginnerGuide,
    // ----- miniApp -----
    MiniAppSideNav, // composable
    DashboardTask,
    CreateDashboardDialog, // composable
    CreateComponentDialog,
    CreateFilterDialog,
    DeleteDashboardDialog, // composable
    DeleteComponentDialog, // composable
    UpdateDashboardNameDialog, // composable
    DataSettingsDialog, // composable
    DropdownSelect, // composable
    FilterControlPanel, // composable
    AxisControlPanel, // composable
    WarningModule, // composable
    ComponentToAlertConditionDialog,
    // ----- packages -----
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  setup () {
    const { appEventLog } = useEventLog()

    const {
      parserLanguage,
      setParserLanguage,
      setFilterList,
      setLastTimeSelectedColumnList,
      askCancelTokenList,
      resetCancelToken,
      cancelAllToken
    } = useAskingModuleContext()

    const {
      fetchMiniAppList,
      miniAppList
    } = useMiniAppModuleStore()

    return {
      appEventLog,
      parserLanguage,
      setParserLanguage,
      setFilterList,
      setLastTimeSelectedColumnList,
      askCancelTokenList,
      resetCancelToken,
      cancelAllToken,
      fetchMiniAppList,
      miniAppList
    }
  },
  data () {
    return {
      miniApp: {},
      currentDashboardId: null,
      currentComponentId: null,
      componentToWarningCriteriaData: {},
      isShowWarningModule: false,
      isShowCreateDashboardDialog: false,
      isShowCreateComponentDialog: false,
      isCreateComponentInit: false,
      isShowDeleteDashboardDialog: false,
      isShowDeleteComponentDialog: false,
      isShowCreateWarningCriteriaDialog: false,
      isShowUpdateDashboardNameDialog: false,
      dataSettingsDialogMode: null,
      isLoading: false,
      isProcessing: false,
      isProcessingCreateDashboard: false,
      isShowShare: false,
      shareLink: null,
      confirmDeleteText: this.$t('editing.confirmDelete'),
      isShowDelete: false,
      newAppEditModeName: '',
      isEditingAppName: false,
      newDashboardName: '',
      isEditingDashboardName: false,
      isShowCreateFilterDialog: false,
      createFilterType: null,
      isHierarchicalFilter: null,
      filterCreationDialogTitle: null,
      draggedContext: { index: -1, futureIndex: -1 },
      isCurrentDashboardInit: false,
      scriptOptionList: [],
      initComponent: null,
      isMiniAppCompiled: false,
      componentsLayout: null,
      updateSegmentationTimer: null,
      askQuestionFlags: {},
      // side nav
      sideNavPinerLeaveTimer: null,
      isMouseEnterSideNavPinner: false,
      isSideNavShow: false,
      isSideNavPin: true,
      // computed
      dashboardSettingOptions: [
        {
          title: 'miniApp.updateDashboardName',
          icon: 'edit',
          dialogName: 'UpdateDashboardName'
        },
        {
          title: 'miniApp.deleteDashboard',
          icon: 'delete',
          dialogName: 'DeleteDashboard'
        }
      ]
    }
  },
  computed: {
    ...mapState(['isGuideMode']),
    ...mapGetters('userManagement', ['getCurrentAccountId', 'getCurrentGroupId']),
    ...mapGetters('validation', { max: 'fieldCommonMaxLength' }),
    logoType () {
      return localStorage.getItem('currentLogo')
    },
    isEditMode () {
      return this.$route.query?.mode === 'edit'
    },
    isViewMode () {
      return this.$route.query?.mode === 'view'
    },
    isPreviewMode () {
      return this.$route.query?.mode === 'preview'
    },
    isPublished () {
      return this.miniApp.settings?.isPublished
    },
    appData () {
      if (!this.miniApp.settings) return false
      const { editModeData, viewModeData } = this.miniApp.settings
      return (this.isEditMode || this.isPreviewMode) ? editModeData : viewModeData
    },
    dashboardList () {
      return this?.appData?.dashboards ?? []
    },
    currentDashboard () {
      return this.dashboardList.length > 0
        ? this.dashboardList.find(board => board.uuid === this.currentDashboardId)
        : null
    },
    currentComponent () {
      return this.currentDashboard
        ? this.currentDashboard.components.find(comp => comp.uuid === this.currentComponentId)
        : null
    },
    otherFeatureList () {
      if (!this.isEditMode || !this.appData) return []
      return [
        ...(this.isPublished ? [
          {
            id: 'shareUrl',
            name: this.$t('miniApp.getPublishedUrl'),
            icon: 'share',
            action: () => this.showShareDialog()
          },
          {
            id: 'goToLivePage',
            name: this.$t('miniApp.goToLivePage'),
            icon: 'goto_page',
            action: () => this.directToCertainModeMiniApp('view')
          }
        ] : []),
        {
          id: 'updateMiniAppDataSettings',
          name: this.$t('miniApp.settingsOfAppDataSource'),
          icon: 'database',
          action: () => this.dataSettingsDialogMode = 'update'
        },
        {
          id: 'copyMiniApp',
          name: this.$t('miniApp.option.copy'),
          icon: 'copy',
          disabled: this.isTriggeredAppLimit,
          action: () => this.dataSettingsDialogMode = 'copy'
        },
        {
          id: 'exportMiniApp',
          name: this.$t('miniApp.option.export'),
          icon: 'export',
          action: () => this.downloadExportedApp(this.miniApp.id, this.miniApp.name)
        },
        {
          id: 'deleteMiniApp',
          name: this.$t('miniApp.deleteApplication'),
          icon: 'delete',
          action: () => this.showDeleteDialog()
        }
      ]
    },
    controlTypeOptions () {
      return [
        {
          name: this.$t('miniApp.generalControl'),
          id: 'SingleChoiceFilter'
        },
        {
          name: this.$t('miniApp.hierarchicalFilter'),
          id: 'HierarchicalFilter'
        },
        {
          name: this.$t('miniApp.yAxisControl'),
          id: 'YAxisController'
        }
      ]
    },
    componentTypeOptions () {
      return [
        {
          name: this.$t('miniApp.generalComponent'),
          id: 'General'
        },
        {
          name: this.$t('miniApp.monitorComponent'),
          id: 'MonitorWarning'
        },
        {
          name: this.$t('miniApp.abnormalStatisticsComponent'),
          children: [
            {
              name: this.$t('miniApp.unhandledAbnormalStatisticsComponent'),
              id: 'UnhandledAbnormalStatistics'
            },
            {
              name: this.$t('miniApp.handledAbnormalStatisticsComponent'),
              id: 'HandledAbnormalStatistics'
            }
          ]
        },
        {
          name: this.$t('miniApp.simulateComponent'),
          children: [
            {
              name: this.$t('miniApp.modelSimulateComponent'),
              id: 'Simulator'
            },
            // 暫時不開放給使用者使用
            {
              name: this.$t('miniApp.parametersOptimizedSimulateComponent'),
              id: 'ParametersOptimizedSimulator'
            }
          ]
        },
        {
          name: this.$t('miniApp.specialIndexTypeComponent'),
          id: 'Formula'
        }
      ]
    },
    filterTypeOptions () {
      const hasRelativeDateTimeFilter = this.currentFilterList.find(filterSet => filterSet.find(filter => filter.column.type === 'RELATIVEDATETIME' || filter.column.type === 'CUSTOMDATETIME'))
      return [
        {
          name: this.$t('miniApp.generalFilter'),
          id: 'MulitipleChoiceFilter'
        },
        {
          name: this.$t('miniApp.dateTimeFilter'),
          childrenPosition: 'left',
          disabled: !!hasRelativeDateTimeFilter,
          children: [
            {
              name: this.$t('miniApp.defaultDateTimeFilter'),
              id: 'RELATIVEDATETIME'
            },
            {
              name: this.$t('miniApp.customDateTimeFilter'),
              id: 'CUSTOMDATETIME'
            }
          ]
          // id: 'TimeFilter'
        }
      ]
    },
    miniAppId () {
      return this.$route.params.mini_app_id
    },
    currentFilterList () {
      return JSON.parse(JSON.stringify(this.currentDashboard?.filterList ?? []))
    },
    currentControlList () {
      return JSON.parse(JSON.stringify(this.currentDashboard?.controlList ?? []))
    },
    yAxisControlList () {
      return JSON.parse(JSON.stringify(this.currentDashboard?.yAxisControlList ?? []))
    },
    appTimeZone () {
      return this?.miniApp?.settings?.editModeData?.config?.timeZone ?? momentTZ.tz.guess()
    },
    sideNavPinIcon () {
      return this.isMouseEnterSideNavPinner ? 'pin' : 'side-nav'
    },
    viewModeUpdateDate () {
      return this.miniApp?.settings?.viewModeData?.updateDate ?? null
    },
    warningModuleSetting: {
      get () {
        return this.appData?.warningModule
      },
      set (val) {
        if (this.appData?.warningModule) {
          this.appData.warningModule = val
        }
      }
    },
    isWarningModuleActivate () {
      return this.warningModuleSetting?.activate
    },
    isTriggeredAppLimit () {
      return this.miniAppList.appLimit !== -1 && this.miniAppList.appBasicDtoList.length >= this.miniAppList.appLimit
    }
  },
  provide () {
    return {
      appTimeZone: () => this.appTimeZone,
      isInMiniApp: true
    }
  },
  watch: {
    isSideNavPin (val) {
      if (!val) {
        this.isSideNavShow = false
        this.isMouseEnterSideNavPinner = false
      }
    },
    currentDashboard () {
      this.newDashboardName = this.currentDashboard?.name ?? null
    },
    currentDashboardId () {
      this.cancelRequest()
    }
  },
  async created () {
    const confirm = await migrationConfirm(this.miniAppId)
    if (confirm) {
      this.getMiniAppInfo()
    } else {
      this.$router.push({ name: 'MiniAppList' })
    }
  },
  destroyed () {
    this.resetCancelToken()
  },
  methods: {
    ...mapActions('dataSource', ['changeDataSourceById']),
    // ----- data fetch -----
    async getMiniAppInfo () {
      this.isLoading = true
      try {
        await this.fetchMiniAppList()
        let miniAppInfo = await getMiniAppInfo(this.miniAppId)

        if (miniAppInfo.status !== MiniAppStatus.Enable) {
          this.$router.push({ name: 'MiniAppList' })
          return
        }

        // update page title
        document.title = `JarviX - MiniApp - ${miniAppInfo.name} (${this.$route.query?.mode || 'illegal'})`

        // check miniApp setting version
        const isNewestVersion = miniAppInfo.settings.version === versionList[versionList.length - 1]
        const updatedMiniAppInfo = isNewestVersion
          ? JSON.parse(JSON.stringify(miniAppInfo))
          : miniAppSchemaMigration(JSON.parse(JSON.stringify(miniAppInfo)))

        await this.updateAppSetting(updatedMiniAppInfo)

        if (!isNewestVersion) {
          this.appEventLog('migration', { appId: updatedMiniAppInfo.id, version: versionList[versionList.length - 1] })
        }

        this.miniApp = updatedMiniAppInfo
        this.isSideNavPin = this.isEditMode
          ? (updatedMiniAppInfo?.settings?.editModeData?.config?.isSideNavPin ?? true)
          : true

        this.newAppEditModeName = this.appData.name

        // 如果有 dashboard, focus 在第一個
        if (this.dashboardList.length > 0 && !this.currentDashboardId) {
          this.activeCertainDashboard(this.dashboardList[0].uuid)
          this.generateComponentLayout()
        }

        // 如果有控制項，或當前 Dashboard 有控制項是剛被創完需被設定預設值時，應等待控制項更新完成後帶上新 reestriction 問問題
        this.isCurrentDashboardInit = this.currentControlList.length === 0
      } catch {
        this.$router.push({
          name: 'MiniAppList',
          params: {
            account_id: this.getCurrentAccountId,
            group_id: this.getCurrentGroupId
          }
        })
      } finally {
        this.isLoading = false
      }
    },
    updateAppSetting (appInfo, miniAppId = this.miniAppId) {
      return updateAppSetting(miniAppId, { ...appInfo })
    },
    // ----- miniApp -----
    async confirmDelete () {
      try {
        this.isProcessing = true
        await deleteMiniApp(this.miniAppId)
        this.appEventLog('deleteApp', { appId: this.miniAppId })

        Message({
          message: this.$t('message.deleteSuccess'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
        this.isShowDelete = false
        this.$router.push({ name: 'MiniAppList' })
      } finally {
        this.isProcessing = false
      }
    },
    showDeleteDialog () {
      this.isShowDelete = true
    },
    closeDeleteDialog () {
      this.isShowDelete = false
    },
    async updateAppName () {
      try {
        const valid = await this.$validator.validate('appNameInput')
        if (!valid) throw new Error('new App name not valid')

        this.isProcessing = true
        const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
        editedMiniApp.settings.editModeData.name = this.newAppEditModeName

        await this.updateAppSetting(editedMiniApp)
        this.appEventLog('updateAppName', {
          appId: editedMiniApp.id,
          oldName: this.miniApp.settings.editModeData.name,
          newName: editedMiniApp.settings.editModeData.name
        })
        this.miniApp = editedMiniApp
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isEditingAppName = false
        this.isProcessing = false
      }
    },
    async handleDataSettingDialogDone () {
      await this.getMiniAppInfo()
      this.appEventLog('changeDataSource', {
        appId: this.miniApp.id
      })
    },
    // ----- dashbaords -----
    activeCertainDashboard (dashboardId, dashboardName) {
      if (this.currentDashboardId === dashboardId) return
      if (!this.dashboardList.find(board => board.uuid === dashboardId)) {
        Message({
          message: this.$t('miniApp.dashboardNoLongerExist'),
          type: 'warning',
          duration: 3 * 1000,
          showClose: true
        })
        return
      }
      this.isEditingDashboardName = false
      this.isShowWarningModule = false
      this.currentDashboardId = dashboardId
      this.isCurrentDashboardInit = this.currentControlList.length === 0
      this.newDashboardName = dashboardName || this.currentDashboard.name
      this.generateComponentLayout()
    },
    async createDashboard (newDashBoardInfo) {
      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
      updatedMiniAppData.settings.editModeData.dashboards.push({
        ...newDashBoardInfo,
        components: [],
        filterList: [],
        controlList: [],
        yAxisControlList: []
      })
      this.isProcessingCreateDashboard = true
      try {
        await this.updateAppSetting(updatedMiniAppData)
        this.appEventLog('createDashboard', {
          appId: updatedMiniAppData.id,
          dashboardName: newDashBoardInfo.name
        })
        this.miniApp = updatedMiniAppData
        this.generateComponentLayout()
        this.activeCertainDashboard(newDashBoardInfo.uuid, newDashBoardInfo.name)
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isShowCreateDashboardDialog = false
        this.isProcessingCreateDashboard = false
      }
    },
    async deleteDashboard () {
      const dashboradIndex = this.dashboardList.findIndex(board => board.uuid === this.currentDashboardId)
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      const dashboradName = this.dashboardList[dashboradIndex].name
      editedMiniApp.settings.editModeData.dashboards.splice(dashboradIndex, 1)

      try {
        await this.updateAppSetting(editedMiniApp)
        this.appEventLog('deleteDashboard', {
          appId: this.miniApp.id,
          dashboardName: dashboradName
        })

        // 預設 focus 到剩餘 Dashboard 的第一個，若刪光了就 null
        const newDashboards = editedMiniApp.settings.editModeData.dashboards
        this.currentDashboardId = newDashboards.length ? newDashboards[0].uuid : null
        this.miniApp = editedMiniApp
        this.generateComponentLayout()

        Message({
          message: this.$t('message.deleteSuccess'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isShowDeleteDashboardDialog = false
      }
    },
    async updateDashboardName () {
      try {
        const valid = await this.$validator.validate('dashboardNameInput')
        if (!valid) throw new Error('not valid')

        this.isProcessing = true
        const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))

        let dashboardOldName = null
        editedMiniApp.settings.editModeData.dashboards.forEach(board => {
          if (board.uuid === this.currentDashboardId) {
            dashboardOldName = board.name
            board.name = this.newDashboardName
          }
        })

        await this.updateAppSetting(editedMiniApp)
        this.appEventLog('updateDashboardName', {
          appId: this.miniApp.id,
          oldName: dashboardOldName,
          newName: this.newDashboardName
        })

        this.isEditingDashboardName = false
        this.miniApp = editedMiniApp
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isProcessing = false
      }
    },
    async updateDashboardNameByDialog (newDashboardName) {
      this.isProcessing = true
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))

      let dashboardOldName = null
      editedMiniApp.settings.editModeData.dashboards.forEach(board => {
        if (board.uuid === this.currentDashboardId) {
          dashboardOldName = board.name
          board.name = newDashboardName
        }
      })

      try {
        await this.updateAppSetting(editedMiniApp)
        this.appEventLog('updateDashboardName', {
          appId: editedMiniApp.id,
          oldName: dashboardOldName,
          newName: newDashboardName
        })

        this.miniApp = editedMiniApp
        this.newDashboardName = newDashboardName
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isShowUpdateDashboardNameDialog = false
        this.isProcessing = false
      }
    },
    async updateDashboardOrder (target) {
      try {
        await this.updateAppSetting(this.miniApp)
        this.appEventLog('changeDashboardOrder', {
          appId: this.miniApp.id
        })

        Message({
          message: this.$t('miniApp.orderUpdated', { target }),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      }
    },
    // ----- components -----
    handleDashboardSwitchName ({ name, componentComplementaryInfo }, componentData) {
      this.switchDialogName(name, {
        ...componentData,
        ...componentComplementaryInfo
      })
    },
    async switchDialogName (eventName, componentData) {
      switch (eventName) {
        case 'DeleteComponent':
          this.currentComponentId = componentData && componentData.uuid
          break
        case 'CreateComponent':
          this.isCreateComponentInit = true
          this.currentComponentId = componentData && componentData.uuid
          if (['chart', 'text', 'index'].includes(componentData.type)) {
            try {
              await this.changeDataSourceById({
                dataFrameId: this.currentComponent.source.dataFrameId,
                dataSourceId: this.currentComponent.source.dataSourceId
              })
              const defaultLanguage = (['zh-TW', 'zh-CN'].includes(this.$i18n.local) ? 'ZH-TW' : 'EN-US')
              this.setParserLanguage(this.currentComponent?.questionConfig?.parserLanguage ?? defaultLanguage)
            } catch (err) {
              console.log(err)
            }
          }
          this.setFilterList(this.currentComponent.advanced?.filterList ?? [])
          this.setLastTimeSelectedColumnList(this.currentComponent.advanced?.selectedColumnList ?? null)
          break
        case 'CreateWarningCriteria':
          this.componentToWarningCriteriaData = {
            ...componentData,
            controlList: this.currentDashboard.controlList,
            filterList: this.currentDashboard.filterList
          }
          break
      }
      this[`isShow${eventName}Dialog`] = true
    },
    generateComponentLayout () {
      if (this.currentDashboard && this.currentDashboard.components.length > 0) {
        this.componentsLayout = this.currentDashboard.components.map((component) => ({
          i: component.uuid,
          x: component.displayConfig.position.x ? parseInt(component.displayConfig.position.x) : 0,
          y: component.displayConfig.position.y ? parseInt(component.displayConfig.position.y) : 0,
          w: component.displayConfig.position.column ? parseInt(component.displayConfig.position.column) : 0,
          h: component.displayConfig.position.row ? parseInt(component.displayConfig.position.row) : 0
        }))
      } else {
        this.componentsLayout = []
      }
    },
    updateComponentLayout () {
      if (!(this.componentsLayout && this.miniApp)) return

      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
      const components = updatedMiniAppData.settings.editModeData.dashboards.find(board => board.uuid === this.currentDashboardId).components
      this.componentsLayout.forEach((newPosition, i) => {
        components[i].displayConfig.position.x = newPosition.x
        components[i].displayConfig.position.y = newPosition.y
        components[i].displayConfig.position.column = newPosition.w
        components[i].displayConfig.position.row = newPosition.h
      })
      this.updateAppSetting(updatedMiniAppData)
      this.miniApp = updatedMiniAppData
    },
    createComponentType (type) {
      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
      const dashboardName = this.currentDashboard.name
      switch (type) {
        case 'MonitorWarning':
          this.createDefaultComponent('monitor-warning-list')
          this.appEventLog('createMonitor', {
            appId: updatedMiniAppData.id,
            dashboard: dashboardName
          })
          break
        case 'UnhandledAbnormalStatistics':
          this.createDefaultComponent('unhandled-abnormal-statistics')
          this.appEventLog('createUnhandledAbnormal', {
            appId: updatedMiniAppData.id,
            dashboard: dashboardName
          })
          break
        case 'HandledAbnormalStatistics':
          this.createDefaultComponent('handled-abnormal-statistics')
          this.appEventLog('createHandledAbnormal', {
            appId: updatedMiniAppData.id,
            dashboard: dashboardName
          })
          break
        case 'Simulator':
          this.initComponent = this.componentTemplateFactory('simulator')
          this.isShowCreateComponentDialog = true
          break
        case 'ParametersOptimizedSimulator':
          this.initComponent = this.componentTemplateFactory('parameters-optimized-simulator')
          this.isShowCreateComponentDialog = true
          break
        case 'Formula':
          this.initComponent = this.componentTemplateFactory('formula')
          this.isShowCreateComponentDialog = true
          break
        case 'General':
          this.initComponent = this.componentTemplateFactory()
          this.isShowCreateComponentDialog = true
          break
      }
    },
    async createDefaultComponent (componentType) {
      try {
        this.isProcessing = true
        this.currentComponentId = null

        const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))

        updatedMiniAppData.settings.editModeData.dashboards.forEach(board => {
          if (board.uuid === this.currentDashboardId) {
            const newComponentInfo = JSON.parse(JSON.stringify(this.componentTemplateFactory(componentType)))
            if (this.componentsLayout && this.componentsLayout.length > 0) {
              const newPosition = layoutLeftSpace(generateLayoutMap(this.componentsLayout), newComponentInfo.displayConfig.position.column, newComponentInfo.displayConfig.position.row)
              newComponentInfo.displayConfig.position.x = newPosition.x
              newComponentInfo.displayConfig.position.y = newPosition.y
            }
            board.components.push(newComponentInfo)
          }
        })

        this.closeCreateComponentDialog()

        await this.updateAppSetting(updatedMiniAppData)
        this.miniApp = updatedMiniAppData
        this.generateComponentLayout()
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isProcessing = false
      }
    },
    async createComponent (newComponentInfo) {
      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))

      let dashboardName = null
      updatedMiniAppData.settings.editModeData.dashboards.forEach(board => {
        if (board.uuid === this.currentDashboardId) {
          dashboardName = board.name
          if (this.componentsLayout?.length > 0) {
            const newPosition = layoutLeftSpace(generateLayoutMap(this.componentsLayout), newComponentInfo.displayConfig.position.column, newComponentInfo.displayConfig.position.row)
            newComponentInfo.displayConfig.position.x = newPosition.x
            newComponentInfo.displayConfig.position.y = newPosition.y
          }
          board.components.push(newComponentInfo)
        }
      })

      try {
        await this.updateAppSetting(updatedMiniAppData)
        this.appEventLog('createComponent', {
          appId: updatedMiniAppData.id,
          name: newComponentInfo.displayConfig.title.name,
          dashboard: dashboardName
        })

        this.miniApp = updatedMiniAppData
        this.closeCreateComponentDialog()
        this.generateComponentLayout()
      } catch (e) {
        console.log(e)
        // TODO: 顯示錯誤 message
      } finally {
        this.isProcessing = false
        this.currentComponentId = null
      }
    },
    async deleteComponent () {
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      const componentIndex = this.currentDashboard.components.findIndex(comp => comp.uuid === this.currentComponentId)

      let componentName = null
      let dashboardName = null
      editedMiniApp.settings.editModeData.dashboards.forEach(board => {
        if (board.uuid === this.currentDashboardId) {
          dashboardName = board.name
          componentName = board.components[componentIndex].displayConfig.title.name
          board.components.splice(componentIndex, 1)
        }
      })

      try {
        await this.updateAppSetting(editedMiniApp)
        this.appEventLog('removeComponent', {
          appId: editedMiniApp.id,
          name: componentName,
          dashboard: dashboardName
        })

        this.currentComponentId = null
        this.miniApp = editedMiniApp
        this.generateComponentLayout()

        Message({
          message: this.$t('message.deleteSuccess'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.isShowDeleteComponentDialog = false
      }
    },
    async updateComponentSetting (updatedComponentInfo) {
      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
      let dashboardName = null

      updatedMiniAppData.settings.editModeData.dashboards.forEach((board, boardIndex) => {
        if (board.uuid === this.currentDashboardId) {
          dashboardName = board.name
          board.components.forEach((component, componentIndex) => {
            if (component.uuid === this.currentComponentId) {
              updatedMiniAppData.settings.editModeData.dashboards[boardIndex].components[componentIndex] = {
                ...updatedComponentInfo,
                updateTime: new Date().getTime()
              }
            }
          })
        }
      })
      this.closeCreateComponentDialog()

      try {
        await this.updateAppSetting(updatedMiniAppData)
        this.appEventLog('updateComponent', {
          appId: updatedMiniAppData.id,
          name: updatedComponentInfo.displayConfig.title.name,
          dashboard: dashboardName
        })
        this.miniApp = updatedMiniAppData
        this.generateComponentLayout()
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        this.currentComponentId = null
      }
    },
    async checkAskQuestionFlags ({ uuid, status }) {
      this.askQuestionFlags[uuid] = status
      if (Object.values(this.askQuestionFlags).every((val) => val)) {
        try {
          await this.updateAppSetting({ ...this.miniApp })
        } catch (err) {
          console.log(err)
        } finally {
          Object.keys(this.askQuestionFlags).forEach((key) => {
            delete this.askQuestionFlags[key]
          })
        }
      }
    },
    updateComponentSegmentation (updatedComponentInfo) {
      this.miniApp.settings.editModeData.dashboards.forEach((board, boardIndex) => {
        if (board.uuid === this.currentDashboardId) {
          board.components.forEach((component, componentIndex) => {
            if (component.uuid === updatedComponentInfo.uuid) {
              this.miniApp.settings.editModeData.dashboards[boardIndex].components[componentIndex] = {
                ...updatedComponentInfo,
                updateTime: new Date().getTime()
              }
            }
          })
        }
      })
    },
    async deleteComponentRelation (componentId) {
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      const currentDashboardIndex = this.currentDashboard
        ? this.dashboardList.findIndex(board => board.uuid === this.currentDashboardId)
        : -1

      const component = editedMiniApp.settings.editModeData.dashboards[currentDashboardIndex].components.find(comp => comp.uuid === componentId)
      component.settingConfig.related.dashboard.active = false
      component.settingConfig.related.dashboard.dashboardId = null
      component.settingConfig.related.dashboard.name = null

      try {
        await this.updateAppSetting(editedMiniApp)
        // TODO: 送出 log -> 刪除 component dashboard 連結，這部分是從 DashboardTask 傳上來，但目前沒有觸發
        this.miniApp = editedMiniApp
        this.generateComponentLayout()
        Message({
          message: this.$t('message.deleteSuccess'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      }
    },
    // ----- component(table) -----
    // TODO: check
    columnTriggered ({ relatedDashboardId, cellValue, columnId, columnName }) {
      this.activeCertainDashboard(relatedDashboardId)
      this.currentControlList.forEach(filterSet => {
        filterSet.forEach(filter => {
          // 如果目標 Dashboard 已設定該欄位 controller，就將預設值設定為剛剛使用者點的 cell 的值
          if (filter.column.columnId === columnId || filter.column.name === columnName) filter.optionValues = [cellValue]
        })
      })
    },
    rowTriggered ({ relatedDashboardId, rowData }) {
      this.activeCertainDashboard(relatedDashboardId)
      this.currentControlList.forEach(filterSet => {
        filterSet.forEach(filter => {
          const matchedColumn = rowData.find(column => column.columnId === filter.column.columnId || column.columnName === filter.column.name)
          // 如果目標 Dashboard 已設定該欄位 controller，就將預設值設定為剛剛使用者點的 cell 的值
          if (matchedColumn) filter.optionValues = [matchedColumn.cellValue]
        })
      })
    },
    chartTriggered ({ relatedDashboardId, restrictions }) {
      this.activeCertainDashboard(relatedDashboardId)
      this.currentControlList.forEach(filterSet => {
        filterSet.forEach(filter => {
          // 確認有無對應到欲前往的 dashboard 中的任一控制項
          const targetRestriction = restrictions.find(restriction => (filter.column.columnId === restriction.dc_id || filter.column.name === restriction.display_name))

          if (targetRestriction) {
            const value = targetRestriction.value

            // 檢查 Category 類型的 Chart 中的值是否為數字，若為數字會被加上 []，這邊需要把數字取出來
            const regex = /^\[.+\]$/gm
            if (Array.isArray(value)) {
              filter.optionValues = value.map((val) => regex.test(val) ? val.substring(1, val.length - 1) : val)
            } else {
              filter.optionValues = [regex.test(value) ? value.substring(1, value.length - 1) : value]
            }
          }
        })
      })
    },
    closeCreateComponentDialog () {
      this.currentComponentId = null
      this.initComponent = null
      this.isShowCreateComponentDialog = false
      this.isCreateComponentInit = false
    },
    // ----- component(warning) -----
    // TODO: check
    warningLogTriggered ({ relatedDashboardId, rowData }) {
      this.activeCertainDashboard(relatedDashboardId)

      // 控制項
      if (rowData.controlList.length > 0 && this.currentControlList.length > 0) {
        this.currentControlList.forEach(controlSet => {
          controlSet.forEach(control => {
            // 如果 log rowData 有欄位同 controller 欄位，就將預設值設定為該筆 rowData 該 column 的值
            // 判斷條件：同 columnId 或同 columnName
            const sameColumnRow = rowData.controlList.find(column => column.dataColumnId === control.column.columnId || column.displayName === control.column.columnName)
            if (sameColumnRow) control.optionValues = [sameColumnRow.datum[0]]
          })
        })
      }

      // 篩選器
      if (rowData.filterList.length > 0 && this.currentFilterList.length > 0) {
        this.currentFilterList.forEach(filterSet => {
          filterSet.forEach(filter => {
            // 如果 log rowData 有欄位同 filter 欄位，就將預設值設定為該筆 rowData 該 column 的值
            // 判斷條件：同 columnId 或同 columnName
            const sameColumnRow = rowData.filterList.find(column => column.dataColumnId === filter.column.columnId || column.displayName === filter.column.columnName)
            if (sameColumnRow) filter.optionValues = [sameColumnRow.datum[0]]
          })
        })
      }
    },
    // ----- filters -----
    createFilterAndControl (type) {
      if (!type) return
      if (type === 'RELATIVEDATETIME' || type === 'CUSTOMDATETIME') {
        this.createFilterType = 'filter'
        this.saveCreatedFilter([[
          {
            column: {
              columnId: null,
              name: type === 'RELATIVEDATETIME' ? this.$t('miniApp.defaultDateTimeFilter') : this.$t('miniApp.customDateTimeFilter'),
              type
            },
            optionValues: []
          }
        ]])
        return
      }

      this.isShowCreateFilterDialog = true
      this.isHierarchicalFilter = type === 'HierarchicalFilter'

      switch (type) {
        case 'MulitipleChoiceFilter':
          this.createFilterType = 'filter'
          this.filterCreationDialogTitle = this.$t('miniApp.createFilterCondition')
          break
        case 'SingleChoiceFilter':
          this.createFilterType = 'control'
          this.filterCreationDialogTitle = this.$t('miniApp.createPanelControl')
          break
        case 'HierarchicalFilter':
          this.createFilterType = 'control'
          this.filterCreationDialogTitle = this.$t('miniApp.createHierarchicalFilter')
          break
        case 'YAxisController' :
          this.createFilterType = 'YAxisControl'
          this.filterCreationDialogTitle = this.$t('miniApp.createSingleYAxisController')
          break
      }
    },
    closeFilterCreationDialog () {
      this.isShowCreateFilterDialog = false
      this.isHierarchicalFilter = false
      this.createFilterType = null
    },
    async saveCreatedFilter (filterList) {
      this.isProcessing = true
      const dashboradIndex = this.dashboardList.findIndex(board => board.uuid === this.currentDashboardId)
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))

      // 決定要新增到控制項或篩選條件中
      if (this.createFilterType === 'YAxisControl') {
        editedMiniApp.settings.editModeData.dashboards[dashboradIndex].yAxisControlList.push(filterList)
      } else if (this.createFilterType === 'control') {
        editedMiniApp.settings.editModeData.dashboards[dashboradIndex].controlList.push(...filterList)
      } else if (this.createFilterType === 'filter') {
        editedMiniApp.settings.editModeData.dashboards[dashboradIndex].filterList.push(...filterList)
      }

      // 更新 app 資料
      try {
        await this.updateAppSetting(editedMiniApp)
        this.appEventLog(this.createFilterType === 'YAxisControl' ? 'createYAxis' : 'createFilter', { appId: editedMiniApp.id })
        this.isShowCreateFilterDialog = false
        this.getMiniAppInfo()
      } finally {
        this.isProcessing = false
      }
    },
    async updateFilter (updatedFilterList, type) {
      const modeData = (this.isEditMode || this.isPreviewMode) ? 'editModeData' : 'viewModeData'
      const dashboradIndex = this.dashboardList.findIndex(board => board.uuid === this.currentDashboardId)
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      editedMiniApp.settings[modeData].dashboards[dashboradIndex][type] = updatedFilterList
      this.miniApp = editedMiniApp

      // edit mode 下可以賦予預設值，其餘模式則無法
      if (!this.isEditMode) return this.isProcessing = false

      try {
        await this.updateAppSetting(editedMiniApp) // 更新 app
      } catch (e) {
        // TODO: 顯示錯誤 message
      }
    },
    async updateYAxisControl (updatedControlList) {
      const modeData = (this.isEditMode || this.isPreviewMode) ? 'editModeData' : 'viewModeData'
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      const dashboradIndex = this.dashboardList.findIndex(board => board.uuid === this.currentDashboardId)
      editedMiniApp.settings[modeData].dashboards[dashboradIndex].yAxisControlList = updatedControlList
      this.miniApp = editedMiniApp

      // edit mode 下可以賦予預設值，其餘模式則無法
      if (!this.isEditMode) return this.isProcessing = false

      try {
        await this.updateAppSetting(editedMiniApp) // 更新 app
      } catch (e) {
        // TODO: 顯示錯誤 message
      }
    },
    // ----- warning modules -----
    async openWarningModule () {
      this.isProcessing = true
      try {
        const miniAppInfo = await getMiniAppInfo(this.miniAppId)
        this.miniApp = JSON.parse(JSON.stringify(miniAppInfo))
        this.isShowWarningModule = true
        this.currentDashboardId = null
        this.generateComponentLayout()
      } finally {
        this.isProcessing = false
      }
    },
    closeCreateWarningCriteriaDialog () {
      this.componentToWarningCriteriaData = {}
      this.isShowCreateWarningCriteriaDialog = false
    },
    async addComponentAlertToWarningModuleSetting (conditionId) {
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      editedMiniApp.settings.editModeData.warningModule.conditions.push({
        conditionId: conditionId,
        relatedDashboardId: null
      })
      try {
        await Promise.all([
          updateAlertTimeZone({
            conditionIds: [conditionId],
            groupId: this.$route.params.group_id,
            timeZone: this.appTimeZone
          }),
          this.updateAppSetting(editedMiniApp)
        ])
        this.miniApp = editedMiniApp
      } catch (e) {
        // TODO: 顯示錯誤 message
      }
    },
    async updateWarningModuleSetting (warningModule) {
      const editedMiniApp = JSON.parse(JSON.stringify(this.miniApp))
      editedMiniApp.settings.editModeData.warningModule = warningModule

      try {
        await this.updateAppSetting(editedMiniApp)

        const oldConditionActivate = this.miniApp.settings.editModeData.warningModule.activate
        const newConditionActivate = editedMiniApp.settings.editModeData.warningModule.activate
        if (oldConditionActivate !== newConditionActivate) {
          this.appEventLog(
            newConditionActivate ? 'activeAllConditions' : 'deactivateAllConditions',
            { appId: editedMiniApp.id }
          )
        }

        this.miniApp = editedMiniApp
        globalEmitter.emit('fetchConditions')

        Message({
          message: this.$t('message.saveSuccess'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      }
    },
    // ----- sideNav -----
    handleSideNavPinnerHover (isEnter) {
      if (isEnter) {
        clearTimeout(this.sideNavPinerLeaveTimer)
        this.sideNavPinerLeaveTimer = null
        this.isMouseEnterSideNavPinner = true
        this.isSideNavShow = true
      } else {
        this.sideNavPinerLeaveTimer = setTimeout(() => {
          this.isMouseEnterSideNavPinner = false
          this.isSideNavShow = false
        }, 200)
      }
    },
    // ----- commons -----
    doOtherFeature (action) {
      if (action === 'shareUrl') return this.showShareDialog()
      if (action === 'deleteMiniApp') return this.showDeleteDialog()
      if (action === 'goToLivePage') return this.directToCertainModeMiniApp('view')
    },
    formatTimeStamp (timestampe) {
      return moment(timestampe).format('YYYY/M/D')
    },
    showShareDialog () {
      this.isShowShare = true
      this.shareLink = `${window.location.origin}${this.$route.path}?mode=view`
    },
    closeShareDialog () {
      this.isShowShare = false
    },
    copyLink () {
      let input = this.$refs.shareInput
      input.select()
      /* For mobile devices */
      input.setSelectionRange(0, 99999)
      document.execCommand('copy')

      Message({
        message: this.$t('message.copiedToBoard'),
        type: 'success',
        duration: 3 * 1000,
        showClose: true
      })
      this.isShowShare = false
    },
    directToCertainModeMiniApp (mode) {
      const { name, params } = this.$route
      const routeData = this.$router.resolve({
        name,
        params,
        query: { mode: mode }
      })
      window.open(routeData.href, '_blank')
    },
    async handleSideNavPinClick (status) {
      this.isSideNavPin = status
      if (this.isEditMode) {
        // update API
        const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
        updatedMiniAppData.settings.editModeData.config.isSideNavPin = status
        await this.updateAppSetting(updatedMiniAppData)
        this.miniApp = updatedMiniAppData
      }
    },
    // ----- publish -----
    async publishMiniApp (type) {
      this.isProcessing = true
      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
      // 更新發佈狀態
      updatedMiniAppData.settings.isPublished = true
      // 更新發佈區資料
      updatedMiniAppData.settings.viewModeData = {
        ...this.miniApp.settings.editModeData,
        updateDate: new Date()
      }

      try {
        await this.updateAppSetting(updatedMiniAppData)
        this.appEventLog('publishApp', { appId: this.miniApp.id })
        this.miniApp = updatedMiniAppData
        Message({
          message: type === 'publish' ? this.$t('miniApp.appSuccessfullyPublished') : this.$t('miniApp.appSuccessfullyUpdated'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        setTimeout(() => {
          this.isProcessing = false
        }, 1000)
      }
    },
    async unpublishMiniApp () {
      this.isProcessing = true
      const updatedMiniAppData = JSON.parse(JSON.stringify(this.miniApp))
      // 更新發佈狀態
      updatedMiniAppData.settings.isPublished = false
      // 更新發佈區資料
      updatedMiniAppData.settings.viewModeData = null

      try {
        await this.updateAppSetting(updatedMiniAppData)
        this.appEventLog('unpublishApp', { appId: this.miniApp.id })
        this.miniApp = updatedMiniAppData
        Message({
          message: this.$t('miniApp.appSuccessfullyUnpublished'),
          type: 'success',
          duration: 3 * 1000,
          showClose: true
        })
      } catch (e) {
        // TODO: 顯示錯誤 message
      } finally {
        setTimeout(() => {
          this.isProcessing = false
        }, 1000)
      }
    },
    // NOTICE: 暫存
    componentTemplateFactory (type = 'chart') {
      // TODO: 抽出 schema
      const commonConfig = {
        uuid: uuidv4(),
        type: type,
        diagram: null,
        updateTime: new Date().getTime(),
        source: {
          dataSourceId: null,
          dataFrameId: null
        },
        displayConfig: {
          fontSize: 'middle',
          title: {
            isSyncQuestion: false,
            name: null
          },
          position: {
            x: 0,
            y: 0,
            row: 6,
            column: 6
          },
          unit: null,
          dateTimeColumn: {
            columnId: null,
            primaryAlias: null
          },
          customMarkLine: [],
          isStack: false,
          magicType: null,
          binSize: null,
          showLabelData: false
        },
        settingConfig: {
          related: {
            dashboard: {
              active: false,
              dashboardId: null,
              name: null
            }
          }
        }
      }
      const formulaConfig = {
        questionConfig: {
          keyResultId: null
        },
        formulaSetting: {
          formulaId: null,
          inputList: [],
          displayedFormula: null
        }
      }
      const moduleConfig = {
        modelSetting: {
          modelId: null,
          type: null,
          inputList: []
        }
      }
      const questionConfig = {
        questionConfig: {
          parserLanguage: 'ZH_TW',
          question: null,
          questionId: null,
          segmentation: null,
          dataColumns: [],
          resultId: null,
          keyResultId: null,
          algoConfig: null,
          advanced: null
        },
        flags: {
          isTextTypeAvailable: false,
          isIndexTypeAvailable: false
        }
      }

      let returnObj = { ...commonConfig }
      returnObj.type = type

      if (type === 'formula') {
        returnObj = {
          ...returnObj,
          ...formulaConfig
        }
        returnObj.settingConfig.refresh = {
          isAuto: false,
          refreshFrequency: null
        }
        returnObj.displayConfig.title.name = this.$t('miniApp.specialIndexTypeComponent')
      }

      if (['unhandled-abnormal-statistics', 'handled-abnormal-statistics'].includes(type)) {
        if (type === 'unhandled-abnormal-statistics') {
          returnObj.displayConfig.title.name = this.$t('miniApp.unhandledAbnormalStatisticsComponent')
        }

        if (type === 'handled-abnormal-statistics') {
          returnObj.displayConfig.title.name = this.$t('miniApp.handledAbnormalStatisticsComponent')
        }
        returnObj.diagram = returnObj.type
        returnObj.type = 'abnormal-statistics'
      }

      if (type === 'monitor-warning-list') {
        returnObj.displayConfig.title.name = this.$t('alert.realTimeMonitorAlert')
      }

      if (['simulator', 'parameters-optimized-simulator'].includes(type)) {
        returnObj = {
          ...returnObj,
          ...moduleConfig
        }
        returnObj.displayConfig.title.name = type === 'simulator' ? this.$t('miniApp.simulator') : this.$t('miniApp.parametersOptimizedSimulator')
        returnObj.displayConfig.position.row = 12
        returnObj.displayConfig.position.column = 12
      }

      if (['chart', 'text', 'index'].includes(type)) {
        returnObj = {
          ...returnObj,
          ...questionConfig
        }

        returnObj.displayConfig.anomalySettings = []
        returnObj.settingConfig.refresh = {
          isAuto: false,
          refreshFrequency: null
        }
        returnObj.settingConfig.related.table = {
          active: false,
          triggerTarget: 'column',
          dashboardId: null,
          column: {
            columnId: null,
            columnAlias: null
          }
        }
      }

      return returnObj
    },
    cancelRequest () {
      if (!this.askCancelTokenList.length) return
      this.cancelAllToken()
      this.resetCancelToken()
    },
    downloadExportedApp
    // TODO: 待移除
    // formatRestraint (filterInfo) {
    //   const columnStatsType = filterInfo.statsType
    //   let filter = {
    //     dataSourceName: filterInfo.dataSourceName,
    //     dataSourceId: filterInfo.dataSourceId,
    //     dataFrameName: filterInfo.dataFrameName,
    //     dataFrameId: filterInfo.dataFrameId,
    //     columnId: filterInfo.columnId,
    //     dataType: filterInfo.dataType,
    //     statsType: filterInfo.statsType,
    //     columnName: filterInfo.columnName,
    //     ...(filterInfo.isSelected && { isSelected: filterInfo.isSelected })
    //   }

    //   switch (columnStatsType) {
    //     case 'BOOLEAN':
    //     case 'CATEGORY':
    //       filter = {
    //         ...filter,
    //         dataValues: filterInfo.dataValues || [],
    //         dataValueOptionList: filterInfo.dataValueOptionList || []
    //       }
    //       break
    //     case 'DATETIME':
    //       filter = {
    //         ...filter,
    //         dataMax: filterInfo.dataMax || null,
    //         dataMin: filterInfo.dataMin || null,
    //         start: filterInfo.start || null,
    //         end: filterInfo.end || null
    //       }
    //       break
    //     case 'NUMERIC':
    //       filter = {
    //         ...filter,
    //         dataMax: filterInfo.dataMax || null,
    //         dataMin: filterInfo.dataMin || null,
    //         start: filterInfo.start || null,
    //         end: filterInfo.end || null
    //       }
    //       break
    //     case 'RELATIVEDATETIME':
    //       filter = {
    //         ...filter,
    //         dataValues: filterInfo.dataValues || [],
    //         dataValueOptionList: filterInfo.dataValueOptionList || [],
    //         start: filterInfo.start || null,
    //         end: filterInfo.end || null
    //       }
    //       break
    //   }
    //   return filter
    // },
    // logDraggingMovement (e) {
    //   const { index, futureIndex } = e.draggedContext
    //   this.draggedContext = { index, futureIndex }
    // },
  }
})
</script>

<style lang="scss" scoped>
@mixin dropdown-select-controller {
  &:hover {
    .dropdown-select { visibility: visible; }
  }
}

@mixin dropdown-select-position ($top: 0, $right: 0, $left: 0, $before: 0) {
  box-shadow: 0 2px 5px rgba(34, 117, 125, 0.5);
  left: unset;
  right: -3px;
  top: calc(50% + 17px);
  &::before { right: 7px; }

  .dropdown-flex {
    min-width: unset;
  }
}

.mini-app {
  .spinner-block {
    margin-top: 30vh;
  }

  &__page {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    ::v-deep .new-name-input {
      margin-right: 16px;

      .input-verify-text {
        height: 30px;
        margin-bottom: 0;
      }

      .input-error {
        bottom: -16px;
      }
    }
  }

  &__nav {
    align-items: center;
    background: rgba(0, 0, 0, 0.55);
    border-bottom: 1px solid #232c2e;
    display: flex;
    flex: 0 0 56px;
    justify-content: space-between;
    padding: 0 20px 0 24px;
    position: relative;
    z-index: 5;

    .side-nav--pin {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      cursor: pointer;
      margin-right: 18px;
      padding-right: 18px;
      position: relative;

      .svg-icon {
        display: block;
        fill: #4de2f0;
        height: 20px;
        width: 20px;
      }
    }

    .nav--left {
      align-items: center;
      display: flex;

      .app-logo {
        padding-right: 24px;

        &__img {
          max-height: 30px;
        }
      }

      .app-name {
        align-self: center;
        display: flex;
        font-size: 20px;

        &.is-live {
          border-left: 1px solid #404949;
          font-weight: 600;
          letter-spacing: 4px;
          line-height: 30px;
          padding-left: 24px;
        }

        &:hover {
          .icon-edit {
            visibility: visible;
          }
        }
      }

      .icon-arrow {
        color: $theme-color-primary;
        cursor: pointer;
        margin-right: 20px;
      }

      .icon-edit {
        color: $theme-color-primary;
        cursor: pointer;
        font-size: 16px;
        margin-left: 12px;
        visibility: hidden;
      }

      .cancel-btn {
        margin-left: 6px;
      }
    }

    .nav--right {
      color: $theme-color-primary;
      cursor: pointer;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    height: 0;

    .empty-block {
      align-items: center;
      color: #ddd;
      display: flex;
      flex-direction: column;
      font-size: 18px;
      margin-top: 30vh;
      width: 100%;

      .create-btn {
        color: #004046;
        margin-top: 20px;
        position: relative;

        .svg-icon {
          margin-right: 6px;
        }
      }
    }
  }

  &__side-nav {
    $side-nav-width: 240px;

    flex: 0 0 0;
    position: relative;
    transition: flex-basis 0.3s ease-out;

    .mini-app-side-nav {
      height: calc(100vh - 56px);
      left: 0;
      position: fixed;
      top: 56px;
      transform: translateX(-100%);
      transition: transform 0.3s ease-out;
      width: $side-nav-width;
      z-index: 99;
    }

    &--pinned {
      flex: 0 0 $side-nav-width;
    }

    &--show,
    &--pinned {
      .mini-app-side-nav {
        transform: none;
      }
    }

    &-toggle {
      align-items: center;
      background-color: #232c2e;
      border-radius: 0 50px 50px 0;
      cursor: pointer;
      display: flex;
      height: 30px;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 9px;
      transform: translateX(100%);
      width: 24px;
      z-index: 0;

      .svg-icon {
        display: block;
        fill: #2ad2e2;
        transform: rotateY(180deg) translateX(1px);
      }
    }
  }

  &__main {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    min-width: 0;
    overflow: auto;
    overflow: overlay;
    padding: 20px 0 0 20px;
    transition: flex-basis 0.3s ease-out;

    &-header {
      align-items: center;
      display: flex;
      flex: 0 0 30px;
      justify-content: space-between;
      margin-bottom: 20px;
      padding-right: 20px;
      position: relative;
      z-index: 4;
    }
    &.warning {}

    &.dashboard {
      .dashboard__header {
        align-items: center;
        display: flex;
        flex: 0 0 30px;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-right: 20px;
        position: relative;
        z-index: 4;

        .header-left {
          align-items: center;
          display: flex;

          .name {
            font-size: 20px;
            line-height: 28px;
          }

          &:hover {
            .icon-edit {
              visibility: visible;
            }
          }

          .icon-edit {
            color: $theme-color-primary;
            cursor: pointer;
            margin-left: 12px;
            visibility: hidden;

            &:hover {
              visibility: visible;
            }
          }
        }

        .header-right {
          align-items: center;
          display: flex;
          justify-content: flex-end;

          .dashboard-setting-box {
            @include dropdown-select-controller;

            align-items: center;
            border: 1px solid #fff;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            flex: 0 0 30px;
            height: 30px;
            justify-content: center;
            margin-left: 6px;
            position: relative;

            .dropdown-select {
              z-index: 2;

              ::v-deep .dropdown-select-box {
                box-shadow: 0 2px 5px rgba(34, 117, 125, 0.5);
                left: unset;
                right: -3px;
                top: calc(50% + 17px);
                z-index: 1;
                &::before { right: 7px; }

                .svg-icon {
                  color: $theme-color-primary;
                }

                .dropdown-flex {
                  min-width: unset;
                }
              }
            }
          }

          .icon-triangle {
            font-size: 8px;
            transform: rotate(180deg);
          }

          .button-label {
            margin: 0 6px;

            &:last-child {
              margin-right: 16px;
            }
          }

          .svg-icon {
            flex: 0 0 14px;
          }
        }

        .create-component-btn {
          .beginner-guide {
            left: 2px;
          }
        }

        .create-filter-btn {
          margin-left: 8px;

          .beginner-guide {
            left: 10px;
          }
        }

        .cancel-btn {
          margin-left: 6px;
        }

        ::v-deep .dropdown {
          &__list-container {
            left: 0;
            text-align: left;
            top: calc(100% + 10px);
            width: auto;
            z-index: 1;

            &::before {
              background-color: transparent;
              bottom: 100%;
              content: '';
              height: 12px;
              left: 0;
              position: absolute;
              width: 100%;
            }

            &::after {
              border-bottom: 8px solid #2b3839;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              bottom: 100%;
              content: '';
              left: 50%;
              position: absolute;
              transform: translateX(-50%);
            }
          }

          &__link {
            font-size: 14px;
            font-weight: 600;
            line-height: 39px;
          }
        }

        .component-type-dropdown >>> .dropdown__list-container {
          width: 160px;
        }
      }
    }
  }

  &__dashboard {
    &-components {
      flex: 1;
      height: 100%;
      overflow: auto;
      overflow: overlay; // 讓scrollbar不佔位。for有支援此屬性的瀏覽器
      &::after {
        clear: both;
        content: '';
        display: block;
      }
    }
  }

  &__dialog-input {
    margin: 24px 0;
    padding-bottom: 8px;
  }

  &__dialog-select-wrapper {
    margin: 24px 0;
    width: 100%;
  }

  &__dialog-select {
    border-bottom: 1px solid #fff;
    width: 100%;

    ::v-deep .el-input__inner {
      padding-left: 0;
    }

    ::v-deep .error-text {
      text-align: left;
    }
  }

  .button-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    position: relative;

    &__button {
      line-height: 20px;
      min-width: unset;
      padding: 5px 10px;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &.btn-default {
        color: #000;
      }
    }

    &__time {
      color: #ddd;
      font-size: 12px;
    }

    &__status {
      background: #333;
      border-radius: 24px;
      color: #fff;
      display: inline-block;
      font-size: 12px;
      padding: 4px 8px;

      &::before {
        background: transparent;
        border: 1px solid #999;
        border-radius: 50%;
        content: '';
        display: inline-block;
        height: 8px;
        margin: auto 0;
        width: 8px;
      }

      &--active {
        &::before {
          background: #2fecb3;
          border: 0;
        }
      }
    }

    &__description {
      font-size: 14px;
      line-height: 32px;

      [lang='en'] & {
        line-height: 24px;
        text-align: right;
      }

      .question-lamp {
        color: $theme-color-warning;
      }
    }

    ::v-deep .dropdown {
      margin-left: 8px;

      &__list-container {
        left: -109px;
        text-align: left;
        top: calc(100% + 10px);
        width: 160px;
        z-index: 1;

        ::before {
          background-color: transparent;
          bottom: 100%;
          content: '';
          height: 12px;
          left: 0;
          position: absolute;
          width: 100%;
        }

        ::after {
          border-bottom: 12px solid #2b3839;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          bottom: 100%;
          content: '';
          left: 72%;
          position: absolute;
        }
      }

      &__link {
        font-size: 14px;
        line-height: 40px;

        &::before {
          display: none;
        }
      }
    }
  }

  &__dashboard-control {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-wrap: wrap;
    margin-right: 16px;
    position: relative;
    z-index: 1;

    &--top {
      margin-bottom: 12px;
      z-index: 2;
    }

    &--bottom {
      margin-bottom: 20px;
      z-index: 1;
    }

    &.editing {
      background: #1c292b;
      padding: 16px 19px 0;
    }
  }

  &__dashboard-filter {
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }

  &__dashboard-components {
    margin-right: 4px;
    position: relative;
    z-index: 0;

    .warning-icon {
      color: #ff5c46;
    }
  }

  ::v-deep .vue-grid-item.vue-grid-placeholder {
    background: #4de2f0;
  }
}

.dropdown-select {
  visibility: hidden;
}

.dragging-ghost {
  background: #192323;
  opacity: 0.5;
}

::v-deep .vue-resizable-handle {
  background: none;

  &::before {
    border-color: #2ad2e2;
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: '';
    display: block;
    height: 5px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
  }
}
</style>
