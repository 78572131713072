<template>
  <el-tooltip
    slot="label"
    :disabled="!isFailed"
    :enterable="false"
    class="tooltip"
  >
    <div slot="content">
      <div class="tooltip__title">
        {{ $t('miniApp.pleaseCheckFollowingSituations') + '：' }}
      </div>
      <p class="tooltip__item">
        {{ '1.' + $t('miniApp.dataframeExistenceAndRebuild') }}
      </p>
      <p class="tooltip__item">
        {{ '2.' + $t('miniApp.connectionStatus') }}
      </p>
      <p class="tooltip__item">
        {{ '3.' + $t('miniApp.otherReasons') }}
      </p>
    </div>
    <div
      ref="container"
      :class="{
        'grey-bg': isSingleChoiceFilter,
        'blue-bg': !isEditMode && isShowFilterPanel,
        'hoverable': !isEditMode
      }"
      class="filter"
      @click="toggleFilterPanel"
      @mouseenter="setIsHover(true)"
      @mouseleave="setIsHover(false)"
    >
      <spinner
        v-if="isProcessing"
        size="10"
      />
      <template v-else>
        <div
          :class="{'filter__title--error': isFailed}"
          class="filter__title"
        >
          {{ displayName }}
        </div>
        <div
          class="filter__clear"
          v-if="isClearAvailable"
          @click.stop="handleClear"
        >
          {{ $t('common.clear') }}
        </div>
        <div
          v-if="isEditMode"
          class="filter__delete-icon-box"
          @click.stop="removeFilter"
        >
          <svg-icon
            icon-class="close"
            class="filter__delete-icon"
          />
        </div>
        <svg-icon
          v-else
          icon-class="triangle"
          class="filter__dropdown-icon"
        />
        <template v-if="!isFailed">
          <!--Range-->
          <div
            v-if="isShowFilterPanel && filterType === 'NUMERIC'"
            class="filter__input-panel input-panel"
            @click.stop
          >
            <spinner
              v-if="isLoading"
              class="filter-spinner"
            />
            <template v-else>
              <div class="input-panel__input-group">
                <label
                  class="input-panel__label"
                  for="max"
                >{{ `${$t('miniApp.upperBound')}(max: ${valueMax})` }}</label>
                <input
                  v-validate="upperBoundRules"
                  id="max"
                  ref="upperBound"
                  v-model="tempFilter.valueRange.end"
                  :placeholder="$t('miniApp.pleaseEnterNumber')"
                  name="upperBound"
                  class="input-panel__input input"
                  type="text"
                >
                <div
                  v-show="errors.has('upperBound')"
                  class="error-text"
                >
                  {{ errors.first('upperBound') }}
                </div>
              </div>
              <div class="input-panel__input-group">
                <label
                  class="input-panel__label"
                  for="min"
                >{{ `${$t('miniApp.lowerBound')}(min: ${valueMin})` }}</label>
                <input
                  v-validate="lowerBoundRules"
                  id="min"
                  ref="lowerBound"
                  v-model="tempFilter.valueRange.start"
                  :placeholder="$t('miniApp.pleaseEnterNumber')"
                  name="lowerBound"
                  class="input-panel__input input"
                  type="text"
                >
                <div
                  v-show="errors.has('lowerBound')"
                  class="error-text"
                >
                  {{ errors.first('lowerBound') }}
                </div>
              </div>
              <div class="button__block">
                <button
                  class="btn btn-outline"
                  @click.stop="toggleFilterPanel"
                >
                  {{ $t('button.cancel') }}
                </button>
                <button
                  class="btn btn-default"
                  @click.stop="updateRangeFilteredColumnValue"
                >
                  {{ $t('button.save') }}
                </button>
              </div>
            </template>
          </div>
          <!--Datetime-->
          <div
            v-else-if="filterType === 'DATETIME'"
            :class="{ 'hidden': isShowFilterPanel }"
            class="filter__datetime-picker-panel"
          >
            <el-date-picker
              ref="datepicker"
              :value="dateTimeRange"
              :format="'yyyy-MM-dd HH:mm'"
              :picker-options="pickerOptions"
              :editable="false"
              :clearable="false"
              :default-value="valueMin"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm"
              @input="updateDateTimeFilteredColumnValue"
            />
          </div>
          <!--Enum-->
          <div
            v-else-if="isShowFilterPanel && (filterType === 'CATEGORY' || filterType === 'BOOLEAN')"
            class="filter__selector-panel selector"
            @click.stop
          >
            <input
              v-model.trim="searchInput"
              :placeholder="$t('dataFrameAdvanceSetting.searchColumn')"
              class="selector__input-block"
              type="text"
            >
            <spinner
              v-if="isLoading"
              class="filter-spinner"
              size="20"
            />
            <template v-else>
              <div
                v-if="dataValueOptionList.length === 0"
                class="empty-message"
              >
                {{ $t('message.emptyResult') }}
              </div>
              <div
                v-else
                class="selector__action-block"
              >
                <a
                  href="javascript:void(0);"
                  class="link selector__action-block-link"
                  @click="toggleAllOptions(true)"
                >{{ $t('dataFrameAdvanceSetting.selectAll') }}</a>
                <a
                  href="javascript:void(0);"
                  class="link selector__action-block-link"
                  @click="toggleAllOptions(false)"
                >{{ $t('dataFrameAdvanceSetting.cancelSelect') }}</a>
              </div>
              <div class="selector__list-block">
                <template v-for="(value, index) in dataValueOptionList">
                  <!--Control panel filter-->
                  <label
                    v-if="isSingleChoiceFilter"
                    :key="index"
                    name="control"
                    class="radio"
                  >
                    <input
                      :checked="checkValueIsChecked(value.name)"
                      class="radio__input"
                      type="radio"
                      @input="updateSingleEnumFilteredColumnValue($event, value.name)"
                    >
                    <span class="radio__name">{{ value.name }}</span>
                  </label>
                  <!--: Multiple choice Filter-->
                  <label
                    v-else
                    :key="index"
                    class="checkbox"
                  >
                    <div class="checkbox-label">
                      <input
                        :checked="checkValueIsChecked(value.name)"
                        type="checkbox"
                        @input="updateMultipleEnumFilteredColumnValue($event, value.name)"
                      >
                      <div class="checkbox-square" />
                    </div>
                    <span class="radio__name">{{ value.name }}</span>
                  </label>
                </template>
              </div>
            </template>
          </div>
          <!--Relative Datetime-->
          <div
            v-else-if="isShowFilterPanel && filterType === 'RELATIVEDATETIME'"
            class="filter__selector-panel selector"
            @click.stop
          >
            <div
              v-if="dataValueOptionList.length === 0"
              class="empty-message"
            >
              {{ $t('message.emptyResult') }}
            </div>
            <div class="selector__list-block">
              <template v-for="(option, index) in dataValueOptionList">
                <label
                  :key="index"
                  name="control"
                  class="radio"
                >
                  <input
                    :checked="checkValueIsChecked(option.value)"
                    class="radio__input"
                    type="radio"
                    @input="updateSingleEnumFilteredColumnValue($event, option.value)"
                  >
                  <span class="radio__name">{{ $t('miniApp.' + option.name) }}</span>
                </label>
              </template>
            </div>
          </div>
          <!--Custom Datetime-->
          <div
            v-else-if="isShowFilterPanel && filterType === 'CUSTOMDATETIME'"
            class="filter__selector-panel customTimeInterval"
            @click.stop
          >
            <div class="customTimeInterval__body">
              <div>
                <label
                  for="numeric"
                  class="customTimeInterval__title"
                >
                  {{ $t('dataType.numeric') }}
                </label>
                <input
                  v-model="timeValue"
                  id="numeric"
                  class="input customTimeInterval__input"
                  name="numeric"
                  type="number"
                >
              </div>
              <div>
                <label class="customTimeInterval__title">
                  {{ $t('algorithm.timeUnit') }}
                </label>
                <radio-group
                  :options="customDateUtilOptions"
                  v-model="timeUnit"
                />
              </div>
            </div>
            <div class="customTimeInterval__footer">
              <button
                :disabled="isProcessing"
                type="button"
                class="btn btn-outline"
                @click.stop="isShowFilterPanel = false"
              >
                {{ $t('button.cancel') }}
              </button>
              <button
                :disabled="isProcessing"
                type="button"
                class="btn btn-primary customTimeInterval__confirm-btn"
                @click.stop="updateCustomDateTimeFilter"
              >
                {{ $t('button.confirm') }}
              </button>
            </div>
          </div>
        </template>
      </template>
    </div>
  </el-tooltip>
</template>

<script>
import momentTZ from 'moment-timezone'
import { getDataColumnValue, dataValueSearch } from '@/API/DataSource'
import countStringBytes from '@/utils/countStringBytes'
import { debounce } from 'lodash'
import axios from 'axios'
import RadioGroup from './../components/RadioGroup'

export default {
  name: 'SingleFilterBadge',
  inject: ['$validator'],
  components: { RadioGroup },
  props: {
    initialFilter: {
      type: Object,
      required: true
    },
    isEditMode: {
      type: Boolean,
      required: true
    },
    isSingleChoiceFilter: {
      type: Boolean,
      default: false
    },
    filterSet: {
      type: Array,
      default: () => []
    },
    isNeedUpdate: {
      type: Boolean,
      default: false
    },
    isProcessing: {
      type: Boolean,
      default: false
    },
    isFilterListNeedUpdate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filter: {},
      isLoading: false,
      searchInput: '',
      isShowFilterPanel: false,
      tempFilter: {},
      relativeDatetimeOptions: ['unset', 'today', '6hour', '3hour', '1hour', 'week', 'month', 'quarter', 'year'],
      customDatetimeOptions: ['minute', 'month', 'hour', 'quarter', 'day', 'year', 'week'],
      isFailed: false,
      isFirstInit: true,
      dataValueOptionList: [],
      valueMax: 0,
      valueMin: 0,
      isHover: false,
      cancelToken: null,
      // initialFilter 改動會初始化
      timeUnit: '',
      timeValue: 0
    }
  },
  computed: {
    upperBoundRules () {
      let minLabel = this.tempFilter.valueRange.start !== 0 && (!this.tempFilter.valueRange.start || isNaN(this.tempFilter.valueRange.start))
        ? this.$t('message.lowerBound')
        : this.tempFilter.valueRange.start
      return `required_if:tempFilter.valueRange.end|decimal|min_value:${minLabel}`
    },
    lowerBoundRules () {
      let maxLabel = this.tempFilter.valueRange.end !== 0 && (!this.tempFilter.valueRange.end || isNaN(this.tempFilter.valueRange.end))
        ? this.$t('message.upperBound')
        : this.tempFilter.valueRange.end
      return `required_if:tempFilter.valueRange.start|decimal|max_value:${maxLabel}`
    },
    displayName () {
      if (this.isFailed && this.filter?.column) return this.filter.column.name + ': ' + this.$t('miniApp.fetchDataFailed')
      if (this.filter?.column) {
        if (this.filterType === 'CATEGORY' || this.filterType === 'BOOLEAN') {
          const selectedAmount = this.filter.optionValues.length
          if (selectedAmount === 0) {
            return this.filter.column.name
          }

          if (selectedAmount === 1) {
            const displayStr = this.filter.optionValues[0].split('').reduce((acc, cur) => {
              if ((countStringBytes(acc) + countStringBytes(cur)) <= 10) {
                return `${acc}${cur}`
              }
              return /\.\.\.$/.test(acc) ? acc : `${acc}...`
            }, '')

            return `${this.filter.column.name}: ${displayStr}`
          }
          return `${this.filter.column.name} (${this.filter.optionValues.length})`
        } else if (this.filterType === 'RELATIVEDATETIME') {
          return (this.filter.optionValues.length === 0)
            ? this.filter.column.name
            : `${this.filter.column.name}: ${this.$t('miniApp.' + this.filter.optionValues[0])}`
        } else if (this.filterType === 'CUSTOMDATETIME') {
          return (this.filter.optionValues.length === 0)
            ? this.filter.column.name
            : `${this.filter.column.name}: ${this.$t('miniApp.timeUnit.last', { time: `${this.filter.optionValues[0].timeValue} ${this.$t(`miniApp.timeUnit.${this.filter.optionValues[0].timeUnit}`)}` })}`
        } else if (this.filterType === 'NUMERIC') {
          return (this.filter.valueRange.start === null || this.filter.valueRange.start === '')
            ? this.filter.column.name
            : `${this.filter.column.name} (${this.filter.valueRange.start} - ${this.filter.valueRange.end})`
        } else if (this.filterType === 'DATETIME') {
          return (this.filter.valueRange.start === null || this.filter.valueRange.start === '')
            ? this.filter.column.name
            : `${this.filter.column.name}: ${this.filter.valueRange.start} - ${this.filter.valueRange.end}`
        }
      }
    },
    dateTimeRange () {
      if (!this.filter.valueRange.start && !this.filter.valueRange.end) return []
      return [this.filter.valueRange.start, this.filter.valueRange.end]
    },
    pickerOptions () {
      const vm = this
      return {
        disabledDate (time) {
          return time.getTime() < new Date(vm.valueMin).getTime() || time.getTime() > new Date(vm.valueMax).getTime()
        }
      }
    },
    filterType () {
      return this.filter?.column?.type ?? null
    },
    isClearAvailable () {
      const isValueSet = (this.filter?.valueRange?.start || this.filter?.valueRange?.end) || (this.filter?.optionValues?.length > 0)
      return this.isHover && isValueSet
    },
    customDateUtilOptions () {
      return this.customDatetimeOptions.map(item => ({
        name: this.$t(`miniApp.timeUnit.${item}`),
        value: item
      }))
    }
  },
  watch: {
    initialFilter: {
      handler (val) {
        this.filter = { ...this.initialFilter }
        // 初始化 filterType=CUSTOMDATETIME timeUnit timeValue
        if (this.filterType === 'CUSTOMDATETIME' && this.filter.optionValues.length) {
          const { timeUnit, timeValue } = this.filter.optionValues[0]
          this.timeUnit = timeUnit
          this.timeValue = timeValue
        }
      },
      deep: true
    },
    searchInput: debounce(async function (value) {
      this.cancelToken?.cancel('cancel')
      this.isLoading = true
      try {
        const response = await this.searchValue(value, true)
        this.dataValueOptionList = response.fuzzySearchResult.map(value => ({
          value: value,
          name: value,
          isSelected: this.filter.optionValues.includes(value)
        }))
        this.cancelToken = null
        this.isLoading = false
      } catch (e) {
        console.log(e)
      }
    }, 500),
    async isNeedUpdate (val) {
      if (!val) return
      if (this.isShowFilterPanel) this.toggleFilterPanel()
      // 第一次 init 後，如果父層 filter 改變時，需要先清空之前選擇的選項
      if (!this.isFirstInit) {
        this.filter.optionValues = await this.searchValueExist(this.filter.optionValues)
      }
      this.fetchData()
    },
    isShowFilterPanel (isShow) {
      if (!isShow) return
      const newList = []
      this.dataValueOptionList
        .filter((item) => this.filter.optionValues.includes(item.value))
        .forEach((item) => { newList.push(item) })
      this.dataValueOptionList
        .filter((item) => !this.filter.optionValues.includes(item.value))
        .forEach((item) => { newList.push(item) })

      this.dataValueOptionList = newList
    }
  },
  mounted () {
    this.filter = JSON.parse(JSON.stringify(this.initialFilter))
    this.fetchData()
    document.addEventListener('click', this.autoHide, false)
  },
  destroyed () {
    document.removeEventListener('click', this.autoHide, false)
  },
  methods: {
    autoHide (evt) {
      if (!this.isShowFilterPanel) return false
      if (!this.$el.contains(evt.target)) this.toggleFilterPanel()
    },
    async fetchData () {
      this.isLoading = true
      this.$emit('update:isProcessing', true)
      if (this.isFirstInit) this.isFirstInit = false
      try {
        if (this.filterType === 'RELATIVEDATETIME') return this.getRelativeDatetimeOption()
        if (this.filterType === 'CUSTOMDATETIME') return this.getCustomDatetimeOption()
        if (this.filterType === 'NUMERIC' || this.filterType === 'DATETIME') return await this.getDataColumnValue()

        const { fuzzySearchResult: tempOptionList } = await this.searchValue()

        // 如果當前 filter 有已選定的值，先確認是否有在 option 清單中
        if (this.filter.optionValues.length > 0) {
          const checkedOptionsValues = await this.searchValueExist(this.filter.optionValues)

          // 如果沒有結果，則把之前選定的值清空
          if (checkedOptionsValues.length === 0) {
            this.filter.optionValues = []
          } else {
            checkedOptionsValues.forEach((val) => {
              // 確認有沒有在無搜尋字串時，至多 200 個選項當中
              const isInTempOptionList = tempOptionList.includes(val)

              // 如果沒有，代表選定值在 200 個選項之外，因此把它加到第 201 個選項
              !isInTempOptionList && tempOptionList.push(val)
            })
          }
        }

        // 將值轉換成下拉選項
        this.dataValueOptionList = tempOptionList.map(value => ({
          value: value,
          name: value,
          isSelected: this.filter.optionValues.includes(value)
        }))

        // 控制項須確保至少選定其中一個項目：如果當前沒有或之前選定的值在新取的選項中沒有，則預設為第一個選項
        const isNeedDefaultSelect = this.isSingleChoiceFilter &&
          (this.filter.optionValues.length === 0 || !this.dataValueOptionList.find(option => option.isSelected)) &&
          this.dataValueOptionList.length > 0

        //  將預設選項更新出去
        if (isNeedDefaultSelect) return this.updateSingleEnumFilteredColumnValue(null, this.dataValueOptionList[0].name)
        // 如果是因為階層被觸發去重新取選單資料，須把取完後的結果更新出去，並由外層委派下一個 filter 去更新
        if (this.isNeedUpdate || this.filter.optionValues.length === 0) {
          this.emitFilter(this.filter)
        } else {
          this.$emit('update:isProcessing', false)
        }
      } catch (e) {
        console.log(e)
        this.isFailed = true
        this.emitFilter(this.filter)
      } finally {
        this.$emit('update:isProcessing', false)
        this.isLoading = false
      }
    },
    getRelativeDatetimeOption () {
      this.dataValueOptionList = this.relativeDatetimeOptions.map(value => ({
        value: value,
        name: value,
        isSelected: this.filter.optionValues.includes(value)
      }))
    },
    getCustomDatetimeOption () {
      this.dataValueOptionList = this.customDatetimeOptions.map(value => ({
        value: value,
        name: value,
        isSelected: this.filter.optionValues.includes(value)
      }))
    },
    getDataColumnValue () {
      return getDataColumnValue(this.filter.column.columnId)
        .then(response => {
          const statsType = response.type
          const valueList = response[statsType.toLowerCase()]

          if (statsType === 'NUMERIC') {
            this.valueMin = valueList.min
            this.valueMax = valueList.max
            return
          }

          if (statsType === 'DATETIME') {
            // 目前後端有用到 13 種日期格式，先預設所有日期最小單位都到秒
            valueList.datePattern = 'yyyy-MM-dd HH:mm:ss'
            this.valueMin = this.customerTimeFormatter(valueList.start, 'SECOND')
            this.valueMax = this.customerTimeFormatter(valueList.end, 'SECOND')
          }
        })
    },
    searchValue (searchString = '', isInputSearch = false) {
      if (isInputSearch) {
        this.cancelToken = axios.CancelToken.source()
      }
      return dataValueSearch(
        this.filter.column.columnId,
        {
          page: 0,
          searchString,
          size: 200,
          restrictions: this.restrictions()
        },
        this.cancelToken?.token
      )
    },
    toggleFilterPanel () {
      if (this.isProcessing || this.isFailed) return
      if (!this.isShowFilterPanel) {
        // 重設自訂時間條件表單
        if (this.filterType === 'CUSTOMDATETIME') {
          if (this.initialFilter.optionValues.length) {
            const { timeUnit, timeValue } = this.initialFilter.optionValues[0]
            this.timeUnit = timeUnit
            this.timeValue = timeValue
          } else {
            this.resetCustomDateTime()
          }
        }
        this.createTempFilter()
      } else {
        if (this.filterType === 'NUMERIC') {
          this.$validator.detach('upperBound')
          this.$validator.detach('lowerBound')
          this.$nextTick(() => this.tempFilter = {})
        } else if (this.filterType === 'CATEGORY' || this.filterType === 'BOOLEAN') {
          this.searchInput = ''
        }
      }
      this.isShowFilterPanel = !this.isShowFilterPanel
    },
    updateRangeFilteredColumnValue () {
      this.$validator.validateAll().then(isValidate => {
        if (!isValidate) return
        this.$emit('update:isFilterListNeedUpdate', true)
        this.filter = { ...this.tempFilter }
        this.emitFilter(this.tempFilter)
        this.toggleFilterPanel()
      })
    },
    updateDateTimeFilteredColumnValue ([start, end]) {
      this.filter.valueRange.start = start
      this.filter.valueRange.end = end
      this.$emit('update:isFilterListNeedUpdate', true)
      this.emitFilter(this.filter)
      this.toggleFilterPanel()
    },
    createTempFilter () {
      this.tempFilter = JSON.parse(JSON.stringify(this.filter))
    },
    updateMultipleEnumFilteredColumnValue ({ target: { checked } }, columnValue) {
      const isInDataValueList = this.filter.optionValues.includes(columnValue)
      if (checked && !isInDataValueList) {
        this.filter.optionValues.push(columnValue)
      } else {
        this.filter.optionValues = this.filter.optionValues.filter(value => value !== columnValue)
      }
      this.$emit('update:isFilterListNeedUpdate', true)
      this.emitFilter(this.filter)
    },
    updateSingleEnumFilteredColumnValue (event, columnValue) {
      this.filter.optionValues = [columnValue]
      this.$emit('update:isFilterListNeedUpdate', true)
      this.emitFilter(this.filter)
    },
    emitFilter (val) {
      this.$emit('updateFilter', val)
    },
    toggleAllOptions (isSelectAll) {
      if (
        (isSelectAll && this.filter.optionValues.length === this.dataValueOptionList.length) ||
        !(isSelectAll || this.filter.optionValues.length)
      ) return
      if (isSelectAll) {
        this.filter.optionValues = [...new Set(this.dataValueOptionList.map((option) => option.value).concat(this.filter.optionValues))]
      } else {
        this.filter.optionValues = []
      }
      this.$emit('update:isFilterListNeedUpdate', true)
      this.$emit('updateFilter', this.filter)
    },
    checkValueIsChecked (value) {
      return this.filter.optionValues.includes(value)
    },
    removeFilter () {
      this.$emit('removeFilter')
    },
    restrictions () {
      const currentFilterIndex = this.filterSet.findIndex(filter => filter.uuid === this.initialFilter.uuid)
      const restrictions = this.filterSet
        .slice(0, currentFilterIndex)
        .filter(filter => {
          return this.checkShouldApplyMiniAppFilter(filter)
        })
        .map(filter => {
          const filterType = filter.column.type

          let type = ''
          let data_type = ''
          switch (filterType) {
            case ('STRING'):
            case ('BOOLEAN'):
            case ('CATEGORY'):
              data_type = 'string'
              type = 'enum'
              break
            case ('FLOAT'):
            case ('NUMERIC'):
              data_type = 'int'
              type = 'range'
              break
            case ('DATETIME'):
            case ('RELATIVEDATETIME'):
            case ('CUSTOMDATETIME'):
              data_type = 'datetime'
              type = 'range'
              break
          }

          // 相對時間 filter 需取當前元件所屬 dataframe 的預設時間欄位和當前時間來套用
          if (filterType === 'RELATIVEDATETIME') {
            return [{
              type,
              properties: {
                data_type,
                dc_id: this.componentData.settingConfig.dateTimeColumn.dataColumnId,
                display_name: this.componentData.settingConfig.dateTimeColumn.dataColumnPrimaryAlias,
                ...this.formatRelativeDatetime(filter.optionValues[0])
              }
            }]
          } else if (filterType === 'CUSTOMDATETIME') {
            return [{
              type,
              properties: {
                data_type,
                dc_id: this.componentData.settingConfig.dateTimeColumn.dataColumnId,
                display_name: this.componentData.settingConfig.dateTimeColumn.dataColumnPrimaryAlias,
                ...this.formatCustomDatetime(filter.optionValues[0])
              }
            }]
          }

          return [{
            type,
            properties: {
              data_type,
              dc_id: filter.column.columnId,
              display_name: filter.column.name,
              ...((filterType === 'STRING' || filterType === 'BOOLEAN' || filterType === 'CATEGORY') && {
                datavalues: filter.optionValues,
                display_datavalues: filter.optionValues
              }),
              ...((filterType === 'NUMERIC' || filterType === 'FLOAT' || filterType === 'DATETIME') && {
                start: filter.valueRange.start,
                end: filter.valueRange.end
              })
            }
          }]
        })
      return restrictions.length === 0 ? null : restrictions
    },

    formatCustomDatetime ({ timeUnit, timeValue }) {
      const currentTimeZone = this.timeZone ?? momentTZ.tz.guess()
      return {
        start: momentTZ().tz(currentTimeZone).subtract(timeUnit, timeValue).format('YYYY-MM-DD HH:mm'),
        end: momentTZ().tz(currentTimeZone).format('YYYY-MM-DD HH:mm')
      }
    },

    async searchValueExist (valArr = []) {
      if (!valArr || valArr.length === 0) return []

      const returnArr = []
      const promiseArr = valArr.map((val) => {
        return this.searchValue(val)
      })

      const checkRes = await Promise.all(promiseArr)
      checkRes.forEach((res) => {
        if (res.fuzzySearchResult.length > 0) {
          returnArr.push(res.fuzzySearchResult[0])
        }
      })

      return returnArr
    },
    setIsHover (status) {
      this.isHover = status
    },
    handleClear () {
      this.$emit('update:isFilterListNeedUpdate', true)
      const returnFilter = { ...this.filter }
      if ('valueRange' in returnFilter) {
        returnFilter.valueRange.start = null
        returnFilter.valueRange.end = null
      }
      if ('optionValues' in returnFilter) {
        returnFilter.optionValues = []
      }

      this.emitFilter(returnFilter)
      // 重設自訂時間
      this.resetCustomDateTime()
    },
    updateCustomDateTimeFilter () {
      this.updateSingleEnumFilteredColumnValue(null, { timeUnit: this.timeUnit, timeValue: this.timeValue })
      this.isShowFilterPanel = false
    },
    resetCustomDateTime () {
      this.timeUnit = 'minute'
      this.timeValue = 10
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  display: flex;
  padding: 6px 12px;
  position: relative;
  user-select: none;

  &__title {
    font-size: 12px;
    line-height: 17px;
    margin-right: 4px;

    &--error {
      color: #ffdf6f;
    }
  }

  &__clear {
    color: #2ad2e2;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.01em;
    line-height: 17px;
    margin-right: 4px;
    position: relative;
    z-index: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  &__delete-icon-box {
    align-items: center;
    background: #a7a7a7;
    border-radius: 50%;
    cursor: pointer;
    cursor: pointer;
    display: flex;
    height: 10px;
    justify-content: center;
    width: 10px;
    z-index: 1;
  }

  &__delete-icon {
    font-size: 4px;
  }

  &__dropdown-icon {
    font-size: 6px;
    transform: rotate(180deg);
  }

  &__input-panel,
  &__selector-panel {
    background-color: var(--color-bg-gray);
    border-radius: 5px;
    filter: drop-shadow(2px 2px 5px rgba(12, 209, 222, 0.5));
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 215px;
    z-index: 1;
  }

  /* 透過透明度隱藏預設的結果顯示，但仍保留點擊時要能被開啟的功能 */
  &__datetime-picker-panel {
    height: 100%;
    left: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    /* 大小為零時，使用找點任何一處可觸發選擇匡被關閉 */
    &.hidden {
      height: 0;
      width: 0;
    }
  }

  &.grey-bg {
    background: rgba(255, 255, 255, 0.2);
    border: none;
  }

  &.blue-bg {
    background: #2ad2e2;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  &.hoverable {
    cursor: pointer;
  }

  .input-panel {
    background: #303435;
    border-radius: 5px;
    padding: 12px;

    &__input-group {
      margin-bottom: 8px;
    }

    &__label {
      color: #ccc;
      display: block;
      font-size: 12px;
      margin-bottom: 4px;
    }

    &__input {
      background: #141c1d;
      border: none;
      border-radius: 5px;
      height: 40px;
      opacity: 0.99;
      padding: 8px;
      width: 100%;

      &::placeholder {
        color: #888;
      }
    }

    &.error-text {
      margin-top: 5px;
    }
  }

  ::v-deep .spinner-block {
    padding: 3.5px 0;
  }
}

.tooltip {
  &__title {
    font-size: 12px;
    font-weight: bold;
  }

  &__item {
    font-size: 12px;
  }
}

.selector {
  &__input-block {
    background-color: #141c1d;
    border: none;
    border-radius: 8px;
    color: #888;
    font-size: 14px;
    margin: 12px 12px 8px;
    padding: 9px 12px;
    width: calc(100% - 24px);

    &:focus {
      outline: none;
    }

    .placeholder {
      color: #888;
      color: #888;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__action-block {
    padding: 0 12px 6px;

    &-link {
      font-size: 12px;
      font-weight: 600;

      &:first-of-type {
        margin-right: 8px;
      }
    }
  }

  &__list-block {
    max-height: 220px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.7);
      border-color: rgba(0, 0, 0, 0.7);
    }

    .checkbox {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      min-height: 32px;
      padding: 8px 12px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }

      &:not(:first-child) {
        border-top: 1px solid #3f4546;
      }

      &-label {
        margin: 0 22px 0 2px;

        & input:checked ~ .checkbox-square {
          background: #777;
          border-color: #dcdfe6;
        }

        & input:disabled ~ .checkbox-square::after {
          border-color: #c0c4cc;
        }
      }

      & > span {
        color: #ccc;
        color: #ccc;
        font-size: 14px;
        line-height: 16px;
      }
    }

    .radio {
      color: #ccc;
      cursor: pointer;
      display: block;
      font-size: 14px;
      min-height: 32px;
      padding: 8px 12px;

      &__input {
        display: none;

        &:checked {
          & + .radio__name {
            color: #2ad2e2;
          }
        }
      }

      &:hover {
        color: #2ad2e2;
      }
    }
  }

  .empty-message {
    color: #ccc;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 12px;
    padding: 0 12px;
  }
}

.button {
  &__block {
    display: flex;

    .btn + .btn {
      margin-left: 4px;
    }
  }
}

.customTimeInterval {
  width: 268px;
  padding: 12px;

  &__title {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 8px;
  }

  &__input {
    padding: 9px 12px;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 12px;
    border: none;
    border-radius: 5px;
    background-color: #141c1d;
    color: #fff;
  }

  &__footer {
    padding-top: 4px;
    display: flex;
    justify-content: flex-end;
  }

  &__confirm-btn {
    font-weight: 600;
    color: #004046;
    margin-left: 12px;
  }

}

</style>
