<template>
  <div
    :class="{
      'grey-bg': isEditMode,
      'blue-bg': !isEditMode && isShowControlOptionList,
      'hoverable': !isEditMode
    }"
    class="control"
    @click="toggleOptionList"
    v-clickoutside="closeOptionList"
  >
    <div class="control__title">
      {{ displayName }}
    </div>
    <div
      v-if="isEditMode"
      class="control__delete-icon-box"
      @click="removeControl"
    >
      <svg-icon
        icon-class="close"
        class="control__delete-icon"
      />
    </div>
    <svg-icon
      v-else
      icon-class="dropdown"
      class="control__dropdown-icon"
    />
    <!--Enum-->
    <div
      v-if="isShowControlOptionList"
      class="control__selector-panel selector"
      @click.stop
    >
      <div
        v-if="initialOptionList.length === 0"
        class="empty-message"
      >
        {{ $t('message.emptyResult') }}
      </div>
      <div class="selector__list-block">
        <template v-for="(option, index) in initialOptionList">
          <!--Control panel filter-->
          <label
            :key="index"
            name="control"
            class="radio"
          >
            <input
              :checked="option.isSelected"
              class="radio__input"
              type="radio"
              @input="updateOptionList(option.columnId)"
            >
            <span class="radio__name">{{ option.columnName }}</span>
          </label>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'SingleAxisControlBadge',
  inject: ['$validator'],
  props: {
    initialOptionList: {
      type: Array,
      required: true
    },
    isEditMode: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    // 開關 list
    const isShowControlOptionList = ref(false)
    const toggleOptionList = () => {
      isShowControlOptionList.value = !isShowControlOptionList.value
    }
    const closeOptionList = () => {
      isShowControlOptionList.value = false
    }

    // emit 相關
    const updateOptionList = (columnId) => {
      emit('updateSelectedOption', columnId)
    }

    const removeControl = () => {
      emit('removeControl')
    }

    // computed
    const displayName = computed(() => {
      // 設定預設前，會沒有被選取的對象，因此需暫時給定空值
      const selectedOption = props.initialOptionList.find(option => option.isSelected)
      return `自變項目: ${selectedOption ? selectedOption.columnName : ''}`
    })

    // hooks
    onMounted(() => {
      if (props.initialOptionList && props.initialOptionList.length > 0) {
        updateOptionList(props.initialOptionList[0].columnId)
      }
    })

    return {
      displayName,
      updateOptionList,
      isShowControlOptionList,
      closeOptionList,
      toggleOptionList,
      removeControl
    }
  }
})
</script>

<style lang="scss" scoped>
.control {
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 20px;
  display: flex;
  padding: 6px 12px;
  position: relative;
  user-select: none;

  &__title {
    font-size: 12px;
    line-height: 17px;
    margin-right: 4px;
  }

  &__delete-icon-box {
    align-items: center;
    background: #a7a7a7;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 10px;
    justify-content: center;
    width: 10px;
  }

  &__delete-icon,
  &__dropdown-icon {
    font-size: 4px;
  }

  &__input-panel,
  &__selector-panel {
    background-color: var(--color-bg-gray);
    border-radius: 5px;
    filter: drop-shadow(2px 2px 5px rgba(12, 209, 222, 0.5));
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 215px;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  &.grey-bg {
    background: rgba(255, 255, 255, 0.2);
    border: none;
  }

  &.blue-bg {
    background: #2ad2e2;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  &.hoverable {
    cursor: pointer;
  }

  .input-panel {
    background: #303435;
    border-radius: 5px;
    padding: 12px;

    &__input-group {
      margin-bottom: 8px;
    }

    &__label {
      color: #ccc;
      display: block;
      font-size: 12px;
      margin-bottom: 4px;
    }

    &__input {
      background: #141c1d;
      border: none;
      border-radius: 5px;
      height: 40px;
      opacity: 0.99;
      padding: 8px;
      width: 100%;

      &::placeholder {
        color: #888;
      }
    }

    &.error-text {
      margin-top: 5px;
    }
  }
}

.selector {
  &__input-block {
    background-color: #141c1d;
    border: none;
    border-radius: 8px;
    color: #888;
    font-size: 14px;
    margin: 12px 12px 8px;
    padding: 9px 12px;
    width: calc(100% - 24px);

    &:focus {
      outline: none;
    }

    .placeholder {
      color: #888;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__list-block {
    max-height: 220px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.7);
      border: 4px solid rgba(0, 0, 0, 0.7);
    }

    .radio {
      color: #ccc;
      cursor: pointer;
      display: block;
      font-size: 14px;
      min-height: 32px;
      padding: 8px 12px;

      &__input {
        display: none;

        &:checked {
          & + .radio__name {
            color: #2ad2e2;
          }
        }
      }

      &:hover {
        color: #2ad2e2;
      }
    }
  }

  .empty-message {
    color: #ccc;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 12px;
    padding: 0 12px;
  }
}
</style>
