<template>
  <div
    ref="component"
    class="component__item"
  >
    <div class="component__item-inner-container">
      <spinner
        v-if="isInitializing"
        class="component__item-init-spinner"
      />
      <template v-else>
        <span class="component__item-header">
          <div class="header-left">
            <el-tooltip
              :content="taskOriginTitle"
              placement="bottom"
            >
              <span
                class="item-title"
                v-html="dashboardTaskTitle"
              />
            </el-tooltip>
          </div>
          <div class="header-right">
            <div class="component-property-box">
              <el-tooltip
                v-if="componentData.settingConfig.related.dashboard.active"
                :content="displayedRelatedDashboard"
              >
                <div
                  class="property__item"
                  @click="$emit('redirect', componentData.settingConfig.related.dashboard.dashboardId)"
                >
                  <svg-icon
                    icon-class="chain"
                    class="icon-chain"
                  />
                </div>
              </el-tooltip>
              <el-tooltip
                v-if="isAutoRefresh"
                :content="displayedUpdateFrequency"
              >
                <div class="property__item">
                  <svg-icon
                    icon-class="auto-refresh"
                    class="icon-refresh"
                  />
                </div>
              </el-tooltip>
            </div>
            <div
              v-if="isEditMode"
              class="component-setting-box"
            >
              <svg-icon
                icon-class="more"
                class="more-icon"
              />
              <!-- <slot name="drowdown"/> -->
              <dropdown-select
                :bar-data="componentSettingOptions"
                @switchDialogName="$emit('switchDialogName', { name: $event, componentComplementaryInfo })"
              />
            </div>
            <div v-if="!isEditMode && componentData.type === 'monitor-warning-list'">
              <svg-icon
                icon-class="warning"
                class="icon-warning"
              />
            </div>
          </div>
        </span>
        <div
          v-if="componentData.type === 'index' || componentData.type === 'formula'"
          class="component__item-content index"
        >
          <div class="index-data">
            <spinner v-if="isProcessing" />
            <template v-else>
              <task
                :class="{ 'not-empty': !isEmptyData && !isComponentFailed }"
                :custom-chart-style="indexComponentStyle"
                :key="'index-' + keyResultId"
                :component-id="keyResultId"
                :converted-type="'index_info'"
                intend="key_result"
                @isEmpty="isEmptyData = true"
                @failed="onComponentFailed"
                @finished="isIndexTypeComponentLoading = false"
                @setConfig="updateComponentConfigInfo"
              />
              <span
                v-if="!isIndexTypeComponentLoading && (!isEmptyData && !isComponentFailed)"
                :class="[componentData.displayConfig.fontSize || 'middle']"
                class="index-unit"
              >{{ componentData.displayConfig.unit }}</span>
            </template>
          </div>
        </div>
        <div
          v-else-if="componentData.type === 'text'"
          class="component__item-content text"
        >
          <spinner v-if="isProcessing" />
          <template v-else>
            <task
              :class="{ 'not-empty': !isEmptyData }"
              :custom-chart-style="textComponentStyle"
              :key="'text-' + keyResultId"
              :component-id="keyResultId"
              :converted-type="'text_info'"
              intend="key_result"
              @isEmpty="isEmptyData = true"
            />
            <span
              v-if="!isEmptyData"
              class="index-unit"
            >{{ componentData.displayConfig.unit }}</span>
          </template>
        </div>
        <monitor-warning-list
          v-else-if="componentData.type === 'monitor-warning-list'"
          :setting="warningModuleSetting"
          :is-edit-mode="isEditMode"
          :app-id="appId"
          @warningLogTriggered="$emit('warningLogTriggered', $event)"
        />
        <abnormal-statistics
          v-else-if="componentData.type === 'abnormal-statistics'"
          :type="componentData.diagram"
          :filter-time="filterTime"
          :font-size="componentData.displayConfig.fontSize"
          :warning-module-setting="warningModuleSetting"
          :is-edit-mode="isEditMode"
          :app-id="appId"
        />
        <parameters-optimized-simulator
          v-else-if="componentData.type === 'parameters-optimized-simulator'"
          :is-edit-mode="isEditMode"
          :restrictions="restrictions()"
          :source="componentData.source"
          :model-setting="componentData.modelSetting"
          :key="taskId"
        />
        <simulator
          v-else-if="componentData.type === 'simulator'"
          :is-edit-mode="isEditMode"
          :restrictions="restrictions()"
          :model-setting="componentData.modelSetting"
          :key="taskId"
        />
        <div
          v-else
          class="component__item-content chart"
        >
          <spinner v-if="isProcessing" />
          <no-result
            v-else-if="isAskQuestionFailed"
            :message="askQuestionFailedMessage || $t('miniApp.noSuitableResult')"
            class="no-result"
          />
          <task
            v-else
            :custom-chart-style="dynamicComponentStyle"
            :key="'chart' + keyResultId"
            :component-id="keyResultId"
            :is-show-description="false"
            :converted-type="convertMagicTypeDiagram(componentData.type, componentData.displayConfig.magicType)"
            :is-show-toolbox="false"
            :custom-cell-class-name="customCellClassName"
            :is-hoverable="isHoverable"
            :anomaly-setting="anomalySetting"
            :uuid="componentData.uuid"
            :custom-mark-line="customMarkLineConfig"
            :chart-toolbox-setting="chartToolboxSetting"
            :is-series-stack="isStackOption ? componentData.displayConfig.isStack : false"
            :is-show-label-data="componentData.displayConfig.showLabelData"
            intend="key_result"
            :is-miniapp-order-desc="segmentation && segmentation.denotation === 'TREND'"
            :segmentation="segmentation"
            @clickCell="columnTriggered($event)"
            @clickRow="rowTriggered($event)"
            @clickChart="chartriggered($event)"
            @setConfig="updateComponentConfigInfo"
            @failed="onComponentFailed"
          />
        </div>
      </template>
    </div>
    <decide-dialog
      v-if="isShowConfirmDelete"
      :title="$t('miniApp.confirmDeletingComponentRelation', { name: componentData.settingConfig.related.dashboard.name })"
      :type="'delete'"
      @closeDialog="isShowConfirmDelete = false"
      @confirmBtn="confirmDelete"
    />
  </div>
</template>

<script>
import momentTZ from 'moment-timezone'
import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { defineComponent } from '@vue/composition-api'
import { sizeTable } from '@/utils/general'
import { useAskingModuleContext } from '@/modules/shared/asking'
import { askFormulaResult } from '@/API/NewAsk'
import DecideDialog from '@/components/dialog/DecideDialog'
import DropdownSelect from '@/components/select/DropdownSelect'
import { formatAnomalySetting } from '@/components/display/common/addons'
import MonitorWarningList from './MonitorWarningList'
import AbnormalStatistics from './AbnormalStatistics'
import ParametersOptimizedSimulator from './ParametersOptimizedSimulator'
import Simulator from './Simulator'
import { hasCorrectSegmentation } from '../../../utils/checkRules'
import axios from 'axios'

export default defineComponent({
  name: 'DashboardTask',
  components: {
    DecideDialog,
    DropdownSelect,
    MonitorWarningList,
    AbnormalStatistics,
    ParametersOptimizedSimulator,
    Simulator
  },
  props: {
    componentData: {
      type: Object,
      default: null,
      required: true
    },
    taskIndex: {
      type: Number,
      default: null
    },
    filters: {
      type: Array,
      default: () => []
    },
    yAxisControls: {
      type: Array,
      default: () => ({})
    },
    controls: {
      type: Array,
      default: () => []
    },
    isEditMode: {
      type: Boolean,
      default: false,
      required: true
    },
    warningModuleSetting: {
      type: Object,
      default: () => ({})
    },
    isCurrentDashboardInit: {
      type: Boolean,
      default: false
    },
    timeZone: {
      type: String,
      default: momentTZ.tz.guess()
    },
    appId: {
      type: Number,
      default: null
    }
  },
  setup () {
    const {
      askQuestion,
      askResult,
      getComponentList,
      askCancelTokenList,
      addCancelToken,
      askSpecificType
    } = useAskingModuleContext()
    return {
      dispatchAskQuestion: askQuestion,
      dispatchAskResult: askResult,
      getComponentList,
      askCancelTokenList,
      addCancelToken,
      askSpecificType
    }
  },
  data () {
    return {
      sizeTable,
      timeoutFunction: null,
      totalSec: 50,
      periodSec: 200,
      isShowConfirmDelete: false,
      autoRefreshFunction: null,
      debouncedAskFunction: null,
      isEmptyData: false,
      isComponentFailed: false,
      isIndexTypeComponentLoading: true,
      textComponentStyle: {
        'font-size': '20px',
        color: '#DDDDDD',
        'text-align': 'center',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center'
      },
      chartComponentStyle: {
        width: '100%',
        height: '100%'
      },
      chartToolboxSetting: {
        show: true,
        dataZoom: false,
        magicType: false,
        restore: false,
        dataView: true,
        brush: false,
        saveAsImage: false,
        myShowLabel: false
      },
      isProcessing: false,
      isAskQuestionFailed: false,
      askQuestionFailedMessage: null,
      tempFilteredKeyResultId: null,
      isInitializing: true,
      enableAlert: false,
      componentComplementaryInfo: null,
      taskId: uuidv4()
    }
  },
  computed: {
    ...mapState('dataSource', ['algoConfig']),
    keyResultId () {
      return this.tempFilteredKeyResultId
    },
    segmentation () {
      return this.componentData?.questionConfig?.segmentation
    },
    isAutoRefresh () {
      return this.componentData.settingConfig?.refresh?.isAuto
    },
    isIndependentComponent () {
      return ['monitor-warning-list', 'abnormal-statistics', 'simulator', 'parameters-optimized-simulator']
        .includes(this.componentData.type)
    },
    shouldComponentYAxisBeControlled () {
      // 表格型元件 不受 Y軸控制器 影響
      // 元件問題需包含數值型欄位
      if (
        this.componentData.diagram === 'table' ||
        ['monitor-warning-list', 'simulator', 'formula', 'parameters-optimized-simulator'].includes(this.componentData.type) ||
        this.yAxisControls.length === 0 ||
        this.componentNumericColumns.length === 0
      ) return false

      // 至少其中一個欄位名稱是任一 controller 中的選項
      return this.yAxisControls.some(controller => {
        return controller.options.some(option => {
          return this.componentNumericColumns.find(column => column.columnName === option.column.name)
        })
      })
    },
    componentNumericColumns () {
      const questionDataColumns = this.componentData.questionConfig?.dataColumns
      return (questionDataColumns && questionDataColumns.filter(column => column.statsType === 'NUMERIC')) || []
    },
    dataColumnAlias () {
      if (this.isIndependentComponent) return []
      return this.segmentation?.transcript.subjectList.reduce((acc, cur) => {
        if (!cur.dataColumn) return acc
        acc.push(cur.dataColumn.dataColumnAlias)
        return acc
      }, []) || []
    },
    dashboardTaskTitle () {
      if (this.shouldComponentYAxisBeControlled) {
        return this.controllerMutatedQuestion(true)
      }
      return this.componentData?.displayConfig?.title?.name
    },
    taskOriginTitle () {
      return this.componentData?.displayConfig?.title?.name
    },
    allFilterList () {
      // 可能會有階層，因此需要完全攤平
      return [].concat.apply([], [...this.filters, ...this.controls])
    },
    componentRestrictions () {
      const filterList = this.componentData.advanced?.filterList ?? []
      if (filterList.length === 0) return []
      // 先過濾出啟用的，再取得限制條件
      return filterList
        .filter(element => element.status)
        .map(element => element.restriction)
    },
    filterTime () {
      const relativeDatetime = this.filters
        .filter(item => item[0].statsType === 'RELATIVEDATETIME')
        .map(filter => (this.formatRelativeDatetime(filter[0].dataValues[0])))
      return relativeDatetime[0]
    },
    includeSameDataFrameFilter () {
      return this.allFilterList
        .reduce((acc, cur) => acc.concat(cur.dataFrameId), [])
        .includes(this.componentData.source.dataFrameId)
    },
    customCellClassName () {
      const relatedTable = this.componentData.settingConfig?.related?.table
      if (
        this.componentData.type !== 'chart' ||
        this.componentData.diagram !== 'table' ||
        !relatedTable?.active ||
        relatedTable?.triggerTarget !== 'column'
      ) return []
      if (this.isIndependentComponent) return []

      const columnId = this.componentData.settingConfig.related.table.column?.columnId
      const index = this.segmentation?.sentence.findIndex(element => element.dataColumnId === columnId)
      if (!columnId || index === -1) return []
      return [{
        type: 'column',
        index: index + 1,
        className: 'has-underline is-text-blue'
      }]
    },
    isHoverable () {
      const relatedTable = this.componentData.settingConfig?.related?.table
      return this.componentData.type === 'chart' &&
        this.componentData.diagram === 'table' &&
        relatedTable?.active &&
        relatedTable?.triggerTarget === 'row'
    },
    displayedRelatedDashboard () {
      const relatedDashboard = this.componentData.settingConfig.related.dashboard
      return relatedDashboard.active && `${this.$t('miniApp.relatedDashboard')}： ${relatedDashboard.name}`
    },
    displayedUpdateFrequency () {
      return `
        ${this.$t('miniApp.updateFrequency')}：
        ${this.$t('warRoom.everyMinute', { number: this.convertRefreshFrequency(this.componentData.settingConfig.refresh.refreshFrequency) / (60 * 1000) })}
      `
    },
    indexComponentStyle () {
      return {
        ...this.sizeTable[this.componentData.displayConfig.fontSize || 'middle'],
        color: '#2AD2E2'
      }
    },
    dynamicComponentStyle () {
      const denotation = this.segmentation?.denotation
      return {
        ...this.chartComponentStyle,
        ...((denotation === 'ANOMALY' || denotation === 'NORMALITY_TEST') && {
          height: 'calc(100% - 150px)'
        }),
        ...(denotation === 'STABILITY' && {
          height: 'calc(100% - 80px)'
        }),
        minHeight: '200px'
      }
    },
    componentSettingOptions () {
      const options = [
        {
          title: 'miniApp.componentSetting',
          icon: 'filter-setting',
          dialogName: 'CreateComponent'
        },
        {
          title: 'button.delete',
          icon: 'delete',
          dialogName: 'DeleteComponent'
        },
        ...(this.enableAlert ? [
          {
            title: 'button.createAlert',
            icon: 'warning',
            dialogName: 'CreateWarningCriteria'
          }
        ] : [])
      ]
      return options
    },
    anomalySetting () {
      const amonlySettings = this.componentData.displayConfig?.anomalySettings
      return {
        xAxis: {
          upperLimit: null,
          lowerLimit: null,
          markLine: null
        },
        yAxis: {
          upperLimit: null,
          lowerLimit: null,
          markLine: null,
          ...(amonlySettings && amonlySettings.length > 0 && formatAnomalySetting(amonlySettings))
        }
      }
    },
    customMarkLineConfig () {
      if (!this.componentData?.displayConfig?.customMarkLine) return null
      return this.componentData?.displayConfig?.customMarkLine || []
    },
    isStackOption () {
      return 'isStack' in this.componentData.displayConfig
    }
  },
  inject: ['appTimeZone'],
  watch: {
    isCurrentDashboardInit: {
      immediate: true,
      handler (isInit) {
        if (!isInit) return
        if (!this.isIndependentComponent) {
          this.isProcessing = true
          this.deboucedAskQuestion()
        }
        this.isInitializing = false
      }
    },
    // 當 Dashboard的 fitler 變動時，由元件內部去重新問問題
    filters: {
      deep: true,
      handler (newFilters, oldFilters) {
        if (!this.isIndependentComponent &&
          (JSON.stringify(newFilters) !== JSON.stringify(oldFilters))
        ) {
          this.deboucedAskQuestion()
        }
      }
    },
    controls: {
      deep: true,
      handler (newControls, oldControls) {
        if (!this.isIndependentComponent &&
          (JSON.stringify(newControls) !== JSON.stringify(oldControls))
        ) {
          this.deboucedAskQuestion()
        }
      }
    },
    yAxisControls: {
      deep: true,
      handler (newYControls, oldYControls) {
        if (this.isIndependentComponent) return
        if ((newYControls.length === 0 || this.shouldComponentYAxisBeControlled) &&
          JSON.stringify(newYControls) !== JSON.stringify(oldYControls)
        ) {
          // FIXME: 會導致 yAxis 重問問句，若 datetime column 有異動則會造成前後參考資料不一
          this.deboucedAskQuestion(true)
        }
      }
    },
    'componentData.formulaSetting': {
      deep: true,
      handler (setting) {
        if (!setting) return
        this.deboucedAskQuestion()
      }
    },
    allFilterList () {
      this.taskId = uuidv4()
    }
  },
  mounted () {
    if (this.isAutoRefresh && !this.isEditMode) this.setComponentRefresh()
    // table 需要設定額外設定樣式
    if (this.componentData.diagram === 'table') this.adjustToTableComponentStyle()
  },
  destroyed () {
    if (this.autoRefreshFunction) window.clearTimeout(this.autoRefreshFunction)
    if (this.debouncedAskFunction) window.clearTimeout(this.debouncedAskFunction)
    if (this.timeoutFunction) window.clearTimeout(this.timeoutFunction)
  },
  methods: {
    taskInit () {
      this.isProcessing = true
      this.isEmptyData = false
      this.isComponentFailed = false
    },
    deboucedAskQuestion (isNeededAskQuestion) {
      this.taskInit()
      // 避免在極短時間內，因 filter/controller 變動而多次觸發 askQuestion
      // Ex: 當外層 initFilters 的情境
      window.clearTimeout(this.debouncedAskFunction)
      /* 跳過 askQuestion 步驟
       * 但在場上有 YAxisController 且會被影響的 component 仍要從頭問問句
       * */
      if (!isNeededAskQuestion &&
        !this.shouldComponentYAxisBeControlled &&
        hasCorrectSegmentation(this.segmentation)
      ) {
        this.debouncedAskFunction = window.setTimeout(this.askResult, 0)
        return
      }
      this.debouncedAskFunction = window.setTimeout(this.askQuestion, 0)
    },
    askQuestion (question = this.controllerMutatedQuestion() || this.componentData?.questionConfig?.question) {
      window.clearTimeout(this.timeoutFunction)
      this.isProcessing = true
      this.isIndexTypeComponentLoading = true
      this.isComponentFailed = false
      this.enableAlert = false
      this.totalSec = 50
      this.periodSec = 200
      this.isEmptyData = false
      this.componentComplementaryInfo = null
      this.isAskQuestionFailed = false

      // 透過公式創建元件需使用不同方式取得 result
      if (this.componentData.type === 'formula') return this.getFormulaResult()

      this.$emit('setAskQuestionFlag', {
        uuid: this.componentData.uuid,
        status: false
      })
      this.dispatchAskQuestion({
        question,
        dataSourceId: this.componentData.source.dataSourceId,
        dataFrameId: this.componentData.source.dataFrameId,
        previewQuestionId: this.componentData?.questionConfig?.questionId,
        shouldCancelToken: false,
        language: this.componentData?.questionConfig?.parserLanguage
      }).then(response => {
        let questionId = response.questionId
        let segmentationList = response.segmentationList

        // 處理 NO_ANSWER, 多個結果
        if (segmentationList[0].denotation === 'NO_ANSWER' || segmentationList.length > 1) {
          this.isAskQuestionFailed = true
          this.isProcessing = false
          return
        }

        if (segmentationList.length === 1) {
          this.askResult(segmentationList[0], questionId)

          if (!hasCorrectSegmentation(this.segmentation)) {
            this.componentData.questionConfig.questionId = response.questionId
            this.componentData.questionConfig.segmentation = segmentationList[0]
            this.$emit('updateComponent', { ...this.componentData })
          }
        }
      }).catch(error => {
        this.isProcessing = false
      }).finally(() => {
        this.$emit('setAskQuestionFlag', {
          uuid: this.componentData.uuid,
          status: true
        })
      })
    },

    askResult (segmentation = this.segmentation, questionId = this.componentData?.questionConfig?.questionId) {
      this.addCancelToken({
        index: this.taskIndex,
        token: axios.CancelToken.source()
      })

      // 確認是否為趨勢類型問題
      const isTrendQuestion = segmentation.denotation === 'TREND'
      let dateTimeColumn = segmentation.transcript.subjectList.find(subject => subject.dateTime)
      this.askQuestionFailedMessage = null
      return this.dispatchAskResult({
        algoConfig: this.componentData?.questionConfig?.algoConfig || null,
        questionId,
        segmentation: segmentation,
        restrictions: this.restrictions(),
        selectedColumnList: this.componentData?.questionConfig?.advanced?.selectedColumnList ?? null,
        isFilter: true,
        index: this.taskIndex,
        ...(isTrendQuestion && {
          displayConfig: {
            histogramBarSize: null,
            sortOrders: dateTimeColumn ? [
              {
                dataColumnId: dateTimeColumn.dateTime.dataColumn.dataColumnId,
                sortType: 'DESC'
              }
            ] : []
          }
        }),
        timeZone: this.appTimeZone(), // 因相對時間篩選需要此參數
        language: this.componentData.questionConfig.parserLanguage || 'ZH_TW', // 因相對時間篩選需要此參數
        rebuildSegmentation: true // 因相對時間篩選需要此參數
      }).then(res => {
        this.getComponent(res.resultId)
      }).catch(error => {
        this.askQuestionFailedMessage = error?.error?.message || null
        this.isProcessing = false
      })
    },
    async fetchSpecificType (originResultId, type, data) {
      try {
        const { resultId } = await this.askSpecificType({
          resultId: originResultId,
          type: type,
          settingConfig: {
            displayConfig: {
              histogramBinSize: data
            }
          }
        })

        await this.getComponent(resultId)
      } catch (err) {
        console.log(err)
      }
    },
    getFormulaResult () {
      askFormulaResult({
        algoConfig: {
          ...this.algoConfig.formula,
          dataColumnIdList: this.componentData.formulaSetting.inputList.map(input => input.dcId),
          formulaId: this.componentData.formulaSetting.formulaId
        },
        dataFrameId: this.componentData.source.dataFrameId,
        restrictions: this.restrictions(),
        isFilter: true
      })
        .then(resultInfo => {
          this.getComponent(resultInfo.resultId)
        })
        .catch(error => { this.isProcessing = false })
    },
    getComponent (resultId) {
      this.getComponentList({ resultId })
        .then(componentResponse => {
          switch (componentResponse.status) {
            case 'Process':
            case 'Ready':
              this.timeoutFunction = window.setTimeout(() => {
                this.getComponent(resultId)
              }, this.totalSec)

              this.totalSec += this.periodSec
              this.periodSec = this.totalSec
              break
            case 'Complete':
              /**
               * 因為 resultId 在沒使用的情況之下會被清空，因此每次都得使用新的
               * 另外要打 fetchSpecificType 的前提是必須已經打過 componentList 因此暫時寫在這
               */
              if (this.componentData?.displayConfig?.binSize && !this.tempFilteredKeyResultId) {
                // 為了辨識是否已經打過 componentList
                this.tempFilteredKeyResultId = (componentResponse.componentIds.key_result ?? componentResponse.componentIds.model_predict)[0]
                // 為了在編輯時不用再重新用 resultId 取 componentList
                this.componentData.questionConfig.resultId = resultId
                this.fetchSpecificType(
                  resultId,
                  'OVERVIEW',
                  this.componentData.displayConfig.binSize
                )
                return
              }
              this.totalSec = 50
              this.periodSec = 200
              this.componentData.questionConfig.keyResultId = (componentResponse.componentIds.key_result ?? componentResponse.componentIds.model_predict)[0]
              this.tempFilteredKeyResultId = (componentResponse.componentIds.key_result ?? componentResponse.componentIds.model_predict)[0]
              this.isProcessing = false
              // 定期更新 component 資料
              if (this.isAutoRefresh && !this.isEditMode) this.setComponentRefresh()
              break
            case 'Disable':
            case 'Delete':
            case 'Warn':
            case 'Fail':
              this.isProcessing = false
              break
          }
        }).catch((error) => {
          this.isProcessing = false
          window.clearTimeout(this.autoRefreshFunction)
        })
    },
    restrictions () {
      const topRestrictions = this.allFilterList
        .filter(filter => {
          return this.checkShouldApplyMiniAppFilter(filter, this.componentData?.settingConfig?.dateTimeColumn)
        })
        .map(filter => {
          const filterType = filter.column.type
          let type = ''
          let data_type = ''
          switch (filterType) {
            case ('STRING'):
            case ('BOOLEAN'):
            case ('CATEGORY'):
              data_type = 'string'
              type = 'enum'
              break
            case ('FLOAT'):
            case ('NUMERIC'):
              data_type = 'int'
              type = 'range'
              break
            case ('DATETIME'):
            case ('RELATIVEDATETIME'):
            case ('CUSTOMDATETIME'):
              data_type = 'datetime'
              type = 'range'
              break
          }

          // 相對時間 filter 需取當前元件所屬 dataframe 的預設時間欄位和當前時間來套用
          if (filterType === 'RELATIVEDATETIME') {
            return [{
              type,
              properties: {
                data_type,
                dc_id: this.componentData?.settingConfig?.dateTimeColumn.columnId,
                display_name: this.componentData?.settingConfig?.dateTimeColumn.primaryAlias,
                ...this.formatRelativeDatetime(filter.optionValues[0])
              }
            }]
          } else if (filterType === 'CUSTOMDATETIME') {
            return [{
              type,
              properties: {
                data_type,
                dc_id: this.componentData?.settingConfig?.dateTimeColumn.columnId,
                display_name: this.componentData?.settingConfig?.dateTimeColumn.primaryAlias,
                ...this.formatCustomDatetime(filter.optionValues[0])
              }
            }]
          }

          return [{
            type,
            properties: {
              data_type,
              dc_id: filter.column.columnId,
              display_name: filter.column.name,
              ...((filterType === 'STRING' || filterType === 'BOOLEAN' || filterType === 'CATEGORY') && {
                datavalues: filter.optionValues,
                display_datavalues: filter.optionValues
              }),
              ...((filterType === 'NUMERIC' || filterType === 'FLOAT' || filterType === 'DATETIME') && {
                start: filter.valueRange.start,
                end: filter.valueRange.end
              })
            }
          }]
        })
      return [
        ...topRestrictions,
        ...this.componentRestrictions
      ]
    },
    includeSameColumnPrimaryAliasFilter (filterName) {
      return this.componentData.questionConfig.dataColumns.find(column => column.columnName === filterName)
    },
    confirmDelete () {
      this.isShowConfirmDelete = false
      this.$emit('deleteComponentRelation', this.componentData.uuid)
    },
    setComponentRefresh () {
      this.autoRefreshFunction = window.setTimeout(() => {
        this.deboucedAskQuestion()
      }, this.convertRefreshFrequency(this.componentData.settingConfig.refresh.refreshFrequency))
    },
    convertRefreshFrequency (cronTab) {
      switch (cronTab) {
        case '* * * * *':
          return 60 * 1000
        case '*/5 * * * *':
          return 5 * 60 * 1000
        case '*/15 * * * *':
          return 15 * 60 * 1000
        case '*/30 * * * *':
          return 30 * 60 * 1000
        case '*/45 * * * *':
          return 45 * 60 * 1000
        case '0 * * * *':
          return 60 * 60 * 1000
        case '0 0 * * *':
          return 24 * 60 * 60 * 1000
        case '0 0 * * 0':
          return 7 * 24 * 60 * 1000
        case '0 0 1 * *':
          return 30 * 7 * 24 * 60 * 1000
      }
    },
    // TODO: 這塊跟 CreateComponentDialog 重複應該找時間優化一下
    formatRelativeDatetime (dataValue) {
      const properties = {
        start: null,
        end: null
      }
      const currentTimeZone = this.timeZone ?? momentTZ.tz.guess()
      // update datetime range
      switch (dataValue) {
        case 'today':
          properties.start = momentTZ().tz(currentTimeZone).startOf('day').format('YYYY-MM-DD HH:mm')
          properties.end = momentTZ().tz(currentTimeZone).endOf('day').format('YYYY-MM-DD HH:mm')
          break
        // 注意！每週是從週一到週日，要使用 isoWeek
        case 'week':
          properties.start = momentTZ().tz(currentTimeZone).startOf('isoWeek').format('YYYY-MM-DD HH:mm')
          properties.end = momentTZ().tz(currentTimeZone).endOf('isoWeek').format('YYYY-MM-DD HH:mm')
          break
        case 'month':
        case 'quarter':
        case 'year':
          properties.start = momentTZ().tz(currentTimeZone).startOf(dataValue).format('YYYY-MM-DD HH:mm')
          properties.end = momentTZ().tz(currentTimeZone).endOf(dataValue).format('YYYY-MM-DD HH:mm')
          break
        default:
          if (RegExp('^.*hour.*$').test(dataValue)) {
            const hour = Number(dataValue.split('hour')[0])
            properties.start = momentTZ().tz(currentTimeZone).subtract(hour, 'hours').format('YYYY-MM-DD HH:mm')
            properties.end = momentTZ().tz(currentTimeZone).format('YYYY-MM-DD HH:mm')
          } else {
            properties.start = null
            properties.end = null
          }
      }
      return properties
    },
    formatCustomDatetime ({ timeUnit, timeValue }) {
      const currentTimeZone = this.timeZone ?? momentTZ.tz.guess()
      return {
        start: momentTZ().tz(currentTimeZone).subtract(timeUnit, timeValue).format('YYYY-MM-DD HH:mm'),
        end: momentTZ().tz(currentTimeZone).format('YYYY-MM-DD HH:mm')
      }
    },
    columnTriggered ({ row, column }) {
      const relatedDashboardId = this.componentData.settingConfig.related.table.dashboardId
      const relatedColumn = this.componentData.settingConfig.related.table.column
      if (!relatedColumn) return
      const { columnId, columnAlias: columnName } = relatedColumn

      if (this.componentData.settingConfig.related.table.triggerTarget !== 'column' ||
        column.label !== columnName) return

      this.$emit('columnTriggered', {
        relatedDashboardId,
        columnName,
        columnId,
        cellValue: row[column.index - 1]
      })
    },
    rowTriggered ({ row, header }) {
      const { triggerTarget } = this.componentData.settingConfig.related.table

      if (triggerTarget !== 'row') return
      const { dashboardId } = this.componentData.settingConfig.related.table
      const rowData = this.componentData.questionConfig.dataColumns.map(column => ({
        ...column,
        cellValue: row[header.indexOf(column.columnName)]
      }))
      this.$emit('rowTriggered', {
        relatedDashboardId: dashboardId,
        rowData
      })
    },
    chartriggered (restrictions) {
      if (!this.componentData.settingConfig?.related?.dashboard || !this.componentData.settingConfig.related.dashboard?.active) return
      const acceptedColumnStatesTypeList = ['category']
      if (!restrictions.some(restriction => acceptedColumnStatesTypeList.includes(restriction.stats_type))) return
      this.$emit('chartTriggered', {
        relatedDashboardId: this.componentData.settingConfig.related.dashboard.dashboardId,
        restrictions
      })
    },
    adjustToTableComponentStyle () {
      // 取當前元件中，擺放 table 空間的高度（扣除 pagination）
      const maxHeight = this.$refs.component.getBoundingClientRect().height - 135
      this.$set(this.chartComponentStyle, 'height', maxHeight + 'px')
    },
    updateComponentConfigInfo ({ enableAlert, supportedFunction, ...axisData }) {
      // 存取元件能使用的功能及其資料
      this.componentComplementaryInfo = {
        chartInfo: axisData || {},
        supportedFunction
      }

      // 能用來轉示警條件的元件類型
      const enabledComponentTypeList = ['formula', 'chart']
      const isEnabledComponent = enabledComponentTypeList.includes(this.componentData.type)
      if (!isEnabledComponent) return
      this.enableAlert = enableAlert
    },
    onComponentFailed () {
      this.isComponentFailed = true
      this.enableAlert = false
    },
    columnNameStringToTag (name) {
      return `<div style="text-decoration: underline; margin-left: 4px; white-space: nowrap; display: inline-block;">${name}</div>`
    },
    controllerMutatedQuestion (isWithStyling = false) {
      if (this.isIndependentComponent) return ''
      if (!this.shouldComponentYAxisBeControlled) return ''

      const questionWordList = this.segmentation?.sentence.map(word => ({
        ...word,
        isChanged: false
      }))
      const whiteSpace = isWithStyling ? '&ensp;' : ' '

      const availableControllers = JSON.parse(JSON.stringify(this.yAxisControls))
      for (let alias of this.dataColumnAlias) {
        // 逐一確認有無 y controller 包含當前 alias 的選項
        availableControllers.forEach((controller, index) => {
          const hasMatchedOption = controller.options.some(option => {
            return option.column.name === alias
          })
          if (hasMatchedOption) {
            // 替換成選定的欄位名稱
            const selectedOption = controller.options.find(option => {
              return option.column.columnId === controller.activeColumnId
            })

            const matchedWord = questionWordList.find(wordItem => !wordItem.isChanged && wordItem.matchedWord === alias)
            if (matchedWord && selectedOption) {
              matchedWord.word = isWithStyling ? this.columnNameStringToTag(selectedOption.column.name) : selectedOption.column.name
              matchedWord.isChanged = true
            }

            // 從可用名單中拔除，下一個 alias 只能從其他 y controller 適用
            availableControllers.splice(index, 1)
          }
        })

        if (availableControllers.length === 0) break
      }
      return questionWordList.reduce((acc, cur, index) => {
        acc += `${index !== 0 ? whiteSpace : ''}${cur.word}`
        return acc
      }, '')
    },
    convertMagicTypeDiagram (type, magicType) {
      if (type === 'paramCompare') return 'param_comparison_table'

      return ({
        bar: 'bar_chart',
        line: 'line_chart'
      })[magicType] || null
    }
  }
})
</script>

<style lang="scss" scoped>
/* 定義欄和列的尺寸 */
$direction-size: ('col': 100%, 'row': 100%);

/* 定義每欄和每列要切幾等分 */
$direction-span: ('col': 12, 'row': 12);

/* 依照已定義好的尺寸和等份，製作欄和列使用的 class */
@each $direction, $size in $direction-size {
  $span-amount: map-get($direction-span, $direction);

  @for $span from 1 through $span-amount {
    $property: if($direction == 'col', 'width', 'height');
    .#{$direction}-#{$span} {
      #{$property}: to-fixed(($size / $span-amount) * $span);
    }
  }
}

@mixin dropdown-select-controller {
  &:hover {
    .dropdown-select { visibility: visible; }
  }
}

@keyframes flash {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.component {
  &__item {
    border-radius: 5px;
    float: left;
    height: 100%;
    // padding-bottom: 16px;
    // padding-right: 16px;
    transition: all 0.2s linear;
    width: 100%;

    &-init-spinner {
      margin: auto;
    }

    &-inner-container {
      background-color: #192323;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 16px;
      width: 100%;
    }

    &-header {
      align-items: center;
      display: flex;
      height: 30px;
      justify-content: space-between;
      margin-bottom: 16px;

      .header-left {
        display: flex;

        .item-title {
          @include text-hidden;

          color: #ddd;
          display: flex;
        }
      }

      .header-right {
        display: flex;
        justify-content: flex-end;
        z-index: 3;

        .component-property-box {
          align-items: center;
          cursor: pointer;
          display: flex;
          justify-content: flex-end;

          .property {
            &__item {
              align-items: center;
              background-color: #474f4f;
              border: 1px solid #192323;
              border-radius: 50%;
              display: flex;
              flex: 0 0 24px;
              height: 24px;
              justify-content: center;
              transition: 0.1s all cubic-bezier(1, -0.9, 0.89, 1.34);

              &:not(:last-child) {
                margin-right: -5px;
              }

              .svg-icon {
                width: 14px;
              }

              .icon-refresh {
                // 為了讓此 icon 與相鄰的 icon 視覺面積較為一致
                transform: scale(1.1);
              }
            }
          }

          &:hover {
            .property {
              &__item {
                &:not(:last-child) {
                  margin-right: 5px;
                }
              }
            }
          }
        }

        .component-setting-box {
          @include dropdown-select-controller;

          align-items: center;
          color: $theme-color-primary;
          cursor: pointer;
          display: flex;
          flex: 0 0 30px;
          height: 30px;
          justify-content: center;
          position: relative;
          transition: 0.2s all ease;

          &:hover {
            background-color: $theme-color-primary;
            border-radius: 4px;
            color: #fff;
          }

          .dropdown-select {
            visibility: hidden;
            z-index: 1;

            ::v-deep .dropdown-select-box {
              box-shadow: 0 2px 5px rgba(34, 117, 125, 0.5);
              left: unset;
              right: 0;
              top: 31px;

              &::before {
                right: 5px;
              }

              .svg-icon {
                color: $theme-color-primary;
              }

              .dropdown-flex {
                min-width: unset;
              }
            }
          }
        }

        .component-property-box ~ .component-setting-box {
          margin-left: 8px;
        }
      }

      .icon-warning {
        animation: flash 1s infinite;
        color: $theme-color-danger;
      }
    }

    &-content {
      align-items: center;
      display: flex;
      flex: 1;
      height: 100%;
      justify-content: center;
      min-height: 0;
      overflow: auto;
      overflow: overlay;

      ::v-deep .task,
      ::v-deep .task-component {
        height: 100%;
        position: relative;
        width: 100%;
      }

      ::v-deep .no-result-block {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }

      .no-result {
        position: relative;
        top: unset;
        transform: unset;
      }

      &.index {
        ::v-deep .not-empty {
          max-width: 100%;
          width: auto;
        }

        .index-unit {
          color: #2ad2e2;
          font-size: 36px;
          font-weight: 600;
          &.large { font-size: 36px; }
          &.middle { font-size: 24px; }
          &.small { font-size: 18px; }
          &.mini { font-size: 18px; }
        }

        .index-data {
          align-items: flex-end;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
        }
      }

      .spinner-block {
        flex: 1;
      }
    }

    ::v-deep .task {
      .task-spinner.key-result-spinner {
        height: 100%;
      }
    }
  }

  &__item-wrapper-size {
    input {
      background-color: transparent;
      border: 1px solid #ddd;
      color: #fff;
      width: 40px;
    }
  }
}

</style>
