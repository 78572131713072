<template>
  <div class="abnormal-statistics">
    <spinner
      v-if="isLoading"
      class="list__spinner"
      size="50"
    />
    <div
      v-else-if="!warningModuleSetting.activate"
      class="empty-text"
    >
      {{ $t('alert.alertNotActivate') }}
    </div>
    <display-index-info
      v-else
      :dataset="{data: [logCount]}"
      :custom-chart-style="indexComponentStyle"
    />
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from '@vue/composition-api'
import { getAlertLogsCount } from '@/API/Alert'
import { sizeTable } from '@/utils/general'
import { useMapGetters } from '@/utils/composable/vuex'
import { getMiniAppConditions } from '@/API/MiniApp'

export default defineComponent({
  name: 'AbnormalStatistics',
  props: {
    type: {
      type: String,
      required: true
    },
    fontSize: {
      type: String,
      default: 'middle'
    },
    warningModuleSetting: {
      type: Object,
      default: () => ({})
    },
    filterTime: {
      type: Object,
      default: () => ({
        start: null,
        end: null
      })
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    appId: {
      type: Number,
      default: null
    }
  },
  setup (props, { emit }) {
    const isLoading = ref(false)
    const { getCurrentGroupId } = useMapGetters('userManagement', ['getCurrentGroupId'])

    watch(
      () => props.filterTime,
      (newValue, oldValue) => {
        if (!oldValue || (JSON.stringify(newValue) === JSON.stringify(oldValue))) return
        fetchData()
      },
      {
        immediate: true,
        deep: true
      }
    )

    // ----- check condition -----
    const appActiveConditions = ref([])
    const logCount = ref(0)
    const isGetHandledComponentCount = computed(() => {
      return props.type === 'handled-abnormal-statistics'
    })

    const checkActivateAndFetchWarningConditions = () => {
      // 若警示未發布，則不必去處理有無condition，而是直接顯示尚未發布
      if (!props.warningModuleSetting.activate || !props.appId) return isLoading.value = false
      getMiniAppConditions(props.appId)
        .then(conditionList => {
          if (conditionList.length === 0) return isLoading.value = false
          appActiveConditions.value = conditionList?.length
            ? conditionList
              .filter((condition) => condition.active)
              .map((condition) => condition.id)
            : []
          fetchData()
        })
        .catch(() => isLoading.value = false)
    }

    const fetchData = () => {
      isLoading.value = true
      getAlertLogsCount({
        conditionIds: appActiveConditions.value,
        groupId: getCurrentGroupId.value,
        active: isGetHandledComponentCount.value,
        startTime: props.filterTime.start,
        endTime: props.filterTime.end
      })
        .then(count => {
          logCount.value = count
        })
        .finally(() => { isLoading.value = false })
    }

    // ----- style -----
    const textColor = computed(() => {
      if (logCount.value === 0) return '#999999'
      return isGetHandledComponentCount.value ? '#2AD2E2' : '#FF5C46'
    })
    const indexComponentStyle = computed(() => ({
      ...sizeTable[props.fontSize],
      color: textColor.value
    }))

    // ---- mounted -----
    const init = () => {
      isLoading.value = true
      checkActivateAndFetchWarningConditions()
    }

    onMounted(() => {
      init()
    })

    return {
      isLoading,
      logCount,
      isGetHandledComponentCount,
      indexComponentStyle
    }
  }
})
</script>

<style lang="scss" scoped>
.abnormal-statistics {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: auto;
  overflow: overlay;
  width: 100%;

  .index-info {
    text-align: center;
  }

  .empty-text {
    align-items: center;
    color: #999;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
  }
}
</style>
