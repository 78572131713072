<template>
  <writing-dialog
    :title="$t('miniApp.createDashboard')"
    :button="$t('button.create')"
    :show-both="true"
    :is-loading="isProcessing"
    @closeDialog="$emit('close')"
    @confirmBtn="createDashboard"
  >
    <input-verify
      v-validate="`required|max:${max}`"
      v-model="dashboardName"
      :placeholder="$t('miniApp.pleaseTypeDashboardName')"
      name="dashboardName"
    />
  </writing-dialog>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import WritingDialog from '@/components/dialog/WritingDialog'
import InputVerify from '@/components/InputVerify'
import { useMapGetters } from '@/utils/composable/vuex'
import { v4 as uuidv4 } from 'uuid'
import { useValidator } from '@/utils/composable/validator'

export default defineComponent({
  inject: ['$validator'],
  name: 'CreateDashboardDialog',
  components: {
    WritingDialog,
    InputVerify
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const dashboardName = ref(null)
    const validator = useValidator()

    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])

    const createDashboard = () => {
      validator.validateAll().then(valid => {
        if (!valid) return

        emit('create', {
          name: dashboardName.value,
          uuid: uuidv4()
        })
      })
    }

    return {
      dashboardName,
      max,
      createDashboard
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
