<template>
  <form
    data-vv-scope="customMarkLine"
    class="key-result__setting anomaly test"
    @submit.prevent="setLocalCustomAnomoly"
  >
    <div class="anomaly__title">
      {{ $t('miniApp.customMarkLineTitle') }}
    </div>
    <div class="anomaly__content">
      <div class="anomaly__content-title">
        {{ $t("miniApp.anomalyRules") }}
      </div>
      <div class="anomaly__settings">
        <div class="anomaly__settings--top">
          <div
            v-if="!customMarkLineSetting || customMarkLineSetting.length === 0"
            class="anomaly__empty-message"
          >
            {{ $t("miniApp.emptyRules") }}
          </div>
          <template v-else>
            <div
              v-for="setting in customMarkLineSetting"
              :key="setting.id"
              class="anomaly__setting-item threshold"
            >
              <div class="threshold--left">
                <input-block
                  v-model="setting.label"
                  :placeholder="$t('editing.columnTag')"
                  name="customAmonalyLabel"
                />
                <input-block
                  v-model.trim="setting.value"
                  v-validate="'decimal|required'"
                  :placeholder="$t('miniApp.datumValue')"
                  :name="'anomaly.' + setting.id + '-componentDisplayName'"
                  class="threshold__input"
                />
              </div>
              <div
                class="threshold--right"
                @click="removeCustomAnomalySetting(setting.id)"
              >
                <svg-icon
                  icon-class="delete"
                  class="icon threshold__delete-icon"
                />
              </div>
            </div>
          </template>
        </div>
        <div class="anomaly__settings--bottom">
          <button
            type="button"
            class="btn btn-outline"
            @click="createCustomAnomolyNewRule"
          >
            {{ $t("button.createNewRule") }}
          </button>
          <button
            v-if="isCustomAnomalySettingChanged"
            type="submit"
            class="btn btn-default anomaly__button"
          >
            {{ $t("button.save") }}
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import InputVerify from '@/components/InputVerify'
import InputBlock from '@/components/InputBlock'

export default {
  inject: ['$validator'],
  name: 'CustomAnomaly',
  components: {
    InputVerify,
    InputBlock
  },
  props: {
    componentId: {
      type: String,
      default: null
    },
    markLineConfig: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    let customMarkLineSetting = []
    if (this.markLineConfig && JSON.parse(JSON.stringify(this.markLineConfig))) {
      customMarkLineSetting = JSON.parse(JSON.stringify(this.markLineConfig))
    }

    return {
      isShowCustomAnomaly: false,
      customMarkLineSetting,
      isCustomAnomalySettingChanged: false
    }
  },
  watch: {
    customMarkLineSetting: {
      deep: true,
      handler (val) {
        if (val && (this.markLineConfig.length !== val.length)) {
          this.isCustomAnomalySettingChanged = true
        } else {
          const newUuidList = val.map((item) => item.id)
          const OriginUuidList = this.markLineConfig.map((item) => item.id)

          newUuidList.forEach((key, i) => {
            const localIndex = OriginUuidList.indexOf(key)
            const isLabelMatch = val[i].label === this.markLineConfig[localIndex].label
            const isValueMatch = val[i].value === this.markLineConfig[localIndex].value

            if (!(isLabelMatch && isValueMatch)) {
              this.isCustomAnomalySettingChanged = true
            }
          })
        }
      }
    }
  },
  methods: {
    removeCustomAnomalySetting (settingId) {
      this.customMarkLineSetting =
        this.customMarkLineSetting.filter(
          (setting) => setting.id !== settingId
        )
      this.isCustomAnomalySettingChanged = true
    },
    createCustomAnomolyNewRule () {
      this.customMarkLineSetting.push({
        componentId: this.componentId,
        id: uuidv4(),
        label: null,
        value: null
      })
      this.isCustomAnomalySettingChanged = true
    },
    async setLocalCustomAnomoly () {
      let validateCustomMarkLine = await this.$validator.validateAll('customMarkLine')
      if (!validateCustomMarkLine) return

      this.$emit('updateCustomMarkLine', this.customMarkLineSetting)

      this.isCustomAnomalySettingChanged = false
    }

  }
}
</script>

<style lang="scss" scoped>
.key-result {
  &__settings {
    display: flex;
    flex: 1;
    flex-direction: column;

    &--top {
      display: flex;
      margin-bottom: 16px;
    }
  }

  &__setting-item {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}

.anomaly {
  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
  }

  &__content {
    background: #1c292b;
    border-radius: 12px;
    display: flex;
    padding: 18.5px;
  }

  &__content-title {
    color: #aaa;
    font-size: 14px;
    font-weight: 600;
    margin-right: 33px;
  }

  &__settings {
    display: flex;
    flex: 1;
    flex-direction: column;

    &--top {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
    }
  }

  &__setting-item {
    flex: 0 0 calc(50% - 16px);
    margin-bottom: 16px;

    &:nth-child(2n) {
      margin-left: 16px;
    }

    .input-block {
      margin-bottom: 12px;
    }
  }

  &__empty-message {
    color: #aaa;
    font-size: 14px;
  }

  .threshold {
    background: #141c1d;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    padding: 18px 20px;
    width: 50%;

    &--left {
      display: flex;
    }

    &__select {
      border-bottom: 1px solid #fff;
      height: 40px;
      margin-right: 16px;
      max-width: 115px;
    }

    &__input {
      margin-left: 16px;
      width: 135px;
      // ::v-deep .input-verify-text {
      //   margin-bottom: 12px;
      // }

      // ::v-deep .input-error {
      //   margin-bottom: -20px;
      // }
    }

    &__delete-icon {
      color: #aaa;
      cursor: pointer;
    }
  }
}
</style>
