<template>
  <section class="simulator-setting">
    <!--模型設定-->
    <div class="setting">
      <div class="setting__title">
        {{ $t('miniApp.modelSetting') }}
      </div>
      <div class="setting__content">
        <div class="input-field">
          <label class="input-field__label">{{ $t('miniApp.operatedModel') }}</label>
          <div class="input-field__input-box">
            <default-select
              v-validate="'required'"
              :option-list="modelOptionList"
              :placeholder="$t('miniApp.selectModel')"
              :is-disabled="isLoading"
              :is-loading="isFetchingModelInfo"
              v-model="modelSetting.modelId"
              filterable
              class="input-field__select long-select"
              name="model"
              @change="updateModelSetting"
            >
              <template #option-content="{ option }">
                <span>{{ option.name }}</span>
                <span class="input-field__select-annotation">{{ $t(`model.${option.type}Output`) }}</span>
              </template>
            </default-select>
            <span
              v-if="modelSetting.type"
              class="input-field__type"
            >
              ({{ $t(`model.${modelSetting.type}Output`) }})
            </span>
            <div
              v-show="errors.has('model')"
              class="error-text"
            >
              {{ errors.first('model') }}
            </div>
          </div>
        </div>
        <template v-if="modelSetting.modelId && !isMultiDatasetModel">
          <div
            v-if="modelOuptputList.length > 0"
            class="input-field"
          >
            <label class="input-field__label">{{ $t('miniApp.modelOperatedResult') }}</label>
            <div class="input-field__input-box">
              {{ modelOuptputList | mergeModelOutputList }}
            </div>
          </div>
          <spinner
            v-else
            size="20"
          />
        </template>
      </div>
    </div>
    <!--資料原設定-->
    <div
      v-if="modelSetting.type && !isMultiDatasetModel"
      class="setting"
    >
      <div class="setting__title">
        {{ $t('miniApp.sourceOfDataSetting') }}
      </div>
      <div class="setting__content">
        <div class="input-field">
          <label class="input-field__label">{{ $t('miniApp.dataSource') }}</label>
          <div class="input-field__input-box">
            <default-select
              v-validate="'required'"
              :option-list="dataSourceOptionList"
              :placeholder="$t('miniApp.chooseDataSource')"
              :is-disabled="isLoading"
              :is-loading="isFetchingDataSourceInfo"
              v-model="dataSourceId"
              filterable
              class="input-field__select"
              name="dataSourceId"
              @change="handleDataSourceSelected"
            />
            <div
              v-show="errors.has('dataSourceId')"
              class="error-text"
            >
              {{ errors.first('dataSourceId') }}
            </div>
          </div>
        </div>
        <div class="input-field">
          <label class="input-field__label">{{ $t('miniApp.dataFrame') }}</label>
          <div class="input-field__input-box">
            <default-select
              v-validate="'required'"
              :option-list="dataFrameOptionList"
              :placeholder="$t('miniApp.chooseDataFrame')"
              :is-disabled="isLoading"
              :is-loading="isFetchingDataFrameInfo"
              v-model="dataFrameId"
              filterable
              class="input-field__select"
              name="dataFrameId"
              @change="handleDataFrameSelected"
            />
            <div
              v-show="errors.has('dataFrameId')"
              class="error-text"
            >
              {{ errors.first('dataFrameId') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Input 設定-->
    <div
      v-if="(dataSourceId && dataFrameId && modelSetting.modelId) || isMultiDatasetModel"
      class="setting"
    >
      <div class="setting__title">
        {{ $t('miniApp.inputParamSetting') }}
      </div>
      <div class="setting__content list">
        <spinner
          v-if="isLoading"
          size="20"
        />
        <template v-else-if="!isMultiDatasetModel">
          <div class="list__header">
            <div class="list__title">
              {{ $t('miniApp.modelInputParams') }}
            </div>
            <div class="list__title">
              {{ $t('miniApp.targetedDataFrameColumn') }}
            </div>
          </div>
          <column-setting-card
            v-for="(input, index) in modelInputList"
            :key="`${input.modelColumnName}-${index}`"
            :is-loading="isFetchingDataColumnInfo"
            :is-multi-dataset-model="isMultiDatasetModel"
            :option-list="getDataColumnOptionList(input.statsType)"
            :column-info="input"
            :column-index="index"
            :input-id.sync="modelSetting.inputList[index].id"
            @change="handleModelInputChange(index, $event)"
          />
        </template>
        <template v-else>
          <div class="list__header">
            <div class="list__title">
              {{ $t('miniApp.paramsType') }}
              <remind-popover
                placement="right"
                icon-name="info"
                icon-color="#FFDF6F"
                width="250"
                :content="$t('miniApp.notificationMessage.paramsType')"
              />
            </div>
            <div class="list__title">
              {{ $t('modelFlow.modelInputParameter') }}
            </div>
            <div class="list__title">
              {{ $t('miniApp.targetedDataFrameColumn') }}
            </div>
          </div>
          <column-setting-card
            v-for="(input, index) in modelInputList"
            :key="`${input.modelColumnName}-${index}`"
            :is-loading="isLoading"
            :is-multi-dataset-model="isMultiDatasetModel"
            :has-model-id="!!modelSetting.modelId"
            :option-list="dataSourceOptionList"
            :column-info="input"
            :column-index="index"
            :source-id.sync="modelSetting.inputList[index].dataSourceId"
            :input-id.sync="modelSetting.inputList[index].id"
            @change="handleDataChange(index, $event)"
          >
            <template slot="prefixOption">
              <div class="input-field__input-box">
                <default-select
                  v-validate="'required'"
                  v-model="modelSetting.inputList[index].paramType"
                  :option-list="paramsTypeList"
                  :popper-append-to-body="true"
                  name="paramsType"
                  class="input-field__select short-select"
                  @change="handleParamTypeChange(index, $event)"
                />
                <div
                  v-show="errors.has('paramsType')"
                  class="error-text"
                >
                  {{ errors.first('paramsType') }}
                </div>
              </div>
            </template>
          </column-setting-card>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import DefaultSelect from '@/components/select/DefaultSelect'
import ColumnSettingCard from './ColumnSettingCard.vue'
import RemindPopover from '@/components/popover/RemindPopover.vue'
import {
  getDataFrameById,
  getDataFrameColumnInfoById,
  getDateTimeColumns
} from '@/API/DataSource'
import {
  getModelInfo,
  getModelList
} from '@/API/Model'
import { mapGetters } from 'vuex'

export default {
  inject: ['$validator'],
  name: 'SimulatorSetting',
  components: {
    ColumnSettingCard,
    DefaultSelect,
    RemindPopover
  },
  filters: {
    mergeModelOutputList (list) {
      if (!list || list.length === 0) return ''
      return list.map(output => output.modelColumnName).join('、')
    }
  },
  props: {
    dataSource: {
      type: Object,
      default: () => ({
        dataSourceId: null,
        dataFrameId: null
      })
    },
    modelSetting: {
      type: Object,
      default: () => ({
        modelId: null,
        type: null,
        inputList: []
      })
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isFailed: {
      type: Boolean,
      default: false
    },
    currentComponentType: {
      type: String,
      required: true
    },
    dateTimeColumn: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isFetchingModelInfo: false,
      isFetchingDataSourceInfo: false,
      isFetchingDataFrameInfo: false,
      isFetchingDataColumnInfo: false,
      dataSourceOptionList: [],
      dataFrameOptionList: [],
      modelOptionList: [],
      modelInputList: [],
      modelOuptputList: [],
      dataColumnOptionList: [],
      paramsTypeList: [
        {
          name: this.$t('miniApp.simulatorParams'),
          value: 'SIMULATE'
        },
        {
          name: this.$t('miniApp.fixedParams'),
          value: 'FIXED'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('dataSource', ['dataSourceList']),
    ...mapGetters('userManagement', ['getCurrentGroupId']),
    dataSourceId: {
      get () {
        return this.dataSource?.dataSourceId
      },
      set (val) {
        this.dataSource.dataSourceId = val
      }
    },
    dataFrameId: {
      get () {
        return this.dataSource?.dataFrameId
      },
      set (val) {
        this.dataSource.dataFrameId = val
      }
    },
    isMultiDatasetModel () {
      return this.modelSetting?.type === 'dataFrame'
    }
  },
  mounted () {
    this.$watch(
      (vm) => [vm.modelSetting.modelId, vm.dataFrameId],
      (val) => {
        const intervalFunction = window.setInterval(() => {
          if (this.modelSetting.inputList.length > 0) {
            const tmpList = [...this.modelSetting.inputList]
            if (!this.isMultiDatasetModel) {
              if (!this.dataColumnOptionList?.length) return
              window.clearInterval(intervalFunction)
              // 如果欄位名稱相同就帶入預設
              this.modelInputList.forEach((input, index) => {
                let correspondColumn = this.dataColumnOptionList.find(option => option.name.split('（')[0] === input.modelColumnName)
                if (correspondColumn) {
                  tmpList[index] = correspondColumn
                }
              })
              this.modelSetting.inputList = JSON.parse(JSON.stringify(tmpList))
            } else {
              window.clearInterval(intervalFunction)
              this.modelSetting.inputList = JSON.parse(JSON.stringify(tmpList))
            }
          }
        }, 500)
      },
      {
        deep: true
      }
    )
    this.getModelList()
    this.fetchDataSourceList()
    // 重新編輯時才直接取 model 資料
    this.modelSetting.modelId && this.fetchModelInfo(this.modelSetting.modelId)
  },
  methods: {
    fetchDataSourceList () {
      this.$emit('update:isLoading', true)
      this.isFetchingDataSourceInfo = true
      this.dataSourceOptionList = this.dataSourceList.reduce((acc, cur) => {
        if (cur.state !== 'ENABLE' || cur.enableDataFrameCount < 1) return acc
        acc.push({
          name: cur.name,
          value: cur.id
        })
        return acc
      }, [])
      // 如果是在編輯模式，自動去取得資料表清單
      this.dataSourceId && this.fetchDataFrameList()
      this.$emit('update:isLoading', false)
      this.isFetchingDataSourceInfo = false
    },
    handleDataSourceSelected () {
      this.$emit('update:isLoading', true)
      // 清空原資料
      this.dataColumnOptionList = []
      this.dataFrameOptionList = []
      this.resetInputList()
      this.dataFrameId = null
      this.fetchDataFrameList()
    },
    fetchDataFrameList () {
      this.$emit('update:isLoading', true)
      this.isFetchingDataFrameInfo = true
      const isGetAllStatesDataframe = false
      getDataFrameById(this.dataSourceId, isGetAllStatesDataframe)
        .then(response => {
          this.dataFrameOptionList = response
            .filter((dataFrame) => !dataFrame.generatedByModelFlow)
            .map(dataFrame => ({
              name: dataFrame.primaryAlias,
              value: dataFrame.id
            }))
          // 如果是在編輯模式，自動去取得資料欄位清單
          this.dataFrameId && this.fetchDataColumnList(this.dataFrameId)
        })
        .finally(() => {
          this.$emit('update:isLoading', false)
          this.isFetchingDataFrameInfo = false
        })
    },
    handleDataFrameSelected (dataFrameId) {
      // 清空原資料
      this.dataColumnOptionList = []
      this.resetInputList()
      this.fetchDataColumnList(dataFrameId)
      this.setDataTimeColumns(dataFrameId)
    },
    setDataTimeColumns (dataFrameId) {
      getDateTimeColumns(dataFrameId)
        .then(columnList => {
          this.$emit('update:dateTimeColumn', columnList.find(column => column.isDefault))
        })
    },
    fetchDataColumnList (dataFrameId) {
      this.isFetchingDataColumnInfo = true
      const hasFeatureColumn = true
      // 過濾掉分群欄位
      const hasBlockClustering = false
      const hasAliasLimit = false
      getDataFrameColumnInfoById(dataFrameId, hasFeatureColumn, hasAliasLimit, hasBlockClustering)
        .then(response => {
          this.dataColumnOptionList = response
            .map(column => ({
              ...column,
              name: `${column.primaryAlias || column.name}（${column.statsType}）`,
              value: column.id,
              originalName: column.primaryAlias || column.name,
              dcId: column.id
            }))
        })
        .finally(() => {
          this.isFetchingDataColumnInfo = false
        })
    },
    getModelList () {
      this.isFetchingModelInfo = true
      // 參數最佳化組件目前只能支援輸出是單一一個 numeric 的模型
      const enableParamOpt = this.currentComponentType === 'parameters-optimized-simulator'
      getModelList(this.getCurrentGroupId, 0, 99999, enableParamOpt)
        .then(modelList => {
          this.modelOptionList = modelList.models.map(model => ({
            ...model,
            name: `${model.name} (ID: ${model.id})`,
            type: model.modelOrigin === 'UPLOADED_MULTI_DATASET' ? 'dataFrame' : 'column',
            value: model.id
          }))
        })
        .finally(() => {
          this.$emit('update:isLoading', false)
          this.isFetchingModelInfo = false
        })
    },
    updateModelSetting (selectedId) {
      this.$emit('update:isLoading', true)

      // 如果 input 取得失敗，外層不應該能加元件
      // 取得失敗時，input 設定區塊還不會顯示，因此不會有欄位驗證能擋
      this.$emit('update:isFailed', false)

      // 清空原資料
      this.modelSetting.inputList = []
      this.modelSetting.type = this.modelOptionList.find((model) => model.value === this.modelSetting.modelId).type
      this.modelOuptputList = []

      this.fetchModelInfo(selectedId)
    },
    fetchModelInfo (selectedId) {
      this.isFetchingModelInfo = true
      this.$emit('update:isLoading', true)
      getModelInfo(selectedId)
        .then(({ ioArgs: { input, output } }) => {
          this.modelInputList = input
          this.modelSetting.inputList = input.map((input, index) => ({
            id: null,
            ...(this.modelSetting.inputList[index] && this.modelSetting.inputList[index])
          }))
          // 初次進來時才執行
          if (this.isMultiDatasetModel && !this.modelSetting.inputList[0].paramType) {
            this.modelSetting.inputList.forEach((input, index) => {
              input.paramType = index === 0 ? 'SIMULATE' : 'FIXED'
              input.dataSourceId = null
              input.dataFrameName = this.modelInputList[index].modelDataFrameName
            })
          }
          this.modelOuptputList = output
        })
        .catch(() => { this.$emit('update:isFailed', true) })
        .finally(() => {
          this.$emit('update:isLoading', false)
          this.isFetchingModelInfo = false
        })
    },
    resetInputList () {
      this.modelSetting.inputList = this.modelSetting.inputList.map(input => ({
        id: null
      }))
    },
    getDataColumnOptionList (statesType) {
      return this.dataColumnOptionList.filter(option => option.statsType === statesType)
    },
    handleModelInputChange (index, id) {
      const selectedOption = this.dataColumnOptionList.find(column => column.id === id)
      this.modelSetting.inputList[index] = JSON.parse(JSON.stringify(selectedOption))
    },
    handleParamTypeChange (index, paramType) {
      this.modelSetting.inputList[index].paramType = paramType
    },
    handleDataChange (index, event) {
      return {
        dataSource: () => {
          this.modelSetting.inputList[index].dataSourceId = event.id
          this.modelSetting.inputList[index].id = null
        },
        dataFrame: () => {
          this.modelSetting.inputList[index].id = event.id
        }
      }[event.type]()
    }
  }
}
</script>

<style lang="scss" scoped>
.simulator-setting {
  padding: 24px 17px;

  .setting {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &__title {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__content {
      background: #1c292b;
      border-radius: 12px;
      display: flex;
      flex-wrap: wrap;
      padding: 25px 17px;

      ::v-deep .spinner-block {
        padding: 0;
      }
    }

    ::v-deep .input-field {
      align-items: center;
      display: flex;

      &:not(:last-of-type) {
        margin-right: 58px;
      }

      &__label {
        color: #aaa;
        font-size: 14px;
        width: 90px;
      }

      &__input-box {
        position: relative;

        &:not(:first-of-type) {
          margin-left: 58px;
        }
      }

      &__type {
        margin-left: 16px;
        font-size: 14px;
        color: #CCCCCC;
      }

      &__input {
        border-color: #fff;
        font-size: 14px;
        height: 40px;
        width: 232px;

        &::placeholder {
          color: #aaa;
        }
      }

      &__select {
        border-bottom: 1px solid #fff;
        &-annotation {
          float: right;
          font-size: 12px;
          color: #A7A7A7
        }
      }

      &__display {
        font-size: 14px;
      }

      .error-text {
        position: absolute;
      }

      .el-input__inner {
        font-size: 16px;
        padding-left: 0;

        &::placeholder {
          color: #aaa;
        }
      }

      .el-input {
        &.is-disabled {
          .el-input__inner {
            background-color: transparent;
          }
        }
      }
    }

    .long-select {
      width: 300px;
    }

    .short-select {
      width: 180px;
      margin-right: 60px;
    }
  }

  ::v-deep .list {
    justify-content: center;

    &__header {
      display: flex;
      margin-bottom: 9px;
      padding: 0 16px;
      width: 100%;
    }

    &__title {
      color: #aaa;
      font-size: 14px;
      min-width: 240px;
      &:first-of-type {
        margin-left: 30px;
      }
    }

    &__items {
      display: flex;
      background: #141c1d;
      border-radius: 8px;
      padding: 16px;
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 8px;
      }

      .icon {
        width: 30px;
      }

      &--container {
        display: flex;
        align-items: center;
      }

      &--params {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 30px;
        width: 200px;
      }
    }
  }

  ::v-deep .el-input.is-disabled {
    .el-input__inner {
      color: #AAAAAA;
      border-bottom: 1px solid #AAAAAA;

      &::placeholder {
        color: #AAAAAA;
      }
    }

    .el-icon-arrow-up:before {
      color: #AAAAAA;
    }
  }
}
</style>
