/**
 * 搭配 vue-layout-grid 使用，將 layout 轉為 array
 * @param {Array} layout - vue-layout-grid 的 layout
 */
export function generateLayoutMap (layout) {
  const leftSpace = []

  layout.forEach((item, i) => {
    for (let i = 0; i < item.h; i++) {
      const rowIndex = item.y + i
      if (leftSpace[rowIndex]) {
        // console.log(`x: ${item.x}, y: ${rowIndex}`)
        for (let j = 0; j < item.w; j++) {
          leftSpace[rowIndex][item.x + j] = 1
        }
      } else {
        leftSpace[rowIndex] = []
        for (let j = 0; j < 12; j++) {
          const isFilled = j >= item.x && j < item.x + item.w
          leftSpace[rowIndex].push(isFilled ? 1 : 0)
        }
      }
    }
  })

  return leftSpace
}

/**
 * 搭配 vue-layout-grid 使用，計算新元件從左而右、從上而下的找到適合的位置
 * @param {Array} leftSpace - generateLayoutMap() 產出的 array map
 * @param {Number} w - 新元件的寬
 * @param {Number} h - 新元件的高
 * @returns - {x, y}
 */
export function layoutLeftSpace (leftSpace, w, h) {
  // check if all empty
  if (leftSpace.length === 0) return { x: 0, y: 0 }

  // find position
  const position = {
    x: null,
    y: null
  }

  // loop row
  leftSpace.every((row, currentRowIndex) => {
    let isFindSpace = false

    // find empty index
    const emptyIndex = row
      .map((filled, i) => (!filled ? i : null))
      .filter((val) => val !== null)

    // loop empty index in row
    emptyIndex.every((colStartIndex) => {
      let isMatch = true

      // check space is available
      for (let colOffset = 0; colOffset < w; colOffset++) {
        const colIndex = colStartIndex + colOffset
        for (let rowOffset = 0; rowOffset < h; rowOffset++) {
          const rowIndex = currentRowIndex + rowOffset
          if (leftSpace[rowIndex] && leftSpace[rowIndex][colIndex] !== 0) {
            isMatch = false
            break
          }
        }
      }

      // if match, set position & break
      if (isMatch) {
        position.x = colStartIndex
        position.y = currentRowIndex
        isFindSpace = true
        return false
      }
      return true
    })

    return !isFindSpace
  })

  return position.x === null && position.y === null
    ? { x: 0, y: leftSpace.length }
    : position
}
