<template>
  <filter-status-badge
    :badge-text="badgeText"
    :active="active"
    :icon="badgeIcon"
  >
    <div
      class="filter-status-content"
      slot="content"
    >
      <!-- <div class="content-block">
        <div class="bold">
          {{ $t('miniApp.filterBadgeStatus') }}:
        </div>
        <div>{{ statusText }}</div>
      </div> -->
      <div
        class="content-block"
        v-if="filterSourceType"
      >
        <div class="bold">
          {{ $t('miniApp.filterBadgeSourceType') }}:
        </div>
        <div>{{ filterSourceType }}</div>
      </div>
      <div
        v-if="contentText"
        class="content-block"
      >
        <div class="bold">
          {{ $t('miniApp.filterBadgeContent') }}:
        </div>
        <div>{{ contentText }}</div>
      </div>
    </div>
  </filter-status-badge>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import FilterStatusBadge from './FilterStatusBadge'
import { useI18n } from '@/utils/composable/i18n'

export default defineComponent({
  name: 'SingleFilterStatusBadge',
  components: {
    FilterStatusBadge
  },
  props: {
    active: {
      type: Boolean,
      default: () => false
    },
    filter: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const { t } = useI18n()

    // ----- general -----
    const columnName = props.filter.column.name

    const filterType = computed(() => {
      return props.filter.column.type
    })

    const statusText = computed(() => {
      return props.active
        ? t('miniApp.filterBadgeStatusActive')
        : t('miniApp.filterBadgeStatusDeactive')
    })

    const filterSourceType = computed(() => {
      if (!props.filter?.tagType) return null

      if (props.filter.tagType === 'filter') {
        return t('miniApp.filterCondition')
      }
      if (props.filter.tagType === 'control') {
        return t('miniApp.panelControl')
      }
      return null
    })

    // ----- content Text -----
    const contentRules = [
      {
        match: () => ['CATEGORY', 'BOOLEAN'].includes(filterType.value),
        getText: () => {
          const numOfDataValues = props.filter.optionValues.length
          const isValuesEmpty = numOfDataValues === 0
          return {
            title: isValuesEmpty ? columnName : `${columnName} (${numOfDataValues})`,
            content: isValuesEmpty ? '' : props.filter.optionValues.join(', ')
          }
        }
      },
      {
        match: () => filterType.value === 'NUMERIC',
        getText: () => {
          const isRangeExist = (props.filter.valueRange.start === null || props.filter.valueRange.end === null)
          return {
            title: isRangeExist
              ? columnName
              : `${columnName} (${props.filter.valueRange.start} - ${props.filter.valueRange.end})`,
            content: isRangeExist
              ? ''
              : `${props.filter.valueRange.start} - ${props.filter.valueRange.end}`
          }
        }
      },
      {
        match: () => filterType.value === 'DATETIME',
        getText: () => {
          const isRangeExist = (props.filter.valueRange.start === null || props.filter.valueRange.end === null)
          const getDateStrng = (fullDateString) => fullDateString.replace(/-/g, '/')

          return {
            title: isRangeExist
              ? columnName
              : `${columnName} (${getDateStrng(props.filter.valueRange.start)} - ${getDateStrng(props.filter.valueRange.end)})`,
            content: isRangeExist
              ? ''
              : `${getDateStrng(props.filter.valueRange.start)} - ${getDateStrng(props.filter.valueRange.end)}`
          }
        }
      },
      {
        match: () => filterType.value === 'RELATIVEDATETIME',
        getText: () => {
          const numOfDataValues = props.filter.optionValues.length
          const isValuesEmpty = numOfDataValues === 0
          return {
            title: isValuesEmpty ? columnName : `${columnName} (${t('miniApp.' + props.filter.optionValues[0])})`,
            content: isValuesEmpty ? '' : t('miniApp.' + props.filter.optionValues[0])
          }
        }
      },
      {
        match: () => filterType.value === 'CUSTOMDATETIME',
        getText: () => {
          const numOfDataValues = props.filter.optionValues.length
          const isValuesEmpty = numOfDataValues === 0
          const time = `${props.filter.optionValues[0].timeValue} ${t(`miniApp.timeUnit.${props.filter.optionValues[0].timeUnit}`)}`
          return {
            title: isValuesEmpty ? columnName : `${columnName} (${t('miniApp.timeUnit.last', { time })})`,
            content: isValuesEmpty ? '' : t('miniApp.timeUnit.last', { time })
          }
        }
      }
    ]

    const contentText = computed(() => {
      const matchedRule = contentRules.find((rule) => rule.match())

      return matchedRule === null
        ? ''
        : matchedRule.getText()?.content
    })

    // ----- badge -----
    const badgeText = computed(() => {
      const matchedRule = contentRules.find((rule) => rule.match())

      return matchedRule === null
        ? columnName
        : matchedRule.getText()?.title
    })

    const badgeIcon = computed(() => {
      if (!props.filter?.tagType) return null

      if (props.filter.tagType === 'filter') {
        return 'filter-outline'
      }
      if (props.filter.tagType === 'control') {
        return 'adjuster'
      }
      return null
    })

    return {
      statusText,
      contentText,
      filterSourceType,
      badgeText,
      badgeIcon
    }
  }
})
</script>

<style lang="scss" scoped>
.filter-status-content {
  min-width: 200px;
  padding: 12px;

  .content-block {
    font-size: 12px;
    line-height: 20px;
    padding: 4px 0;

    .bold {
      font-weight: 600;
    }
  }
}
</style>
