<template>
  <div class="create-filter-dialog full-page-dialog">
    <div class="dialog-container">
      <div class="dialog-title">
        {{ title }}
        <a
          href="javascript:void(0)"
          class="close-btn"
          @click="closeDialog"
        ><svg-icon icon-class="close" /></a>
      </div>
      <template>
        <div class="setting-block">
          <div class="input-field">
            <label class="input-field__label">{{ $t('miniApp.dataSource') }}</label>
            <div class="input-field__input">
              <default-select
                v-validate="'required'"
                :option-list="dataSourceOptionList"
                :placeholder="$t('miniApp.chooseDataSource')"
                :is-disabled="isProcessing || isLoading"
                v-model="selectedBasicInfo.dataSourceId"
                filterable
                class="input-field__select"
                name="dataSourceId"
              />
              <div
                v-show="errors.has('dataSourceId')"
                class="error-text"
              >
                {{ errors.first('dataSourceId') }}
              </div>
            </div>
          </div>
          <div class="input-field">
            <label class="input-field__label">{{ $t('miniApp.dataFrame') }}</label>
            <div class="input-field__input">
              <default-select
                v-validate="'required'"
                :option-list="dataFrameOptionList"
                :placeholder="$t('miniApp.chooseDataFrame')"
                :is-disabled="isProcessing || isLoading"
                v-model="selectedBasicInfo.dataFrameId"
                filterable
                class="input-field__select"
                name="dataFrameId"
              />
              <div
                v-show="errors.has('dataFrameId')"
                class="error-text"
              >
                {{ errors.first('dataFrameId') }}
              </div>
            </div>
          </div>
          <template v-if="selectedBasicInfo.dataFrameId">
            <template v-if="!isYAxisController">
              <div class="card__wrapper">
                <div class="card__wrapper-title">
                  <div class="card__wrapper-title--left">
                    {{ $t('miniApp.filterConditions') }}
                  </div>
                  <div
                    v-if="isHierarchicalFilter"
                    class="card__wrapper-title--right"
                  >
                    *{{ $t('miniApp.conditionRelations') }}
                  </div>
                </div>
                <template v-for="(filter, index) in filterInfoList">
                  <single-column-card
                    :column-info="filter"
                    :data-column-option-list="dataColumnOptionList"
                    :column-list="filterInfoList"
                    :key="filter.uuid"
                    :name="index.toString()"
                    :is-loading="isLoading"
                    :is-processing="isProcessing"
                    @updateDataColumn="updateDataColumn($event, filter.uuid)"
                    @remove="removeColumnCard"
                  />
                </template>
              </div>
              <button
                class="btn btn-m btn-outline"
                @click="addNewColumnCard()"
              >
                <svg-icon
                  icon-class="plus"
                  class="icon"
                />{{ $t('button.add') }}
              </button>
            </template>
            <template v-else>
              <div class="input-field">
                <label class="input-field__label">{{ $t('miniApp.yAxisControllerColumns') }}</label>
                <div class="input-field__input">
                  <default-multi-select
                    v-validate="'required'"
                    :value="yAxisControllerList"
                    :option-list="dataColumnOptionList"
                    :placeholder="$t('batchLoad.chooseColumn')"
                    :is-disabled="isProcessing || isLoading"
                    filterable
                    multiple
                    class="input-field__multi-select"
                    name="yAxisController"
                    @input="updateYAxisControllerList"
                  />
                  <div
                    v-show="errors.has('yAxisController')"
                    class="error-text"
                  >
                    {{ errors.first('yAxisController') }}
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
        <div class="button__block">
          <button
            class="btn btn-outline"
            @click="closeDialog"
          >
            {{ $t('button.cancel') }}
          </button>
          <button
            :disabled="isProcessing"
            class="btn btn-default"
            @click="createFilter"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DefaultSelect from '@/components/select/DefaultSelect'
import DefaultMultiSelect from '@/components/select/DefaultMultiSelect'
import InputBlock from '@/components/InputBlock'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import SingleColumnCard from '@/components/card/SingleColumnCard'
import { mapGetters } from 'vuex'
import {
  getDataFrameById,
  getDataFrameColumnInfoById
} from '@/API/DataSource'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'CreateFilterDialog',
  inject: ['$validator'],
  components: {
    DefaultSelect,
    InputBlock,
    DefaultMultiSelect,
    EmptyInfoBlock,
    SingleColumnCard
  },
  props: {
    isProcessing: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    filterType: {
      type: String,
      validator: (value) => { return ['control', 'filter', 'YAxisControl'].includes(value) },
      default: 'filter'
    },
    isHierarchicalFilter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filterInfoList: [],
      selectedBasicInfo: {
        dataSourceId: null,
        dataSourceName: null,
        dataFrameId: null,
        dataFrameName: null
      },
      filterTemplate: {
        // TODO: 抽出 schema
        source: {
          dataSourceId: null,
          dataFrameId: null
        },
        column: {
          columnId: null,
          name: null,
          type: null
        }
      },
      isLoading: false,
      dataFrameOptionList: [],
      dataColumnOptionList: [],
      yAxisControllerList: []
    }
  },
  computed: {
    ...mapGetters('dataSource', ['dataSourceList']),
    dataSourceOptionList () {
      return this.dataSourceList.reduce((acc, cur) => {
        if (cur.state !== 'ENABLE' || cur.enableDataFrameCount < 1) return acc
        acc.push({
          name: cur.name,
          value: cur.id
        })
        return acc
      }, [])
    },
    isFilter () {
      return this.filterType === 'filter'
    },
    isController () {
      return this.filterType === 'control'
    },
    isYAxisController () {
      return this.filterType === 'YAxisControl'
    }
  },
  watch: {
    'selectedBasicInfo.dataSourceId' (id) {
      this.fetchDataFrameList(id)
    },
    'selectedBasicInfo.dataFrameId' (id) {
      this.fetchDataColumnList(id)
    }
  },
  methods: {
    async fetchDataFrameList (dataSourceId) {
      this.isLoading = true

      // 清空原資料
      this.dataFrameOptionList = []
      this.filterInfoList = []
      this.selectedBasicInfo.dataFrameId = null

      // 更新 datasource 名稱
      this.selectedBasicInfo.dataSourceName = this.dataSourceOptionList.find(datasource => datasource.value === dataSourceId).name

      // 更新 dataframe 列表
      try {
        const res = await getDataFrameById(this.selectedBasicInfo.dataSourceId, false)
        this.dataFrameOptionList = res.map(dataFrame => ({
          name: dataFrame.primaryAlias,
          value: dataFrame.id
        }))
      } finally {
        this.isLoading = false
      }
    },
    async fetchDataColumnList (dataFrameId) {
      this.isLoading = true

      // 清空原資料
      this.filterInfoList = []
      this.dataColumnOptionList = []
      this.yAxisControllerList = []

      // 補 dataframe 名稱
      this.selectedBasicInfo.dataFrameName = this.dataFrameOptionList.find(dataFrame => dataFrame.value === dataFrameId).name

      // 過濾掉分群欄位
      try {
        const res = await getDataFrameColumnInfoById(dataFrameId, true, false, false)
        this.dataColumnOptionList = res.reduce((acc, cur) => {
          // 篩選不同情境下顯示的選項
          if (this.isUnavailableOption(cur)) return acc
          acc.push({
            ...cur,
            name: `${cur.primaryAlias || cur.name}（${cur.statsType}）`,
            value: cur.id,
            originalName: cur.primaryAlias || cur.name
          })
          return acc
        }, [])
      } finally {
        this.isLoading = false
      }

      // 預先新增一個欄位選擇器
      if (!this.isYAxisController) this.addNewColumnCard()
    },
    isUnavailableOption (option) {
      return (this.isController && option.statsType !== 'CATEGORY') ||
        (this.isYAxisController && option.statsType !== 'NUMERIC')
    },
    addNewColumnCard () {
      this.filterInfoList.push({
        uuid: uuidv4(),
        ...JSON.parse(JSON.stringify(this.filterTemplate))
      })
    },
    removeColumnCard (cardId) {
      this.filterInfoList = this.filterInfoList.filter(filter => filter.uuid !== cardId)
    },
    closeDialog () {
      this.$emit('closeDialog')
    },
    updateDataColumn (columnId, filterId) {
      const filterInfo = this.filterInfoList.find(filter => filter.uuid === filterId)

      const selectColumnInfo = this.dataColumnOptionList.find(column => column.id === columnId)

      filterInfo.source.dataSourceId = this.selectedBasicInfo.dataSourceId
      filterInfo.source.dataFrameId = this.selectedBasicInfo.dataFrameId
      filterInfo.column.type = selectColumnInfo.statsType
      filterInfo.column.name = selectColumnInfo.originalName
      filterInfo.column.columnId = selectColumnInfo.id

      if (['RELATIVEDATETIME', 'BOOLEAN', 'CATEGORY'].includes(selectColumnInfo.statsType)) {
        filterInfo.optionValues = []
      } else if (['NUMERIC', 'DATETIME'].includes(selectColumnInfo.statsType)) {
        filterInfo.valueRange = {
          end: null,
          start: null
        }
      }
    },
    createFilter () {
      this.$validator.validateAll().then(isValidate => {
        if (!isValidate) return
        if (this.isYAxisController) {
          this.$emit('filterCreated', this.filterInfoList)
        } else if (this.isHierarchicalFilter) {
          this.$emit('filterCreated', [this.filterInfoList])
        } else {
          this.$emit('filterCreated', this.filterInfoList.map(filter => ([filter])))
        }
      })
    },
    updateYAxisControllerList (columnIdList) {
      this.yAxisControllerList = columnIdList
      this.filterInfoList = {
        activeColumnId: columnIdList.length > 0 ? columnIdList[0] : null,
        options: columnIdList.map(id => {
          const dataColumnInfo = JSON.parse(JSON.stringify(this.dataColumnOptionList.find(column => column.id === id)))
          return {
            column: {
              columnId: dataColumnInfo.id,
              name: dataColumnInfo.originalName,
              type: dataColumnInfo.statsType
            },
            source: {
              dataSourceId: this.selectedBasicInfo.dataSourceId,
              dataFrameId: this.selectedBasicInfo.dataFrameId
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-filter-dialog {
  &.full-page-dialog {
    .dialog-container {
      width: 40%;
    }
  }

  .dialog {
    &__sub-title {
      font-size: 14px;
      margin-bottom: 12px;
    }
  }

  .setting-block {
    background-color: rgba(50, 58, 58, 0.95);
    border-radius: 5px;
    margin-bottom: 12px;
    padding: 24px;
    position: relative;

    &__switch {
      position: absolute;
      right: 24px;
      top: 24px;

      ::v-deep .el-switch__label {
        color: #ccc;
      }

      ::v-deep .el-switch__core {
        border: unset;
        height: 10px;

        &::after {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 16px;
    }

    ::v-deep .input-field {
      display: flex;
      flex-direction: column;

      &:not(:last-of-type) {
        margin-bottom: 24px;
      }

      &__multi-select {
        width: 100%;
      }

      &__label {
        color: #ccc;
        font-size: 14px;
      }

      &__select,
      &__multi-select {
        border-bottom: 1px solid #fff;
      }

      &__input-wrapper {
        margin-top: 8px;
      }

      .deletable-checkbox {
        align-items: center;
        display: flex;
        width: fit-content;

        .checkbox-label {
          margin-right: 12px;
        }
      }

      ::v-deep .el-input__inner {
        font-size: 14px;
        padding-left: 0;

        &::placeholder {
          color: #aaa;
        }
      }

      ::v-deep .el-input {
        &.is-disabled {
          .el-input__inner {
            background-color: transparent;
          }
        }
      }
    }

    .cron-time {
      display: flex;
      flex-direction: row;
      height: 360px;
      margin-top: 37px;

      &__setting {
        margin-right: 16px;
        width: 50%;
      }

      &__input {
        margin-bottom: 37px;

        >>> .input-block .placeholder {
          color: #ccc;
        }
      }

      .cron-info {
        background: rgba(72, 84, 84, 0.95);
        border-radius: 8px;
        overflow-y: scroll;
        padding: 16px;
        width: 48%;

        ::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &__block {
          margin-bottom: 16px;

          ul {
            margin-block-end: 16px;
            margin-block-start: 6px;
            padding-inline-start: 20px !important;
          }
        }

        &__title,
        &__list {
          color: #ccc;
          font-size: 14px;
          line-height: 20px;
        }

        &__title {
          color: $theme-color-white;
        }

        &__list {
          &:not(:last-child) {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  .button {
    &__block {
      display: flex;
      justify-content: flex-end;

      .btn:not(:last-child) {
        margin-right: 20px;
      }
    }
  }

  .input-radio-group {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 40px;
    }

    &:last-of-type {
      margin-right: 16px;
    }

    .input-radio-label {
      line-height: 40px;
    }
  }

  .card {
    &__wrapper-title {
      display: flex;
      justify-content: space-between;

      &--left {
        color: #ccc;
        font-size: 14px;
        line-height: 25px;
        margin-bottom: 15px;
      }

      &--right {
        color: #fff;
        font-size: 12px;
        line-height: 25px;
      }
    }
  }

  ::v-deep .sy-multi-select.theme-dark {
    .el-tag {
      border-color: #2ad2e2;
      color: #2ad2e2;
    }

    .el-tag__close.el-icon-close {
      background-color: #2ad2e2;
    }
  }
}
</style>
