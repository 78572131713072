<template>
  <ul class="list">
    <spinner
      v-if="isLoading"
      class="list__spinner"
      size="50"
    />
    <template v-else-if="!setting.activate">
      <div class="empty-text">
        {{ $t('alert.alertNotActivate') }}
      </div>
    </template>
    <template v-else-if="warningLogs.length > 0">
      <li
        v-for="(log, index) in warningLogs"
        :key="index"
        :class="log.relatedDashboardId ? 'is-linkable' : ''"
        class="list__item"
        @click="log.relatedDashboardId ? warningLogTriggered(log) : null"
      >
        <div class="list__item--left">
          <svg-icon
            icon-class="alert"
            class="list__item-icon"
          />
        </div>
        <div class="list__item--right">
          <div class="list__item-title">
            {{ log.conditionName }}
          </div>
          <div
            class="list__item-sub-title"
            v-html="log.conditionMetMessage"
          />
          <div class="list__item-description">
            {{ log.createDate | convertTimeStamp }}
          </div>
        </div>
      </li>
    </template>
    <template v-else>
      <div class="empty-text">
        {{ $t('alert.emptyLogs') }}
      </div>
    </template>
  </ul>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, ref } from '@vue/composition-api'
import { getAlertLogs } from '@/API/Alert'
import { getMiniAppConditions } from '@/API/MiniApp'
import { useMapGetters } from '@/utils/composable/vuex'

export default defineComponent({
  name: 'MonitorWarningList',
  props: {
    setting: {
      type: Object,
      default: () => ({})
    },
    isEditMode: {
      type: Boolean,
      default: false
    },
    appId: {
      type: Number,
      default: null
    }
  },
  setup (props, { emit }) {
    // ----- loading -----
    const isLoading = ref(false)
    onMounted(() => {
      isLoading.value = true
    })

    // ----- refresh -----
    const appActiveConditions = ref([])
    const warningLogs = ref([])
    const autoRefreshFunction = ref(null)
    const { getCurrentGroupId } = useMapGetters('userManagement', ['getCurrentGroupId'])

    const convertRefreshFrequency = (cronTab) => {
      switch (cronTab) {
        case '* * * * *':
          return 60 * 1000
        case '*/2 * * * *':
          return 2 * 60 * 1000
        case '*/5 * * * *':
          return 5 * 60 * 1000
        case '*/15 * * * *':
          return 15 * 60 * 1000
        case '*/30 * * * *':
        default:
          return 30 * 60 * 1000
        case '*/45 * * * *':
          return 45 * 60 * 1000
        case '0 * * * *':
          return 60 * 60 * 1000
        case '0 0 * * *':
          return 24 * 60 * 60 * 1000
        case '0 0 * * 0':
          return 7 * 24 * 60 * 1000
        case '0 0 1 * *':
          return 30 * 7 * 24 * 60 * 1000
      }
    }

    const getWarningLogs = () => {
      isLoading.value = true
      getAlertLogs({
        conditionIds: appActiveConditions.value,
        groupId: getCurrentGroupId.value,
        active: false
      })
        .then(response => {
          warningLogs.value = response.data.map(log => {
            const prevSettingCondition = props.setting.conditions.find(item => item.conditionId === log.conditionId)
            return {
              ...log,
              relatedDashboardId: prevSettingCondition ? prevSettingCondition.relatedDashboardId : null
            }
          })
        })
        .finally(() => {
          setTimeout(() => {
            isLoading.value = false
          }, 800)
        })
    }

    const setComponentRefresh = () => {
      autoRefreshFunction.value = window.setInterval(
        getWarningLogs,
        convertRefreshFrequency(props.setting.updateFrequency)
      )
    }

    const checkActivateAndFetchWarningConditions = () => {
      // 若警示未發布，則不必去處理有無condition，而是直接顯示尚未發布
      if (!props.setting.activate) return isLoading.value = false
      getMiniAppConditions(props.appId)
        .then(conditionList => {
          if (conditionList.length === 0) return isLoading.value = false
          appActiveConditions.value = conditionList?.length
            ? conditionList
              .filter((condition) => condition.active)
              .map((condition) => condition.id)
            : []
          getWarningLogs()
          setComponentRefresh()
        })
        .catch(() => isLoading.value = false)
    }

    onMounted(() => {
      checkActivateAndFetchWarningConditions()
    })

    onBeforeUnmount(() => {
      window.clearInterval(autoRefreshFunction.value)
    })

    const warningLogTriggered = ({ relatedDashboardId, monitoredData, monitoredDateRange }) => {
      emit('warningLogTriggered', {
        relatedDashboardId: relatedDashboardId,
        rowData: {
          controlList: monitoredData.filter(item => item.statsType === 'CATEGORY'),
          filterList: [
            ...(monitoredDateRange.length > 0 ? [{
              filterList: {
                columnId: monitoredDateRange[0].dataColumnId,
                columnName: monitoredDateRange[0].displayName,
                dataType: monitoredDateRange[0].dataType,
                end: monitoredDateRange[0].end,
                start: monitoredDateRange[0].start,
                statsType: monitoredDateRange[0].statsType
              }
            }] : [])
          ]
        }
      })
    }

    return {
      isLoading,
      warningLogs,
      warningLogTriggered
    }
  }
  // NOTICE: 可能已經棄用
  // methods: {
  //   logMonitoredData (rowData) {
  //     return rowData.reduce((acc, cur) => acc.concat(`${cur.displayName}: ${cur.datum[0]}<br>`), '')
  //   }
  // }
})
</script>

<style lang="scss" scoped>
.list {
  flex: 1;
  margin: 0;
  overflow: auto;
  padding: 0;
  width: 100%;

  &__item {
    background: #252f2f;
    border-radius: 5px;
    display: flex;
    min-height: 62px;
    padding: 12px;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    &.is-linkable {
      cursor: pointer;
    }

    &--left {
      align-items: center;
      display: flex;
      margin-right: 11px;
    }

    &--right {
      flex: 1;
    }

    &-icon {
      color: #ff5c46;
      font-size: 25px;
    }

    &-title {
      color: #fff;
      font-size: 14px;
      margin-bottom: 5px;
    }

    &-sub-title {
      color: #fff;
      font-size: 12px;
      margin-bottom: 5px;
    }

    &-description {
      color: #a7a7a7;
      font-size: 12px;
    }
  }

  .empty-text {
    align-items: center;
    color: #999;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
  }
}
</style>
