<template>
  <div :class="['custom-radio__group', ]">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="custom-radio__option"
    >
      <input
        :id="`radio-${option.value}`"
        :value="option.value"
        :checked="option.value === value"
        class="input-radio"
        type="radio"
        @change="handleChange(option.value)"
      >
      <label
        :for="`radio-${option.value}`"
        class="input-radio-label"
      >
        {{ `${option.name}` }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomRadio',
  model: {
    prop: 'value',
    event: 'update'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String || Number,
      default: ''
    }
  },
  methods: {
    handleChange (val) {
      this.$emit('update', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-radio {
  &__group {
    display: flex;
    flex-wrap: wrap;
  }
  &__option {
    font-size: 14px;
    margin-right: 0;
    margin-bottom: 8px;
    width: 50%;
    color: #fff;
  }
}
</style>
