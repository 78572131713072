<template>
  <div
    class="custom-histogram-bin-setting"
  >
    <div class="custom-histogram-bin-setting__title">
      {{ $t('warRoom.displaySetting') }}
    </div>
    <div class="custom-histogram-bin-setting__content">
      <div class="custom-histogram-bin-setting__option">
        <p class="custom-histogram-bin-setting__option-title">
          {{ $t('editing.histogramBinSize') }}
        </p>
        <InputBlock
          class="custom-histogram-bin-setting__option"
          v-validate="'decimal|min_value:0'"
          v-model.number.trim="binSize"
          :placeholder="$t('warRoom.pleaseEnterValue')"
          type="number"
          name="binSize"
        />
        <button
          class="custom-histogram-bin-setting__option-button"
          @click.stop="handleSubmit"
        >
          {{ $t('button.change') }}
        </button>
        <button
          class="custom-histogram-bin-setting__option-button -secondary"
          @click.stop="handleClear"
        >
          {{ $t('common.clear') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import InputBlock from '@/components/InputBlock'

export default defineComponent({
  name: 'CustomHistogramBinSetting',
  inject: ['$validator'],
  props: {
    value: {
      type: [Number, String],
      default: null
    }
  },
  components: {
    InputBlock
  },
  setup (props, { emit }) {
    const binSize = ref(props.value ? props.value : null)

    async function handleSubmit () {
      const validator = await this.$validator.validate('binSize')
      if (!validator) return
      emit('updateBinSize', binSize.value)
    }

    function handleClear () {
      binSize.value = null
      emit('updateBinSize', null)
    }

    return {
      binSize,
      handleSubmit,
      handleClear
    }
  }
})
</script>

<style lang="scss" scoped>
.custom-histogram-bin-setting {
  &__title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 8px;
  }

  &__content {
    background: #1c292b;
    border-radius: 12px;
    display: flex;
    padding: 18.5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }

  &__option {
    display: flex;
    align-items: center;
    &-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #AAAAAA;
      margin-right: 33px;
    }
    &-button {
      color: #004046;
      background: #2AD2E2;
      border-radius: 5px;
      padding: 5px 10px;
      box-shadow: none;
      border: none;
      margin-left: 16px;
      &.-secondary {
        border: 1px solid #2AD2E2;
        background: transparent;
        color: #2AD2E2;
      }
    }
  }
}
</style>
