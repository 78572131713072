<template>
  <filter-status-badge
    v-if="filters.length"
    :badge-text="badgeText"
  >
    <div
      class="filter-status-table"
      slot="content"
    >
      <el-table :data="tableData">
        <el-table-column
          prop="name"
          :label="$t('miniApp.filterBadgeTableTitle')"
          min-width="50px"
        >
          <template slot-scope="scope">
            <filter-status-badge
              :badge-text="scope.row.name"
              no-tooltip
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="filterSourceType"
          :label="$t('miniApp.filterBadgeSourceType')"
        />
        <el-table-column
          prop="text"
          :label="$t('miniApp.filterBadgeTableContent')"
        />
      </el-table>
    </div>
  </filter-status-badge>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import FilterStatusBadge from './FilterStatusBadge'
import { useI18n } from '@/utils/composable/i18n'

export default defineComponent({
  name: 'OverflowFilterStatusBadge',
  components: {
    FilterStatusBadge
  },
  props: {
    filters: {
      type: Array,
      required: true
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()

    const badgeText = computed(() => {
      return `+${props.filters.length}`
    })

    const getFilterContentText = (filter) => {
      const filterType = filter.column.type
      const rules = [
        {
          match: () => ['CATEGORY', 'BOOLEAN'].includes(filterType),
          getText: () => {
            const numOfDataValues = filter.optionValues.length
            return numOfDataValues === 0
              ? ''
              : filter.optionValues.join(', ')
          }
        },
        {
          match: () => filterType === 'NUMERIC',
          getText: () => {
            return (filter.valueRange.start === null || filter.valueRange.end === null)
              ? ''
              : `${filter.valueRange.start} - ${filter.valueRange.end}`
          }
        },
        {
          match: () => filterType === 'DATETIME',
          getText: () => {
            const getDateStrng = (fullDateString) => fullDateString.replace(/-/g, '/')
            return (filter.valueRange.start === null || filter.valueRange.end === null)
              ? ''
              : `${getDateStrng(filter.valueRange.start)} - ${getDateStrng(filter.valueRange.end)}`
          }
        },
        {
          match: () => filterType === 'RELATIVEDATETIME',
          getText: () => {
            const numOfDataValues = filter.optionValues.length
            return numOfDataValues === 0
              ? ''
              : t('miniApp.' + filter.optionValues[0])
          }
        }
      ]

      const matchedRule = rules.find((rule) => rule.match())

      return matchedRule === null
        ? ''
        : matchedRule.getText()
    }

    const checkFilterSourceType = (filter) => {
      if (!filter?.tagType) return null

      if (filter.tagType === 'filter') {
        return t('miniApp.filterCondition')
      }
      if (filter.tagType === 'control') {
        return t('miniApp.panelControl')
      }
      return null
    }

    const tableData = computed(() => {
      return props.filters.map((filter) => ({
        name: filter.column.name,
        filterSourceType: checkFilterSourceType(filter),
        text: getFilterContentText(filter) || '－'
      }))
    })

    return {
      badgeText,
      tableData
    }
  }
})
</script>

<style lang="scss" scoped>
.filter-status-table {
  max-height: 312px;
  min-width: 400px;
  overflow: auto;
  word-break: normal;

  ::v-deep .el-table th,
  ::v-deep .el-table tr,
  ::v-deep .el-table {
    background-color: transparent;
    color: #ddd;
  }

  ::v-deep .el-table td,
  ::v-deep .el-table th.is-leaf {
    border-bottom: 1px solid #52696a;
  }

  ::v-deep .el-table--border::after,
  ::v-deep .el-table--group::after,
  ::v-deep .el-table::before {
    background-color: #52696a;
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(255, 255, 255, 0.05);
  }
}
</style>
