<template>
  <div class="warning-setting">
    <nav class="warning-setting__nav">
      <div class="nav-left">
        {{ $t('alert.alertManagement') }}
        <div class="module-activate-controller">
          <el-switch
            v-model="tempWarningModuleConfig.activate"
            :width="32"
            :disabled="isLoading"
            active-color="#2AD2E2"
            inactive-color="#324B4E"
            @change="saveWarningModuleSetting"
          />
          {{ tempWarningModuleConfig.activate ? $t('miniApp.displayUnderPublishMode') : $t('miniApp.hideUnderPublishMode') }}
        </div>
      </div>
      <div class="nav-right">
        <button
          :disabled="isLoading"
          class="btn-m btn-secondary button-container__button"
          @click="isShowCreateConditionDialog = true"
        >
          {{ $t('alert.createAlertCondition') }}
        </button>
      </div>
    </nav>
    <main class="warning-setting__content">
      <div class="warning-setting__content-frequency">
        <section class="setting-block">
          {{ $t('miniApp.monitorUpdateFrequency') }}：
          <default-select
            v-model="tempWarningModuleConfig.updateFrequency"
            :is-disabled="isLoading"
            :option-list="updateFrequency"
            :placeholder="$t('miniApp.chooseUpdateFrequency')"
            class="setting__block-select"
            name="updateFrequency"
            @change="saveWarningModuleSetting"
          />
        </section>
      </div>
      <div class="warning-setting__content-condition">
        <spinner
          v-if="isLoading || isProcessing"
          :title="$t('button.download')"
          size="50"
        />
        <empty-info-block
          v-else-if="isEmpyAlertCondition"
          :msg="$t('alert.emptyCondition')"
        />
        <template v-else>
          <div class="title">
            <span class="col-enable">{{ $t('alert.enableAlertModule') }}</span>
            <span class="col-condition">{{ $t('alert.alertCondition') }}</span>
            <span class="col-relation">{{ $t('miniApp.relatedDashboard') }}</span>
            <span class="col-status">{{ $t('alert.operatingStatus') }}</span>
            <span class="col-deletion" />
          </div>
          <section
            v-for="condition in tempWarningModuleConfig.conditions"
            :key="condition.id"
            class="setting-block"
          >
            <div class="col-enable">
              <el-switch
                :disabled="condition.isDisabled"
                :value="condition.activate"
                :width="Number('32')"
                active-color="#2AD2E2"
                inactive-color="#324B4E"
                @change="toggleSingleAlertCondition(condition.id, $event)"
              />
            </div>
            <div class="col-condition">
              <div>{{ condition.name }}</div>
              <div class="datasource-info">
                <svg-icon icon-class="data-source" />{{ condition.dataSourceName }}
                <svg-icon icon-class="table" />{{ condition.dataFrameName }}
                <svg-icon :icon-class="isComponentAlerter(condition.targetType) ? 'watch-list' : 'column'" />
                {{ isComponentAlerter(condition.targetType)
                  ? $t('alert.monitoringItem', {number: condition.targetConfig.combinationCounts })
                  : condition.targetConfig.displayName }}
              </div>
              <div
                class="comparing-values"
                v-html="displayedConditionMessage(condition.targetConfig.displayName || condition.targetConfig.analysisValueType, condition.comparingValues)"
              />
              <div class="message-template">
                <span class="message-template__label">{{ $t('alert.alertLogMessage') }}:</span>
                <span class="message-template__content">{{ condition[`alertMessage${locale.split('-')[1]}`] }}</span>
                <a
                  v-if="!isComponentAlerter(condition.targetType)"
                  href="javascript:void(0)"
                  class="link message-template__edit-btn"
                  @click="openAlertConditionMessageDialog(condition)"
                >{{ $t('alert.editAlertMessage') }}</a>
              </div>
            </div>
            <div class="col-relation">
              <default-select
                v-model="condition.relatedDashboardId"
                :option-list="dashboardOptions"
                :placeholder="$t('miniApp.selectDashboard')"
                class="dashboard-select"
                @change="saveWarningModuleSetting"
              />
            </div>
            <div class="col-status">
              <template v-if="condition.activate">
                <button
                  v-if="isAllowManulTriggerAlert(condition.status)"
                  class="btn btn-outline"
                  @click="runAlert(condition.id)"
                >
                  {{ $t('button.runRightAway') }}
                </button>
                <div
                  v-else
                  class="message"
                >
                  <spinner size="14" />{{ $t('alert.operating') }}
                </div>
              </template>
            </div>
            <div class="col-deletion">
              <alert-condition-deleter
                :condition="condition"
                @deleted="fetchSettingData"
              />
            </div>
          </section>
        </template>
      </div>
    </main>
    <create-alert-condition-dialog
      v-if="isShowCreateConditionDialog"
      @close="isShowCreateConditionDialog = false"
      @created="alertConditionUpdated('CreateCondition', $event)"
    />
    <alert-condition-message-editor-dialog
      v-if="isShowEditConditionMessageDialog"
      :condition="currentEditingCondition"
      @close="isShowEditConditionMessageDialog = false"
      @done="alertConditionUpdated('EditConditionMessage', currentEditingCondition.id || null)"
    />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { manualTriggerAlert, toggleAlertCondition } from '@/API/Alert'
import DefaultSelect from '@/components/select/DefaultSelect'
import CreateAlertConditionDialog from './CreateAlertConditionDialog'
import AlertConditionDeleter from './AlertConditionDeleter'
import AlertConditionMessageEditorDialog from './AlertConditionMessageEditorDialog'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import { mapState } from 'vuex'
import { alertTargetType } from '@/utils/general'
import globalEmitter from '@/utils/globalEmitter'
import { useEventLog } from '@/utils/composable/eventLog'
import { getMiniAppConditions } from '@/API/MiniApp'

export default defineComponent({
  name: 'WarningSetting',
  components: {
    DefaultSelect,
    CreateAlertConditionDialog,
    AlertConditionMessageEditorDialog,
    AlertConditionDeleter,
    EmptyInfoBlock
  },
  props: {
    setting: {
      type: Object,
      default: () => ({})
    },
    dashboardList: {
      type: Array,
      default: () => []
    },
    appId: {
      type: Number,
      default: null
    }
  },
  setup () {
    const { appEventLog } = useEventLog()
    return {
      appEventLog
    }
  },
  data () {
    return {
      isLoading: false,
      isProcessing: false,
      tempWarningModuleConfig: {},
      currentEditingCondition: null,
      isShowCreateConditionDialog: false,
      isShowEditConditionMessageDialog: false,
      alertTargetType,
      timeoutFunction: null
    }
  },
  computed: {
    ...mapState('setting', ['locale']),
    dashboardOptions () {
      let options = []
      const defaultOption = {
        value: null,
        name: this.$t('miniApp.noRelation')
      }
      options = this.dashboardList.map(board => ({ value: board.uuid, name: board.name }))
      options.unshift(defaultOption)
      return options
    },
    updateFrequency () {
      return [
        {
          value: '* * * * *',
          name: this.$t('warRoom.everyMinute', { number: 1 })
        },
        {
          value: '*/2 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 2 })
        },
        {
          value: '*/5 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 5 })
        },
        {
          value: '*/15 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 15 })
        },
        {
          value: '*/30 * * * *',
          name: this.$t('warRoom.everyMinute', { number: 30 })
        }
      ]
    },
    isEmpyAlertCondition () {
      return this.tempWarningModuleConfig.conditions.length === 0
    }
  },
  created () {
    this.fetchSettingData()
  },
  mounted () {
    globalEmitter.on('fetchConditions', async () => {
      await this.fetchSettingData()
    })
  },
  destroyed () {
    if (this.timeoutFunction) window.clearTimeout(this.timeoutFunction)
  },
  methods: {
    fetchSettingData () {
      this.isLoading = true
      // 示警模組設定
      if (this.setting) {
        const { activate, updateFrequency } = this.setting
        this.tempWarningModuleConfig = { activate, updateFrequency, conditions: [] }
        return this.fetchAlertConditions()
          .finally(() => this.isLoading = false)
      }
    },
    fetchAlertConditions (id = this.appId) {
      if (!id) return

      window.clearTimeout(this.timeoutFunction)
      return getMiniAppConditions(id)
        .then((conditions) => {
          let latestConditions = []
          if (conditions && conditions.length > 0) {
            conditions
              .sort((a, b) => a.id - b.id)
              .forEach(condition => {
                // 尋找之前是否有針對此示警條件做過設定
                const prevConditionSetting = this.setting.conditions.find(item => item.conditionId === condition.id)

                // 若有，檢查所設定關聯看板是否還存在
                let isRelatedDashbaordExist = false
                if (prevConditionSetting) isRelatedDashbaordExist = this.dashboardList.map(board => board.uuid).includes(prevConditionSetting.relatedDashboardId)

                // 組成示警條件列表
                latestConditions.push({
                  ...condition,
                  activate: condition.active,
                  relatedDashboardId: isRelatedDashbaordExist ? prevConditionSetting.relatedDashboardId : null,
                  isDisabled: false
                })
              })
          }
          this.tempWarningModuleConfig.conditions = latestConditions

          // 如果場上有示警條件且正被使用中則設定輪詢持續取得最新狀態
          const hasEnabledAlertConditions = this.tempWarningModuleConfig.conditions.some(condition => condition.active)
          if (hasEnabledAlertConditions) {
            this.timeoutFunction = window.setTimeout(() => {
              this.fetchAlertConditions()
            }, 5000)
          }
        })
    },
    openAlertConditionMessageDialog (condition) {
      this.currentEditingCondition = { ...condition }
      this.isShowEditConditionMessageDialog = true
    },
    saveWarningModuleSetting () {
      this.$emit('update', {
        ...this.tempWarningModuleConfig,
        conditions: this.tempWarningModuleConfig.conditions.map(item => ({
          // TODO: 抽出 schema
          conditionId: item.id,
          relatedDashboardId: item.relatedDashboardId || null
        }))
      })
    },
    displayedConditionMessage (targetColumnName, comparingValues) {
      return comparingValues.reduce((acc, cur) => {
        let comparisonOperator = ''
        switch (cur.comparisonOperator) {
          case 'GREATER_THAN':
            comparisonOperator = '>'
            break
          case 'GREATER_THAN_OR_EQUAL_TO':
            comparisonOperator = '≥'
            break
          case 'LESS_THAN':
            comparisonOperator = '<'
            break
          case 'LESS_THAN_OR_EQUAL_TO':
            comparisonOperator = '≤'
            break
          case 'EQUAL':
            comparisonOperator = '='
            break
          case 'NOT_EQUAL':
            comparisonOperator = '≠'
        }
        return acc.concat(`- ${targetColumnName}${comparisonOperator}${cur.value}<br>`)
      }, '')
    },
    createAlertCondition (conditionId) {
      this.isLoading = true
      this.tempWarningModuleConfig.conditions.push({ id: conditionId })
      this.saveWarningModuleSetting()
      this.appEventLog('createCondition', {
        conditionId,
        appId: this.$route.params.mini_app_id
      })
    },
    async alertConditionUpdated (action, conditionId) {
      switch (action) {
        case 'CreateCondition':
          this.createAlertCondition(conditionId)
          break
        case 'EditConditionMessage':
          this.isShowEditConditionMessageDialog = false
          this.currentEditingCondition = null
          this.appEventLog('updateCondition', {
            conditionId,
            appId: this.$route.params.mini_app_id
          })
          break
      }
    },
    isComponentAlerter (targetType) {
      return targetType === this.alertTargetType.COMPONENT
    },
    isAllowManulTriggerAlert (status) {
      const enableList = ['Ready', 'Complete', 'Fail']
      return enableList.includes(status)
    },
    runAlert (conditionId) {
      // 先更新狀態，待下一次輪詢取得最新更新資訊
      manualTriggerAlert(conditionId)
        .then(() => {
          this.tempWarningModuleConfig.conditions = this.tempWarningModuleConfig.conditions.map(condition => {
            if (condition.id !== conditionId) return condition
            return { ...condition, status: 'Process' }
          })
        })
        .catch(() => {
          this.tempWarningModuleConfig.conditions = this.tempWarningModuleConfig.conditions.map(condition => {
            if (condition.id !== conditionId) return condition
            return { ...condition, status: 'Fail' }
          })
        })
    },
    toggleSingleAlertCondition (conditionId, isActive) {
      const condition = this.tempWarningModuleConfig.conditions.find(condition => condition.id === conditionId)
      condition.isDisabled = true
      toggleAlertCondition(conditionId, { active: isActive })
        .then(() => this.fetchAlertConditions())
        .catch(() => condition.isDisabled = false)
    }
  }
})
</script>

<style lang="scss" scoped>
.warning-setting {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-right: 20px;

    .nav-left {
      align-items: center;
      display: flex;
      font-size: 20px;
    }

    .nav-right {
      .btn {
        min-width: unset;
        padding: 5px 10px;
      }
    }

    .module-activate-controller {
      font-size: 14px;
      margin-left: 24px;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 0;

    .setting-block {
      align-items: center;
      background-color: #192323;
      border-radius: 5px;
      display: flex;
      font-size: 14px;
      margin-bottom: 20px;
      margin-right: 20px;
      padding: 24px;

      .input-radio-group {
        margin-left: 24px;
      }

      ::v-deep .sy-select {
        .el-input__inner {
          border-bottom: 1px solid #fff;
          font-size: 14px;
          padding-left: 0;
        }
      }
    }

    &-condition {
      flex: 1;
      height: 0;
      overflow: auto;
      overflow: overlay; // 讓scrollbar不佔位。for有支援此屬性的瀏覽器
      .title {
        display: flex;
        margin-right: 24px;
        padding: 0 24px 12px;
      }

      .setting-block {
        margin-bottom: 8px;
        position: relative;

        &:last-child {
          margin-bottom: 24px;
        }
      }
    }

    .col {
      &-enable {
        flex: 0 0 100px;
      }

      &-condition {
        flex: 1;
        padding-right: 16px;

        .datasource-info {
          align-items: center;
          color: #ccc;
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          list-style: 16px;
          margin: 6px 0;
          white-space: nowrap;

          .svg-icon {
            margin-right: 6px;

            &:not(:first-child) {
              margin-left: 6px;
            }
          }
        }

        .comparing-values {
          color: #999;
          font-size: 12px;
          margin-bottom: 6px;
          margin-top: 6px;

          & ~ .comparing-values {
            margin-top: 2px;
          }
        }

        .message-template {
          background-color: #101919;
          border-radius: 5px;
          font-size: 12px;
          padding: 4px 8px;
          width: fit-content;

          &__edit-btn {
            margin-left: 7.5px;
          }
        }
      }

      &-relation {
        flex: 0 0 285px;
      }

      &-status {
        flex: 0 1 160px;

        .message {
          color: #ccc;
          display: flex;
          font-size: 12px;
        }

        ::v-deep .spinner-block {
          margin-right: 7px;
          padding: 0;
        }
      }

      &-deletion {
        flex: 0 0 20px;

        .alert-condition-deleter {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
        }
      }
    }

    .empty-info-block {
      background-color: #192322;
      margin-right: 20px;
    }
  }

  &__reminding {
    background-color: rgba(255, 223, 111, 0.1);
    border-radius: 5px;
    font-size: 12px;
    line-height: 32px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding-left: 16px;
    word-break: keep-all;

    .column-lamp {
      color: $theme-color-warning;
    }
  }
}
</style>
