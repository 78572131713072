<template>
  <warning-setting
    v-if="isEditMode"
    :setting="setting"
    :dashboard-list="dashboardList"
    :app-id="appId"
    @update="$emit('update', $event)"
  />
  <warning-log
    v-else
    :setting="setting"
    :app-id="appId"
    @goToCertainDashboard="$emit('goToCertainDashboard', $event)"
  />
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { useRoute } from '@/utils/composable/router'
import WarningSetting from './WarningSetting'
import WarningLog from './WarningLog'

export default defineComponent({
  name: 'WarningModule',
  components: {
    WarningSetting,
    WarningLog
  },
  props: {
    setting: {
      type: Object,
      default: () => ({})
    },
    dashboardList: {
      type: Array,
      default: () => []
    },
    appId: {
      type: Number,
      default: null
    }
  },
  setup (props, { emit }) {
    const route = useRoute()
    const openDashboard = (dashboardId) => {
      emit('openDashboard', dashboardId)
    }

    const isEditMode = computed(() => {
      return route.query.mode && route.query.mode === 'edit'
    })

    return {
      openDashboard,
      isEditMode
    }
  }
})
</script>

<style lang="scss" scoped>
</style>
