/**
 * 上下兼容
 * miniApp 內某些舊資料的 segmentation 資訊短缺，得重新取
 * 這邊的 segmentation 來自於 askQuestion 的 response
 */
export function hasCorrectSegmentation (segmentation) {
  if (!segmentation) return false
  if (!Array.isArray(segmentation.sentence)) return false
  // 如果是從 componentList 取得的 segmentation，sentence 物件內的 @type 全都是 Token
  return segmentation.sentence[0]['@type'] !== 'Token'
}
