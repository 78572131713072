<template>
  <div
    :class="{ 'simulator__predict': isModelPredict }"
    class="simulator"
  >
    <spinner
      v-if="isLoading"
    />
    <empty-info-block
      v-else-if="isFetchInputFailed"
      :msg="failedMessage || $t('message.systemIsError')"
    />
    <section
      v-show="!isLoading && !isFetchInputFailed"
      class="simulator__content"
    >
      <form
        :data-vv-scope="`params-optimization-${simulatorId}`"
        class="simulator__setting-container"
        @submit.prevent="simulate"
      >
        <div class="simulator__setting-container--top">
          <div class="simulator__setting">
            <div class="simulator__setting-title">
              {{ $t('miniApp.inputParamsCriteria') }}
            </div>
            <div class="simulator__setting-content">
              <parameters-optimized-simulator-input
                v-for="(columnInfo, index) in modelInfo"
                :is-processing="isSimulating"
                :restrictions="restrictions"
                :column-info="columnInfo"
                :model-id="modelSetting.modelId"
                :key="index + '-' + columnInfo.columnId"
                :simulator-id="simulatorId"
                class="simulator__setting-input"
                @done="updateColumnInfoState(index)"
                @failed="handleFetchInputFailed"
              />
            </div>
          </div>
          <div class="simulator__setting">
            <div class="simulator__setting-title">
              {{ $t('miniApp.outputParamsCriteria') }}
            </div>
            <div class="simulator__setting-content">
              <parameters-optimized-simulator-output
                v-for="(output, index) in outputInfo.criteria"
                :is-processing="isSimulating"
                :criteria-info="output"
                :key="index + '-' + output.modelColumnName"
                setting-type="EXPECTATION"
                class="simulator__setting-input"
              />
            </div>
          </div>
          <div class="simulator__setting">
            <div class="simulator__setting-title">
              {{ $t('miniApp.outputResultSetting') }}
            </div>
            <div class="simulator__setting-content">
              <parameters-optimized-simulator-output
                :model-type="modelType"
                :is-processing="isSimulating"
                :risk-property.sync="outputInfo.riskProperty"
                setting-type="RISK"
                class="simulator__setting-input"
              />
            </div>
          </div>
        </div>

        <div class="simulator__setting-container--bottom">
          <button
            :disabled="isSimulating"
            type="submit"
            class="btn-m btn-default btn-simulate"
          >
            {{ $t('miniApp.startSimulating') }}
          </button>
        </div>
      </form>
      <div class="simulator__result">
        <div
          v-if="!taskId"
          class="simulator__default-message"
        >
          {{ failedMessage || $t('miniApp.notYetSimulate') }}
        </div>
        <template v-else>
          <el-tabs
            v-model="activeTabName"
            class="simulator__result-tab"
            type="card"
          >
            <el-tab-pane
              :label="$t('miniApp.simulationResult')"
              :name="$t('miniApp.simulationResult')"
            >
              <div
                v-if="isSimulateFailed"
                class="simulator__default-message"
              >
                {{ failedMessage || $t('message.systemIsError') }}
              </div>
              <spinner
                v-else-if="isSimulating"
                :title="$t('miniApp.simulating')"
              />
              <div
                v-else
                class="simulator__result-panel"
              >
                <simulator-result-card
                  v-for="(result, index) in simulatorResults"
                  :key="index"
                  :result="result"
                />
              </div>
            </el-tab-pane>
            <!-- 下次再加 -->
            <!-- <el-tab-pane
              :label="$t('miniApp.savedRecord')"
              :name="$t('miniApp.savedRecord')">
              <div class="simulator__record-panel">
                {{ $t('miniApp.savedRecord') }}
              </div>
            </el-tab-pane> -->
          </el-tabs>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from '@vue/composition-api'
import { useMapActions } from '@/utils/composable/vuex'
// , computed,
import DefaultSelect from '@/components/select/DefaultSelect'
import EmptyInfoBlock from '@/components/EmptyInfoBlock'
import SimulatorResultCard from './SimulatorResultCard'
import ParametersOptimizedSimulatorInput from './ParametersOptimizedSimulatorInput'
import ParametersOptimizedSimulatorOutput from './ParametersOptimizedSimulatorOutput'
import { getModelInfo, createParamOptimizationTask, getParamOptimizationResult } from '@/API/Model'
import { v4 as uuidv4 } from 'uuid'
import { useI18n } from '@/utils/composable/i18n'
import { useValidator } from '@/utils/composable/validator'
import { customerTimeFormatter } from '@/utils/composable/dateTime'

export default defineComponent({
  inject: ['$validator'],
  name: 'ParametersOptimizedSimulator',
  components: {
    DefaultSelect,
    EmptyInfoBlock,
    SimulatorResultCard,
    ParametersOptimizedSimulatorInput,
    ParametersOptimizedSimulatorOutput
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false
    },
    restrictions: {
      type: Array,
      default: () => []
    },
    modelSetting: {
      type: Object,
      required: true
    },
    dataFrameId: {
      type: Number,
      default: null
    },
    isModelPredict: {
      type: Boolean,
      default: false
    },
    dataColumnMap: {
      type: Object,
      default: () => ({})
    },
    source: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { t } = useI18n()

    // ----- general -----
    const isLoading = ref(false)
    const activeTabName = t('miniApp.simulationResult')
    const outputInfo = ref({
      criteria: [],
      riskProperty: 'LOW'
    })

    // ----- model Info -----
    const isFetchInputFailed = ref(false)
    const modelInfo = ref(null)
    const modelType = ref(null)
    watch(
      () => modelInfo,
      (newList) => {
        const isAllInit = newList.value.every(column => column.isInit)
        if (!isAllInit) return
        isLoading.value = false
      }, {
        deep: true
      }
    )

    const fetchModelInfo = () => {
      isLoading.value = true
      if (!props.isModelPredict) {
        getModelInfo(props.modelSetting.modelId)
          .then(({ modelGeneratedType, ioArgs: { output: outputList } }) => {
            modelType.value = modelGeneratedType
            outputInfo.value.criteria = outputList.map(output => ({
              ...output,
              expectType: 'MAX'
            }))
          })
          .catch(() => isFetchInputFailed.value = true)
        modelInfo.value = props.modelSetting.inputList.map(column => ({
          ...column,
          columnId: column.dcId,
          isInit: false,
          userInput: {
            type: column.statsType === 'NUMERIC' ? 'RANGE' : 'ALL'
          }
        }))
      } else {
        outputInfo.value.criteria = props.modelSetting.ioArgs.output.map(output => ({
          ...output,
          expectType: 'MAX'
        }))
        modelInfo.value = props.modelSetting.ioArgs.input.map(input => ({
          ...input,
          originalName: input.modelColumnName,
          columnId: props.dataColumnMap[Object.keys(props.dataColumnMap).find(key => props.dataColumnMap[key].primary_alias === input.modelColumnName)].id,
          isInit: false,
          userInput: {
            type: input.statsType === 'NUMERIC' ? 'RANGE' : 'ALL'
          }
        }))
      }
    }

    onMounted(() => {
      fetchModelInfo()
    })

    const updateColumnInfoState = (index) => {
      modelInfo.value = modelInfo.value.map((input, currentIndex) => {
        if (index !== currentIndex) return input
        return {
          ...input,
          isInit: true
        }
      })
    }

    // ----- fail handler -----
    const failedMessage = ref(null)
    const handleFetchInputFailed = (message) => {
      isLoading.value = false
      isFetchInputFailed.value = true
      if (!failedMessage.value) failedMessage.value = message
    }

    // ----- simulate -----
    const validator = useValidator()
    const taskId = ref(null)
    const intervalFunction = ref(null)
    const isSimulating = ref(false)
    const simulatorId = uuidv4()
    const simulatorResults = ref([])
    const isSimulateFailed = ref(false)

    const clearPolling = () => {
      isSimulating.value = false
      if (intervalFunction.value) window.clearInterval(intervalFunction.value)
    }
    const startPolling = () => {
      if (!isSimulating.value) return
      intervalFunction.value = window.setInterval(() => {
        getParamOptimizationResult(taskId.value)
          .then(({ status, results, errorMessage }) => {
            switch (status) {
              case 'Ready':
              case 'Process':
                break
              case 'Complete':
                simulatorResults.value = results
                clearPolling()
                break
              case 'Fail':
                failedMessage.value = errorMessage
                isSimulateFailed.value = true
                clearPolling()
                break
            }
          })
          .catch(error => {
            isSimulating.value = false
            failedMessage.value = error.error?.message
            isSimulateFailed.value = true
            clearPolling()
          })
      }, 5000)
    }
    const simulate = () => {
      validator.validateAll(`params-optimization-${simulatorId.value}`).then(result => {
        if (!result) return
        // user tracking
        const { trackPageFunctionClick } = useMapActions('gtm', ['trackPageFunctionClick'])
        trackPageFunctionClick('use-parameter-optimized-simulator')
        isSimulateFailed.value = false
        isSimulating.value = true

        createParamOptimizationTask({
          dataFrameId: props.source.dataFrameId,
          modelId: props.modelSetting.modelId,
          restrictions: props.restrictions.length > 0 ? props.restrictions : null,
          riskProperty: outputInfo.value.riskProperty,
          setting: {
            expectItems: outputInfo.value.criteria.map(output => ({
              expectType: output.expectType
            })),
            inputItems: modelInfo.value.map(input => {
              if (input.statsType === 'CATEGORY' || input.statsType === 'BOOLEAN') {
                return {
                  conditionType: input.userInput.type,
                  dataColumnId: input.columnId,
                  items: input.userInput.type === 'ALL' ? null : input.userInput.selectedList,
                  fixedValue: null,
                  rangeMax: null,
                  rangeMin: null,
                  statsType: input.statsType,
                  startTime: null,
                  endTime: null
                }
              } else if (input.statsType === 'NUMERIC') {
                return {
                  conditionType: input.userInput.type,
                  dataColumnId: input.columnId,
                  items: null,
                  fixedValue: input.userInput.type === 'RANGE' ? null : input.userInput.min,
                  rangeMax: input.userInput.type === 'RANGE' ? input.userInput.max : null,
                  rangeMin: input.userInput.type === 'RANGE' ? input.userInput.min : null,
                  statsType: input.statsType,
                  startTime: null,
                  endTime: null
                }
              } else if (input.statsType === 'DATETIME') {
                return {
                  conditionType: 'RANGE',
                  dataColumnId: input.columnId,
                  items: null,
                  fixedValue: null,
                  rangeMax: null,
                  rangeMin: null,
                  statsType: input.statsType,
                  startTime: customerTimeFormatter(input.userInput.start, 'SECOND'),
                  endTime: customerTimeFormatter(input.userInput.end, 'SECOND')
                }
              }
            }),
            outputLimit: 5
          }
        })
          .then((id) => {
            taskId.value = id
            startPolling()
          })
          .catch(error => {
            isSimulating.value = false
            failedMessage.value = error.error?.message
            isSimulateFailed.value = true
          })
      })
    }

    onBeforeUnmount(() => {
      clearPolling()
    })

    return {
      isLoading,
      activeTabName,
      outputInfo,
      isFetchInputFailed,
      modelInfo,
      updateColumnInfoState,
      failedMessage,
      handleFetchInputFailed,
      taskId,
      isSimulating,
      simulatorId,
      simulatorResults,
      isSimulateFailed,
      simulate,
      modelType
    }
  }
  // data () {
  //   return {
  //     resultList: null
  //   }
  // }
})
</script>
<style lang="scss" scoped>
.simulator {
  &__predict {
    height: 100%;
  }
}
</style>
