<template>
  <decide-dialog
    :title="$t('miniApp.confirmDeletingDashboad', { name: dashboardName })"
    :btn-text="$t('button.delete')"
    :is-processing="isProcessing"
    type="delete"
    @closeDialog="$emit('close')"
    @confirmBtn="confirm"
  />
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import DecideDialog from '@/components/dialog/DecideDialog'

export default defineComponent({
  name: 'DeleteDashboardDialog',
  components: {
    DecideDialog
  },
  props: {
    dashboardName: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const isProcessing = ref(false)
    const confirm = () => {
      emit('confirm')
      isProcessing.value = true
    }
    return {
      isProcessing,
      confirm
    }
  }
})
</script>
