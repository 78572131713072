<template>
  <div
    class="alert-condition-deleter"
    @click="onClickDeleteIcon"
  >
    <svg-icon
      icon-class="delete"
      class="alert-condition-deleter__delete-icon"
    />
    <div
      v-show="isShowConfirmMsg"
      class="alert-condition-deleter__confirm-pop"
    >
      <div class="confirm__message">
        {{ $t('alert.alertConditionDeletionWillImpactOtherApplicationConfirmMsg') }}
      </div>
      <div class="confirm__buttons">
        <a
          href="javascript:void(0)"
          class="link confirm__link--cancel"
          @click.stop="cancelDeletion"
        >{{ $t('button.cancel') }}</a>
        <a
          href="javascript:void(0)"
          class="link"
          @click.stop="confirmDeletion"
        >{{ $t('button.confirm') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { Message } from 'element-ui'
import { deleteAlertCondition } from '@/API/Alert'
import { useEventLog } from '@/utils/composable/eventLog'

export default defineComponent({
  name: 'AlertConditionDeleter',
  props: {
    condition: {
      type: Object,
      default: () => ({})
    }
  },
  setup () {
    const { appEventLog } = useEventLog()
    return {
      appEventLog
    }
  },
  data () {
    return {
      isShowConfirmMsg: false,
      isProcessing: false
    }
  },
  methods: {
    onClickDeleteIcon () {
      this.isShowConfirmMsg = true
    },
    cancelDeletion () {
      this.isShowConfirmMsg = false
    },
    confirmDeletion () {
      this.isProcessing = true
      deleteAlertCondition(this.condition.id)
        .then(() => {
          this.appEventLog('deleteCondition', {
            conditionId: this.condition.id,
            appId: this.$route.params.mini_app_id
          })
          Message({
            message: '示警條件已刪除',
            type: 'success',
            duration: 3 * 1000,
            showClose: true
          })
        })
        .finally(() => {
          this.isProcessing = false
          this.isShowConfirmMsg = false
          this.$emit('deleted')
        })
    }
  }
})
</script>

<style lang="scss" scoped>
.alert-condition-deleter {
  position: relative;

  &__delete-icon {
    cursor: pointer;
    font-size: 16px;
  }

  &__confirm-pop {
    background: #323a3a;
    border: 1px solid #2ad2e2;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(34, 117, 125, 0.5);
    padding: 10px 12px;
    position: absolute;
    right: -10px;
    top: calc(100% + 8px);
    width: 200px;
    z-index: 1;

    &::before {
      border-bottom: 9px solid #2ad2e2;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid transparent;
      bottom: 100%;
      content: '';
      position: absolute;
      right: 7px;
    }

    .confirm__message {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 5px;
    }

    .confirm__buttons {
      display: flex;
      justify-content: flex-end;
    }

    .confirm__link {
      &--cancel {
        color: #fff;
        margin-right: 12px;
      }
    }
  }
}
</style>
