<template>
  <writing-dialog
    :title="$t('miniApp.updateDashboardName')"
    :button="$t('button.save')"
    :show-both="true"
    @closeDialog="$emit('close')"
    @confirmBtn="updateDashboardName"
  >
    <input-verify
      v-validate="`required|max:${max}`"
      v-model="newDashboardName"
      :placeholder="$t('miniApp.pleaseTypeDashboardName')"
      name="dashboardName"
    />
  </writing-dialog>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import WritingDialog from '@/components/dialog/WritingDialog'
import InputVerify from '@/components/InputVerify'
import { useMapGetters } from '@/utils/composable/vuex'
import { useValidator } from '@/utils/composable/validator'

export default defineComponent({
  inject: ['$validator'],
  name: 'UpdateDashboardNameDialog',
  components: {
    WritingDialog,
    InputVerify
  },
  props: {
    originalDashboardName: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    const newDashboardName = ref(null)
    const validator = useValidator()

    const { fieldCommonMaxLength: max } = useMapGetters('validation', ['fieldCommonMaxLength'])

    onMounted(() => {
      newDashboardName.value = props.originalDashboardName
    })

    const updateDashboardName = () => {
      validator.validateAll().then(valid => {
        if (!valid) return
        emit('confirm', newDashboardName.value)
      })
    }

    return {
      newDashboardName,
      max,
      updateDashboardName
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
